import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

import { dayState } from '../Data/dataInterfaces';
import { getAllMilkingDays } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendar: {
      border: 'none',
      minWidth: '350px',
    },
    numberOfMilkings: {
      fontSize: 10,
      verticalAlign: 'super',
      color: theme.palette.primary.main,
    },
  })
);

function MilkingCalendar(props: any) {
  const classes = useStyles();
  const allDays = useAppSelector(getAllMilkingDays);
  const { selectDateCallback, selectedDate } = props;
  const lastDate = allDays.length === 0 ? moment().toDate() : moment(allDays[0].date).toDate();
  const firstDate = allDays.length === 0 ? moment().toDate() : moment(allDays[allDays.length - 1].date).toDate();

  return (
    <Calendar
      onChange={selectDateCallback}
      value={selectedDate ?? moment().toDate()}
      maxDate={lastDate}
      minDate={firstDate}
      minDetail="year"
      className={classes.calendar}
      // eslint-disable-next-line react/no-unstable-nested-components
      tileContent={({ date, view }) =>
        view === 'month' && allDays.find((d: dayState) => moment(d.date).valueOf() === date.getTime()) ? (
          <span className={classes.numberOfMilkings}>
            {' ('}
            {allDays.find((d: dayState) => moment(d.date).valueOf() === date.getTime())?.milkings.length})
          </span>
        ) : null
      }
    />
  );
}

export default MilkingCalendar;
