import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import SendIcon from '@mui/icons-material/Send';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Input, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';

import { userReports } from '../../api/user-reports';
import { UserReportT } from '../../api/user-reports/types';
import VideoPlayer from '../Charts/VideoPlayer';
import { addReport } from '../Data/UserReportReduxSlice';
import { getCollection, getCow, getFarmIdentifier } from '../Data/farmDataReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import { getDeveloper } from '../Data/userReduxSlice';
import LameStatus from '../common/LameStatus';
import ModalWindow from '../common/ModalWindow';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '0%',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  scoreLabel: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  cowBrowserLink: {
    width: '100%',
    display: 'inline-block',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingRight: '1rem',
  },
}));

function FormReportCollection(props: any) {
  const dispatch = useAppDispatch();
  const dispatchedAddReport = (report: UserReportT) => dispatch(addReport(report));
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { collection_id, close, visible } = props;
  const classes = useStyles();
  const collection = useAppSelector(getCollection(collection_id));
  const cow = useAppSelector(getCow(collection?.cowId));
  const [textValue, setTextValue] = React.useState<string>('');
  const [checkScore, setCheckScore] = React.useState<boolean>(false);
  const [checkID, setCheckID] = React.useState<boolean>(false);
  const [checkOther, setCheckOther] = React.useState<boolean>(false);
  const onTextChange = (e: any) => setTextValue(e.target.value);
  const onScoreChange = (e: any) => setCheckScore(e.target.checked);
  const onIDChange = (e: any) => setCheckID(e.target.checked);
  const onOtherChange = (e: any) => setCheckOther(e.target.checked);
  const farmId = useAppSelector(getFarmIdentifier);
  const developer = useAppSelector(getDeveloper);

  const handleReset = () => {
    setTextValue('');
    setCheckScore(false);
    setCheckID(false);
    setCheckOther(false);
  };

  const handleSubmit = async () => {
    if (collection !== undefined) {
      const cowIdentity: Record<string, string> = {};

      if (cow !== undefined) {
        if (cow.tag !== undefined) {
          cowIdentity.tag = cow.tag.toString();
        }

        if (cow.eid !== undefined) {
          cowIdentity.eid = cow.eid;
        }
      }

      const newReport = await userReports.create({
        farmId,
        collectionData: {
          collectionId: collection.uuid,
          date: new Date(collection.date),
          cowIdentity,
        },
        reportData: {
          cowIdentity: checkID,
          score: checkScore,
          other: checkOther,
          message: textValue,
        },
      });

      dispatchedAddReport(newReport);
    }

    handleReset();
    close();
  };

  return (
    <div className={classes.root}>
      <ModalWindow
        open={visible}
        toggle={() => {
          close();
        }}
        title="Report Video"
      >
        <VideoPlayer collectionId={collection_id} />

        {developer && (
          <Link
            href="#common-examples"
            className={classes.cowBrowserLink}
            component={RouterLink}
            to={{
              pathname: '/cowidentity',
              search: `?cowID=${cow?.id}`,
            }}
            align="right"
          >
            View ID correction &rarr;
          </Link>
        )}

        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checkScore} onChange={onScoreChange} />}
              label={
                <Box className={classes.scoreLabel}>
                  <Typography>Incorrect score: {collection?.score?.toFixed(2)}</Typography>
                  <LameStatus score={collection?.score} />
                </Box>
              }
            />

            <FormControlLabel
              control={<Checkbox checked={checkID} onChange={onIDChange} />}
              label={`Incorrect tag: ${cow?.tag}`}
            />

            <FormControlLabel
              control={<Checkbox checked={checkOther} onChange={onOtherChange} />}
              label="Other reason"
            />
          </FormGroup>
        </FormControl>

        <FormControl fullWidth>
          <Input multiline placeholder="Describe the issue (optional)" onChange={onTextChange} value={textValue} />
        </FormControl>

        <Button fullWidth className={classes.button} endIcon={<SendIcon />} variant="contained" onClick={handleSubmit}>
          Submit Report
        </Button>
      </ModalWindow>
    </div>
  );
}

export default FormReportCollection;
