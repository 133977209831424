import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getHexByScore } from '../Data/ColourScale';
import { histogramEntry } from '../Data/dataInterfaces';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '800px',
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function ChartMilkingHistogram(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const { data } = props;

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomLabel(labelProps: any) {
    const { x, y, width, height, value } = labelProps;
    const fireOffset = y < 20;
    const offsetY = fireOffset ? 16 : -4;

    return (
      <text
        x={x + width / 2}
        y={y + offsetY}
        fill={fireOffset ? '#000' : '#000'}
        textAnchor="middle"
        width={width}
        height={height}
      >
        {value}
      </text>
    );
  }

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="95%" height={useMediaQuery(theme.breakpoints.up('md')) ? '95%' : 165}>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 8,
            left: 8,
            bottom: 0,
          }}
        >
          <YAxis hide domain={['minData', 'maxData']} />

          <XAxis
            ticks={[0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0]}
            dataKey="binMedian"
            tickLine={false}
            type="number"
            tickFormatter={(f) => f.toFixed(1)}
          />

          <Bar isAnimationActive={false} dataKey="quantity" fill="#63B179" background={{ fill: '#E5E5E5' }}>
            {data.map((entry: histogramEntry, index: number) => (
              <Cell
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${index}`}
                fill={getHexByScore(entry.binMedian)}
              />
            ))}

            <LabelList
              dataKey="quantity"
              position="top"
              style={{ fill: 'black' }}
              // eslint-disable-next-line react/jsx-no-bind
              content={CustomLabel}
            />
          </Bar>

          <Tooltip
            cursor={false}
            labelFormatter={(d: number) => `Score: ${(d - 0.25).toFixed(1)} - ${(d + 0.25).toFixed(1)}`}
            formatter={(l: number) => l}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ChartMilkingHistogram;
