import React from 'react';

import { GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';

import { cowSnooze } from '../../api/cow-snooze';
import { SnoozeVisiblity, cowState } from '../Data/dataInterfaces';
import { deleteSnooze, getAllCows, getLameCows } from '../Data/farmDataReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import { isScoreVisible } from '../Data/userReduxSlice';
import { TitleStack } from '../Layouts/TitleStack';
import CardDataTable from './CardDataTable';
import { cowColumns, unsnoozeCowActions } from './DataTableProps';
import SnoozePopup from './SnoozeCard';

function ObservationList({
  setCollectionId,
}: {
  setCollectionId: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  const dispatch = useAppDispatch();
  const [snoozeCowId, setSnoozeCowId] = React.useState<number | undefined>();
  const cows = useAppSelector(getAllCows);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'snoozeExpiry', sort: 'asc' }]);
  const scoreVisibility = useAppSelector(isScoreVisible);
  const lameWatchList = useAppSelector(getLameCows(0, SnoozeVisiblity.only_snoozed, 1000));
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const [page, setPage] = React.useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_pageSize, setPageSize] = React.useState<number>(0);

  const unsnoozeCow = (cow: cowState) => {
    if (cow.snoozed !== undefined) {
      /* send the api request */
      cowSnooze.destroy({ snoozeId: cow.snoozed.snoozeId });
      /* update local storage */
      dispatch(
        deleteSnooze({
          eid: cow.snoozed.eid,
        })
      );
    }
  };

  return (
    <TitleStack title="Under Observation">
      <CardDataTable
        data={lameWatchList}
        columns={cowColumns(
          ['tag', 'current_status', 'last_score', 'last_scored', 'snoozeExpiry'],
          scoreVisibility ? [] : ['last_score', 'last_scored']
        ).concat([unsnoozeCowActions(unsnoozeCow)])}
        page={page}
        onPageChange={setPage}
        setPageSize={setPageSize}
        sortModel={sortModel}
        setSortModel={setSortModel}
        header
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        emptyMessage="No Cow Data"
        selectRowCallback={(rowId: GridRowId | undefined) => {
          if (rowId === undefined) setCollectionId(undefined);
          else {
            setCollectionId(cows[rowId as number].collection_ids[0]);
          }
        }}
      />

      <SnoozePopup snoozeCowId={snoozeCowId} setSnoozeCowId={setSnoozeCowId} />
    </TitleStack>
  );
}

export default ObservationList;
