import dayjs from 'dayjs';
import moment from 'moment';

export const weekMillis = 7 * 24 * 60 * 60 * 1000;

export const durationMillis: Record<string, number> = {
  '1 week': weekMillis * 1,
  '2 weeks': weekMillis * 2,
  '3 weeks': weekMillis * 3,
  '4 weeks': weekMillis * 4,
  Forever: weekMillis * 52 * 100,
};

// Sun Feb 06 2022 20:57:25 GMT+1300 (New Zealand Daylight Time)
export const AWSDateStringToMoment = (s: string) => moment(s.substring(0, 24), 'ddd MMM DD YYYY HH:mm:ss');

const pad = (n: number) => (n < 10 ? `0${n}` : n);

export const dateToRFC3339String = (d: Date) => `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}`;

export const dateTimeToRFC3339String = (d: Date) =>
  `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(
    d.getSeconds()
  )}`;

export const formatDateTime = (dateString: string) => {
  if (dateString === null || dateString === undefined) {
    return '-';
  }

  const dateTime = dayjs(dateString);

  return dateTime.format(`MMM DD, YYYY HH:mm:ss`);
};
