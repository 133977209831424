/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { Cow, Observation } from '../../api/farmAPI/types';
import type { RootState } from './store';

export interface CowDataState {
  cows: Cow[];
}

export interface ObservationDataState {
  observations: Observation[];
}

export const ObservationDataReduxSlice = createSlice({
  name: 'observations',
  initialState: {
    observations: [],
  } as ObservationDataState,
  reducers: {
    setObservations: (state, action: PayloadAction<Observation[]>) => {
      state.observations = action.payload;
    },
  },
});

export const CowDataReduxSlice = createSlice({
  name: 'cows',
  initialState: {
    cows: [],
  } as CowDataState,
  reducers: {
    setCows: (state, action: PayloadAction<Cow[]>) => {
      state.cows = action.payload;
    },
  },
});

export const CurrentCowReduxSlice = createSlice({
  name: 'currentCow',
  initialState: {
    currentCow: undefined,
  } as { currentCow: Cow | undefined },
  reducers: {
    setCurrentCow: (state, action: PayloadAction<Cow | undefined>) => {
      state.currentCow = action.payload;
    },
  },
});

export const CurrentObservationReduxSlice = createSlice({
  name: 'currentObservation',
  initialState: {
    currentObservation: undefined,
  } as { currentObservation: Observation | undefined },
  reducers: {
    setCurrentObservation: (state, action: PayloadAction<Observation>) => {
      state.currentObservation = action.payload;
    },
  },
});

export const { setCows } = CowDataReduxSlice.actions;
export const getCows = (state: RootState) => state.cows;

export const { setObservations } = ObservationDataReduxSlice.actions;
export const getObservations = (state: RootState) => state.observations;

export const { setCurrentCow } = CurrentCowReduxSlice.actions;
export const getCurrentCow = (state: RootState) => state.currentCow;

export const { setCurrentObservation } = CurrentObservationReduxSlice.actions;
export const getCurrentObservation = (state: RootState) => state.currentObservation;

export default CowDataReduxSlice.reducer;
