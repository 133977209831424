import React from 'react';

import { Paper, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRowId } from '@mui/x-data-grid';

import { cowIdentity } from '../../api/cow-identity';
// eslint-disable-next-line import/no-cycle
import { useDataTable } from '../../helpers/hooks';
import TableCowDetails from '../Charts/TableCowDetails';
import VideoPlayer from '../Charts/VideoPlayer';
import { collectionState, cowState } from '../Data/dataInterfaces';
import {
  cacheCowIdentityConfirmation,
  getCollectionsWithConfirmedIdentityFromIds,
  getCow,
  getFarmIdentifier,
} from '../Data/farmDataReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import { getEmail, isScoreVisible } from '../Data/userReduxSlice';
import CardDataTable from './CardDataTable';
import { collectionActionCowId, collectionColumns } from './DataTableProps';

const styles = makeStyles((theme) => ({
  containerPadding: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    height: '100%',
    marginRight: theme.spacing(2),
    '&:last-child': { marginRight: theme.spacing(0) },
  },
}));

const useCowCollection = (cow: cowState | undefined, setCurrentCollection: React.Dispatch<number | undefined>) => {
  React.useEffect(() => {
    if (cow !== undefined) setCurrentCollection(cow.collection_ids[0]);
  }, [cow]);
};

function CardVideoListIdentity(props: {
  currentCow: number;
  currentCollection: number | undefined;
  setCurrentCollection: React.Dispatch<number | undefined>;
}) {
  const classes = styles();
  const { currentCow, setCurrentCollection, currentCollection } = props;
  const cow = useAppSelector(getCow(currentCow));

  useCowCollection(cow, setCurrentCollection);

  const { sortModel, setSortModel, selectionModel, setSelectionModel, page, setPage, setPageSize } = useDataTable({
    initialSortModel: [{ field: 'date', sort: 'desc' }],
    initialSelectionModel: [currentCollection as number],
    initialPage: 0,
    initialPageSize: 0,
  });

  const cowCollections = useAppSelector(getCollectionsWithConfirmedIdentityFromIds(cow?.collection_ids));
  const scoreVisibility = useAppSelector(isScoreVisible);
  const dispatch = useAppDispatch();
  const farmIdentifier = useAppSelector(getFarmIdentifier);
  const email = useAppSelector(getEmail);
  const onActionClick = React.useCallback(
    async (collection: collectionState, correction: string) => {
      if (cow !== undefined) {
        /* update local storage */
        const eid = correction === 'setEid' ? cow?.eid : undefined;
        const wrongEids = correction === 'addWrongEid' ? [cow?.eid] : [];

        dispatch(
          cacheCowIdentityConfirmation({
            uuid: collection.uuid,
            eid,
            wrong_eid: wrongEids,
          })
        );
        /* send the api request */
        if (farmIdentifier !== undefined)
          cowIdentity.submit({
            uuid: collection.uuid,
            eid: cow?.eid,
            correction,
            userEmail: email,
            farmIdentifier,
          });
      }
    },
    [farmIdentifier, dispatch, cow, email]
  );

  const columns = React.useMemo(
    () =>
      collectionColumns(['score', 'tag', 'gateTime', 'dateTime'], scoreVisibility ? [] : ['score']).concat([
        collectionActionCowId(cow?.eid, onActionClick),
      ]),
    [cow]
  );

  return (
    <Stack direction="column" className={classes.containerPadding}>
      <Typography component="h2" variant="h5" sx={{ minWidth: 'max-content' }}>
        Videos for cow {cow?.tag}
      </Typography>

      <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <TableCowDetails cowId={cow?.id} />
        <VideoPlayer collectionId={currentCollection} />

        <CardDataTable
          data={cowCollections}
          columns={columns}
          page={page}
          onPageChange={setPage}
          setPageSize={setPageSize}
          sortModel={sortModel}
          setSortModel={setSortModel}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          header={false}
          emptyMessage="This cow has no videos"
          selectRowCallback={(rowId: GridRowId | undefined) => {
            if (rowId === undefined) {
              setCurrentCollection(undefined);
            } else {
              setCurrentCollection(rowId as number);
            }
          }}
        />
      </Paper>
    </Stack>
  );
}

export default React.memo(CardVideoListIdentity);
