import React from 'react';

import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';

import { getHexByScore, getLabelByScore } from '../Data/ColourScale';

const useStyles = makeStyles(() => ({
  root: {
    width: 80,
    padding: 0,
    margin: 0,
    height: 17,
    fontSize: 12,
    color: '#FFFFFF',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
}));

function LameStatus(props: any) {
  let { score } = props;
  const classes = useStyles();

  if (!score) {
    score = undefined;
  }

  return (
    <Chip
      className={classes.root}
      style={{ backgroundColor: getHexByScore(score) }}
      size="small"
      label={getLabelByScore(score)}
    />
  );
}

export default LameStatus;
