import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/ExitToApp';
import { Box, Stack, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';

import { getCowFromTag, getDisplayFarm, getLoading } from '../Data/farmDataReduxSlice';
import { getPageTitle } from '../Data/pageReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import { getDemoMode } from '../Data/userReduxSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.down('xl')]: {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  title: {
    display: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      display: 'contents',
    },
  },
  button: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

function TopBar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [tag, setTag] = React.useState<string>('');
  const tagData = useAppSelector(getCowFromTag);
  const [tagError, setTagError] = React.useState<boolean>(false);

  const signOut = (e: any) => {
    e.preventDefault();
    Sentry.setUser(null);
    Auth.signOut().then(() => {
      window.location.reload();
    });
  };

  const pageTitle = () => {
    const isLoading = useAppSelector(getLoading);
    const isDemoMode = useAppSelector(getDemoMode);
    const farm = useAppSelector(getDisplayFarm);
    let title = useAppSelector(getPageTitle);

    if (!isLoading && !isDemoMode) {
      title = `${farm}\xa0-\xa0${title}`;
    }

    return title;
  };

  const handleSubmit = () => {
    const cowNumber = tagData(tag);

    if (cowNumber === undefined) {
      setTagError(true);

      return;
    }

    setTag('');
    navigate({
      pathname: '/cows',
      search: createSearchParams({ cow: tag }).toString(),
    });
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.title}>
          <Typography color="primary" variant="h4" component="h1">
            {pageTitle()}
          </Typography>
        </Box>

        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent={isSmallScreen ? 'space-between' : 'center'}
          sx={{
            paddingTop: '1rem',
            flexGrow: isSmallScreen ? 1 : 'initial',
          }}
        >
          <TextField
            sx={{
              '& fieldset': {
                borderRadius: '30px',
                background: '#fffff',
              },
            }}
            size="small"
            label="Search by Cow Tag"
            helperText={tagError ? 'Tag not found' : ' '}
            value={tag}
            error={tagError}
            onChange={(e) => {
              setTag(e.target.value);
              setTagError(false);
            }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />

          <Hidden mdDown implementation="css">
            <Button onClick={signOut} className={classes.button} color="primary" startIcon={<LogoutIcon />}>
              Logout
            </Button>
          </Hidden>

          <Hidden mdUp implementation="css">
            <IconButton onClick={signOut} className={classes.button} color="primary">
              <LogoutIcon />
            </IconButton>
          </Hidden>
        </Stack>
      </Toolbar>
    </div>
  );
}

export default TopBar;
