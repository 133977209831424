import React from 'react';

import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ChartCowLameHistoryLine from '../Charts/ChartCowLameHistoryLine';
import TableCowDetails from '../Charts/TableCowDetails';
import TableCowLameStats from '../Charts/TableCowLameStats';
import { getCow } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import WatchCowButton from './CardWatchCowButton';

const useStyles = makeStyles(() => ({
  cowActions: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function CardCowProfile(props: any) {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chartDomain, setChartDomain] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { cow_id, changeCollection, collection_id } = props;
  const cow = useAppSelector(getCow(cow_id));

  if (cow) {
    return (
      <Stack sx={{ flexGrow: 1, padding: 1, gap: 2 }}>
        <TableCowDetails cowId={cow_id} />

        <div className={classes.cowActions}>
          <WatchCowButton cowId={cow_id} />
        </div>

        <Stack sx={{ flexGrow: 1 }}>
          <Typography component="h3" variant="h6">
            Lameness
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <ChartCowLameHistoryLine
              selectDataPoint={changeCollection}
              collection_id={collection_id}
              cow_id={cow_id}
              numberOfDays={chartDomain}
            />
          </Box>
        </Stack>

        <TableCowLameStats cow_id={cow_id} />
      </Stack>
    );
  }

  return (
    <Stack sx={{ alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
      <Typography color="primary" component="h4" variant="h6">
        No Cow Selected
      </Typography>
    </Stack>
  );
}

export default CardCowProfile;
