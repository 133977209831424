import React from 'react';

import { Button, FormControl, InputAdornment, Stack, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import { cowSnooze } from '../../api/cow-snooze';
import TableCowDetails from '../Charts/TableCowDetails';
import { cacheSnooze, deleteSnooze, getCow, getFarmIdentifier, getSnoozeState } from '../Data/farmDataReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import { getEmail, getSnoozeDuration } from '../Data/userReduxSlice';
import { DefaultOrNumericRadioGroup } from '../DefaultOrNumericRadioGroup';
import ModalWindow from '../common/ModalWindow';

const dayMillis = 24 * 60 * 60 * 1000;
const fallbackSnoozeDuration = 14;

function SnoozeCard(props: any) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { cow_id, toggle } = props;
  /* cow we are snoozing */
  const cow = useAppSelector(getCow(cow_id));
  /* the snooze state */
  const snooze = useAppSelector(getSnoozeState(cow?.eid));
  const email = useAppSelector(getEmail);
  const newFarmIdentifier = useAppSelector(getFarmIdentifier);
  const preferenceSnoozeDuration = useAppSelector(getSnoozeDuration);
  const [isCustomSnoozeDuration, setIsCustomSnoozeDuration] = React.useState<boolean>(preferenceSnoozeDuration > 0);
  const [snoozeDurationValue, setSnoozeDurationValue] = React.useState<string>(
    (preferenceSnoozeDuration > 0 ? preferenceSnoozeDuration : fallbackSnoozeDuration).toString()
  );

  const [isCustomSnoozeError, setIsCustomSnoozeError] = React.useState<boolean>(false);

  const snoozeCow = async () => {
    if (
      isCustomSnoozeDuration &&
      (Number.isNaN(parseInt(snoozeDurationValue, 10)) ||
        parseInt(snoozeDurationValue, 10) !== parseFloat(snoozeDurationValue) ||
        parseInt(snoozeDurationValue, 10) < 1)
    ) {
      setIsCustomSnoozeError(true);

      return;
    }

    if (cow !== undefined) {
      /* send the api request */
      let snoozeLength;

      if (!isCustomSnoozeDuration || snoozeDurationValue === '') {
        snoozeLength = 50 * 365 * dayMillis;
      } else {
        snoozeLength = parseInt(snoozeDurationValue, 10) * dayMillis;
      }

      const newSnooze = await cowSnooze.create({
        user: email,
        farm: newFarmIdentifier,
        eid: cow.eid,
        snoozeLength,
      });

      /* update local storage */
      dispatch(cacheSnooze(newSnooze));
    }

    toggle();
  };

  const unsnoozeCow = () => {
    if (cow !== undefined) {
      if (snooze !== undefined) {
        /* send the api request */
        cowSnooze.destroy({ snoozeId: snooze.snoozeId });
        /* update local storage */
        dispatch(
          deleteSnooze({
            eid: snooze.eid,
          })
        );
      }
    }

    toggle();
  };

  const changeSnoozeDuration = (value: string) => {
    if (value === 'custom') {
      setSnoozeDurationValue(
        (preferenceSnoozeDuration > 0 ? preferenceSnoozeDuration : fallbackSnoozeDuration).toString()
      );
    }
  };

  return (
    <Stack direction="column" spacing={theme.spacing(1)}>
      <TableCowDetails cowId={cow_id} onlyTags />

      {snooze !== undefined ? (
        <Box>
          <Typography variant="h6">Observation Times</Typography>

          <Stack direction="row" sx={{ justifyContent: 'space-around', padding: 2 }}>
            <Typography>
              {'Observation Added: '}
              {snooze.snoozeTime.toLocaleDateString()}
            </Typography>

            <Typography>
              {'Observation Expires: '}
              {snooze.snoozeExpiry.toLocaleDateString()}
            </Typography>
          </Stack>

          <FormControl fullWidth>
            <Button variant="contained" onClick={unsnoozeCow}>
              Stop Observing Cow
            </Button>
          </FormControl>
        </Box>
      ) : (
        <>
          <DefaultOrNumericRadioGroup
            groupLabel="Set Observation Period"
            customLabel="Set duration"
            value={snoozeDurationValue}
            setValue={setSnoozeDurationValue}
            isCustom={isCustomSnoozeDuration}
            setIsCustom={setIsCustomSnoozeDuration}
            isError={isCustomSnoozeError}
            onChangeRadio={changeSnoozeDuration}
            setIsError={setIsCustomSnoozeError}
            groupName="observation-period"
            defaultLabel="Until manually removed"
            customInputProps={{
              inputProps: { min: 1.0, max: 28.0, step: 1 },
              endAdornment: <InputAdornment position="end">days</InputAdornment>,
            }}
          />

          <Button variant="contained" onClick={snoozeCow} disabled={isCustomSnoozeError}>
            Place Under Observation
          </Button>
        </>
      )}
    </Stack>
  );
}

function SnoozePopup({
  snoozeCowId,
  setSnoozeCowId,
}: {
  // eslint-disable-next-line react/require-default-props
  snoozeCowId?: number;
  setSnoozeCowId: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  return (
    <ModalWindow
      open={snoozeCowId !== undefined}
      toggle={() => {
        setSnoozeCowId(undefined);
      }}
      title="Observation Status"
    >
      <SnoozeCard
        cow_id={snoozeCowId}
        toggle={() => {
          setSnoozeCowId(undefined);
        }}
      />
    </ModalWindow>
  );
}

export default SnoozePopup;
