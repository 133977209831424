import React from 'react';

import { Paper, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRowId } from '@mui/x-data-grid';
import moment from 'moment';

import { cowIdentity } from '../../api/cow-identity';
// eslint-disable-next-line import/no-cycle
import { useDataTable } from '../../helpers/hooks';
import VideoPlayer from '../Charts/VideoPlayer';
import { collectionState } from '../Data/dataInterfaces';
import {
  cacheCowIdentityConfirmation,
  getCollection,
  getCollectionsWindow,
  getCollectionsWithConfirmedIdentityFromIds,
  getCow,
  getFarmIdentifier,
} from '../Data/farmDataReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import { getEmail, isScoreVisible } from '../Data/userReduxSlice';
import Selector from '../common/Selector';
import CardDataTable from './CardDataTable';
import { collectionActionCowId, collectionColumns } from './DataTableProps';

const styles = makeStyles((theme) => ({
  containerPadding: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    height: '100%',
    marginRight: theme.spacing(2),
    '&:last-child': { marginRight: theme.spacing(0) },
  },
}));

function CardIdentityReassignment(props: { collectionId: number; cowId: number }) {
  const classes = styles();
  const { collectionId, cowId } = props;
  const [reassignmentCollectionId, setReassignmentCollectionId] = React.useState<number | undefined>();
  const baseCollection = useAppSelector(getCollection(collectionId));
  const cow = useAppSelector(getCow(cowId));
  const collection = useAppSelector(getCollection(reassignmentCollectionId));

  React.useEffect(() => {
    setReassignmentCollectionId(collectionId);
  }, [collectionId]);

  const reassignmentTimeFrameOptions = {
    '30 seconds': (30.0 * 1000) / 2,
    '1 minute': (1 * 60.0 * 1000) / 2,
    '2 minutes': (2 * 60.0 * 1000) / 2,
    '3 minutes': (3 * 60.0 * 1000) / 2,
    '5 minutes': (5 * 60.0 * 1000) / 2,
    '10 minutes': (10 * 60.0 * 1000) / 2,
  };

  const [reassignmentTimeFrame, setReassignmentTimeFrame] = React.useState<number | undefined>(
    Object.values(reassignmentTimeFrameOptions)[1]
  );

  const reassignmentCandidateCollectionIds = useAppSelector(
    getCollectionsWindow(
      moment(baseCollection?.date).subtract(reassignmentTimeFrame, 'milliseconds'),
      moment(baseCollection?.date).add(reassignmentTimeFrame, 'milliseconds')
    )
  );

  const reassignmentCollectionList = useAppSelector(
    getCollectionsWithConfirmedIdentityFromIds(reassignmentCandidateCollectionIds)
  );

  const { sortModel, setSortModel, selectionModel, setSelectionModel, page, setPage, setPageSize } = useDataTable({
    initialPage: 0,
    initialPageSize: 0,
    initialSortModel: [{ field: 'date', sort: 'asc' }],
    initialSelectionModel: [collectionId],
  });

  const scoreVisibility = useAppSelector(isScoreVisible);
  const farmIdentifier = useAppSelector(getFarmIdentifier);
  const email = useAppSelector(getEmail);
  const dispatch = useAppDispatch();
  const onActionClick = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (collection: collectionState, correction: string) => {
      if (cow !== undefined) {
        /* update local storage */
        const eid = correction === 'setEid' ? cow?.eid : undefined;
        const wrongEids = correction === 'addWrongEid' ? [cow?.eid] : [];

        dispatch(
          cacheCowIdentityConfirmation({
            uuid: collection.uuid,
            eid,
            wrong_eid: wrongEids,
          })
        );
        /* send the api request */
        if (farmIdentifier !== undefined)
          cowIdentity.submit({
            uuid: collection.uuid,
            eid: cow?.eid,
            correction,
            userEmail: email,
            farmIdentifier,
          });
      }
    },
    [farmIdentifier, dispatch, cow, email]
  );

  const columns = React.useMemo(
    () =>
      collectionColumns(['score', 'tag', 'gateTime', 'dateTime'], scoreVisibility ? [] : ['score']).concat([
        collectionActionCowId(cow?.eid, onActionClick),
      ]),
    [cow]
  );

  return (
    <Stack direction="column" className={classes.containerPadding}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography variant="h5" component="h2" sx={{ minWidth: 'max-content' }}>
          Videos {collection?.uuid.substring(0, 8)}
        </Typography>

        <Selector
          value={reassignmentTimeFrame}
          onChange={setReassignmentTimeFrame}
          options={reassignmentTimeFrameOptions}
        />
      </Stack>

      <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <VideoPlayer collectionId={reassignmentCollectionId} />

        <CardDataTable
          data={reassignmentCollectionList}
          columns={columns}
          page={page}
          onPageChange={setPage}
          sortModel={sortModel}
          setSortModel={setSortModel}
          setPageSize={setPageSize}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          header={false}
          emptyMessage="No matching videos"
          selectRowCallback={(rowId: GridRowId | undefined) => {
            if (rowId === undefined) {
              setReassignmentCollectionId(undefined);
            } else {
              setReassignmentCollectionId(rowId as number);
            }
          }}
        />
      </Paper>
    </Stack>
  );
}

export default React.memo(CardIdentityReassignment);
