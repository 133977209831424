import { stageWrap } from '..';
import type { APIFunctionWithURLAndHeaders } from '..';
import { Snooze } from './types';
import type { CreateSnooze, DeleteSnoozePost } from './types';

export { Snooze } from './types';

export type { CreateSnooze, DeleteSnoozePost } from './types';

const create: APIFunctionWithURLAndHeaders<CreateSnooze, Promise<Snooze>> =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async (createSnooze: CreateSnooze) => {
    const resp = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        ...headers,
        accept: 'application/json',
      }),
      body: JSON.stringify(createSnooze),
    });

    return Snooze.parse(await resp.json());
  };

const getAll =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async () => {
    const resp = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: new Headers({
        ...headers,
        accept: 'application/json',
      }),
    });

    const json = await resp.json();

    return Promise.all(json.Items.map((x: any) => Snooze.parseAsync(x)));
  };

const destroy =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async ({ snoozeId }: { snoozeId: string }) => {
    const postBody: DeleteSnoozePost = {
      tableName: 'SnoozedCows',
      operation: 'delete',
      payload: {
        Key: { eid: window.atob(snoozeId) },
        ReturnValue: 'CREATED_NEW',
      },
    };

    await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        ...headers,
      }),
      body: JSON.stringify(postBody),
    });
  };

const cowSnoozeRoute = stageWrap({ route: '/cow-snooze' });

export const cowSnooze = {
  create: cowSnoozeRoute(create),
  destroy: cowSnoozeRoute(destroy),
  readAll: cowSnoozeRoute(getAll),
};
