import React from 'react';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { cowData } from '../../api/farmAPI/cowData';
import { Observation } from '../../api/farmAPI/types';
import { gradientSVG } from '../Data/ColourScale';
import { getCurrentObservation } from '../Data/CowDataReduxSlice';
import { getFarmId } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import LoadingOverlay from '../common/LoadingOverlay';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '800px',
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyTable: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface ChartCowLameHistoryLineProps {
  cowId: number | undefined;
  latestObservationDate: string;
}

function CustomizedDot(props: any) {
  const { cx, cy, payload, currentObservation } = props;
  const selected = currentObservation?.observationId === payload.observationId;
  const size = selected ? 20 : 12;
  const padding = 4;

  return (
    <svg x={cx - size / 2 - padding} y={cy - size / 2 - padding} height={size + padding * 2} width={size + padding * 2}>
      <circle
        cx={size / 2 + padding}
        cy={size / 2 + padding}
        r={size / 2}
        stroke="#1976D2"
        strokeWidth="3"
        fill="#FFF"
      />
    </svg>
  );
}

function ChartCowLameHistoryLinePreview(props: ChartCowLameHistoryLineProps) {
  const { cowId, latestObservationDate } = props;
  const farmId = useAppSelector(getFarmId);
  const classes = useStyles();
  const theme = useTheme();
  const chartHeight = useMediaQuery(theme.breakpoints.up('md')) ? '90%' : 165;
  const { currentObservation } = useAppSelector(getCurrentObservation);
  const [allDataDates, setAllDataDates] = React.useState<string[]>();
  const [domain, setDomain] = React.useState<number[]>();
  const [scoreRange, setScoreRange] = React.useState<number[]>();
  const [data, setData] = React.useState<Observation[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchObservationsData = (queryParams: Record<string, string>) => {
    if (farmId && cowId) {
      setIsLoading(true);

      cowData.getObservationsForCow(farmId, cowId, {}, queryParams).then((result) => {
        const { data: resultData } = result;
        const formattedData = resultData.map((observation) => {
          return { ...observation, trackDate: dayjs(observation.trackDate).valueOf().toString() };
        });

        const filteredData = formattedData.filter((c) => c.score && c.score !== undefined && c.score > 0);

        filteredData.sort((a, b) => {
          if (a.trackDate < b.trackDate) {
            return -1;
          }

          if (a.trackDate > b.trackDate) {
            return 1;
          }

          return 0;
        });

        const dates = formattedData.map((c) => {
          return c.trackDate;
        });

        dates.sort();

        setDomain([Number(dates[0]), Number(dates.slice(-1)[0])]);
        setAllDataDates(dates);
        setData(filteredData);
        setIsLoading(false);
      });
    }
  };

  React.useEffect(() => {
    const endDate = dayjs(latestObservationDate);

    if (cowId && latestObservationDate)
      fetchObservationsData({
        start: endDate.subtract(3, 'months').toISOString(),
        end: endDate.toISOString(),
      });
  }, [cowId]);

  React.useEffect(() => {
    if (data !== undefined && data.length > 0) {
      let min = 3;
      let max = 0;

      data.forEach((d: Observation) => {
        if (d.score) {
          min = d.score < min ? d.score : min;
          max = d.score > max ? d.score : max;
        }
      });
      setScoreRange([min, max]);
    }
  }, [data]);

  const emptyChart = (
    <div className={classes.emptyTable}>
      {!isLoading ? (
        <Typography color="primary" align="right" component="h4" variant="h6">
          Insufficient data for graph
        </Typography>
      ) : (
        <LoadingOverlay />
      )}
    </div>
  );

  return isLoading ||
    data === undefined ||
    data.length < 1 ||
    allDataDates === undefined ||
    scoreRange === undefined ? (
    emptyChart
  ) : (
    <div className={classes.root}>
      <ResponsiveContainer width="90%" height={chartHeight}>
        <LineChart
          data={data}
          margin={{
            top: 8,
            right: 8,
            left: 8,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="trackDate"
            type="number"
            scale="utc"
            axisLine={false}
            tickFormatter={(d: number) => dayjs(Number(d)).format('DD MMM')}
            interval="preserveStartEnd"
            angle={30}
            tickCount={10}
            tickMargin={10}
            height={40}
            tick={{ fontSize: 12 }}
            domain={domain}
          />

          <YAxis
            dataKey="score"
            domain={[0, 3]}
            axisLine
            tickLine={false}
            width={25}
            tickFormatter={(l: number) => l.toFixed(1)}
          />

          {gradientSVG(scoreRange[0], scoreRange[1])}

          <Line
            isAnimationActive={false}
            name="Lameness score"
            type="basis"
            dataKey="score"
            stroke="url(#gradient)"
            strokeWidth={5}
            dot={<CustomizedDot currentObservation={currentObservation} cursor="pointer" />}
            activeDot={{
              cursor: 'pointer',
              r: 10,
              stroke: '#1976D2',
              strokeWidth: '3',
              fill: '#FFF',
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ChartCowLameHistoryLinePreview;
