import { APIService } from './data';
import { CowAPIResponseData, CowsAPIResponseData, ObservationsAPIResponseData } from './types';

async function getCowDetails(
  farmId: number,
  cowId: number,
  headers: Record<string, string> = {}
): Promise<CowAPIResponseData> {
  const url = `${process.env.REACT_APP_FARM_API_URL}/v1/farms/${farmId}/cows/${cowId}`;

  return APIService.get(url, headers);
}

async function getCows(
  farmId: number,
  headers: Record<string, string> = {},
  queryParams: Record<string, string> = {}
): Promise<CowsAPIResponseData> {
  const query = new URLSearchParams(queryParams).toString();
  const url = `${process.env.REACT_APP_FARM_API_URL}/v1/farms/${farmId}/cows?${query}`;

  return APIService.get(url, {
    ...headers,
    mode: 'cors',
  });
}

async function getObservationsForCow(
  farmId: number,
  cowId: number,
  headers: Record<string, string> = {},
  queryParams: Record<string, string> = {}
): Promise<ObservationsAPIResponseData> {
  const query = new URLSearchParams(queryParams).toString();
  // eslint-disable-next-line max-len
  const url = `${process.env.REACT_APP_FARM_API_URL}/v1/farms/${farmId}/cows/${cowId}/observations?${query}`;

  return APIService.get(url, {
    ...headers,
    'Content-Type': 'application/json',
  });
}

// eslint-disable-next-line import/prefer-default-export
export const cowData = {
  getCowDetails,
  getCows,
  getObservationsForCow,
};
