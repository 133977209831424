/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';

import type { Snooze } from '../../api/cow-snooze';
import { clamp, currentlySnoozed, subarrayBetween } from './dataFormatting';
import {
  APIConfirmedIdentity,
  SnoozeVisiblity,
  collectionState,
  cowState,
  dayState,
  eidAccuracyState,
  graphState,
  milkingState,
  statistics,
  urlState,
} from './dataInterfaces';
import {
  confirmedIdentityRecord,
  cowRecord,
  dayRecord,
  eidAccuracyRecord,
  graphRecord,
  idRecord,
  milkingRecord,
  videoRecord,
} from './inputDataInterfaces';
import type { RootState } from './store';

/* Root State */
interface FarmDataState {
  displayName: string;
  farmIdentifier: string;
  farmId: number | null;
  loading: boolean;
  refreshScheduled: boolean;
  legacyS3Dir: string;
  /* S3 data */
  cows: cowState[];
  tagToCowMap: Record<string, cowState>;
  collections: collectionState[];
  collectionsMap: Record<string, collectionState>;
  collectionCount: number;
  milkingDays: dayState[];
  prevalence: graphState[];
  severe_prevalence: graphState[];
  eid_accuracy: eidAccuracyState[];
  /* API caches */
  imageCache: urlState[];
  videoCache: urlState[];
  snoozeCache: Record<string, Snooze>;
  confirmedIdentityCache: Record<string, APIConfirmedIdentity>;
}

export const farmDataReduxSlice = createSlice({
  name: 'farm',
  initialState: {
    displayName: 'Error: no farms(s)',
    loading: true,
    refreshScheduled: false,
    legacyS3Dir: '',
    cows: [],
    tagToCowMap: Object.create(null),
    collections: [],
    collectionsMap: Object.create(null),
    collectionCount: 0,
    milkingDays: [],
    prevalence: [],
    severe_prevalence: [],
    eid_accuracy: [],
    imageCache: [],
    videoCache: [],
    snoozeCache: {},
    confirmedIdentityCache: Object.create(null),
    farmIdentifier: '',
    farmId: null,
  } as FarmDataState,
  reducers: {
    setFarmId: (state, action: PayloadAction<number>) => {
      state.farmId = action.payload;
    },
    setDisplayFarm: (state, action: PayloadAction<string>) => {
      state.displayName = action.payload;
    },
    setLegacyS3Dir: (state, action: PayloadAction<string>) => {
      state.legacyS3Dir = action.payload;
    },
    setFarmIdentifier: (state, action: PayloadAction<string>) => {
      state.farmIdentifier = action.payload;
    },
    // loading status
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    // refresh status
    setRefreshScheduled: (state, action: PayloadAction<boolean>) => {
      state.refreshScheduled = action.payload;
    },
    addAllData: (state, action: PayloadAction<any>) => {
      /* name the payload data */
      const videos: videoRecord[] = action.payload[0];
      const identities: cowRecord[] = action.payload[1];
      const milkings: dayRecord[] = action.payload[2];
      const uuidRecords: idRecord[] = action.payload[3];
      const prevalence: graphRecord[] = action.payload[4];
      const snoozes: Snooze[] = action.payload[5];
      const confirmedIdentities: confirmedIdentityRecord[] = action.payload[6];
      const eidAccuracies: eidAccuracyRecord[] = action.payload[7];
      const scoreVisibility: boolean = action.payload[8];

      /* clear state, in case we are loading a new farm */
      state.cows = [];
      state.collections = [];
      state.collectionsMap = Object.create(null);
      state.tagToCowMap = Object.create(null);
      state.collectionCount = 0;
      const sortedCollections: collectionState[] = [];

      state.milkingDays = [];
      state.imageCache = [];
      state.videoCache = [];
      state.snoozeCache = {};
      state.confirmedIdentityCache = {};

      /* add the confirmed identity */
      state.confirmedIdentityCache = Object.fromEntries(
        confirmedIdentities.map((i: confirmedIdentityRecord) => [
          i.CollectionUuid,
          {
            uuid: i.CollectionUuid,
            eid: i.Eid,
            wrong_eid: i.WrongEid ?? [],
          } as APIConfirmedIdentity,
        ])
      );

      /* add the snooze data */
      state.snoozeCache = Object.fromEntries(snoozes.map((s: Snooze) => [s.eid, s]));

      state.eid_accuracy = eidAccuracies.map(
        (g: eidAccuracyRecord) =>
          ({
            date: moment(g.date).valueOf(),
            videos: g.videos,
            eids: g.eids,
            correct: g.correct,
            wrong: g.wrong,
          } as eidAccuracyState)
      );

      /* parse the extra graphs */
      state.prevalence = prevalence.map((g: graphRecord) => ({
        date: moment(g.date).valueOf(),
        value: g.prevalence_lame,
      }));
      state.severe_prevalence = prevalence.map((g: graphRecord) => ({
        date: moment(g.date).valueOf(),
        value: g.prevalence_severely_lame,
      }));

      /* preprocessing for performance */
      const uuidToEid: { [id: string]: string } = {};
      const uuidToGateTime: { [id: string]: string | undefined } = {};

      uuidRecords.forEach((record: idRecord) => {
        uuidToEid[record.id] = record.eid;
        uuidToGateTime[record.id] = record.gateTime;
      });
      const cowIndexes: { [eid: string]: number } = {};

      /* add the videos as collections */
      videos.sort((a: videoRecord, b: videoRecord) => new Date(b.date).getTime() - new Date(a.date).getTime());
      videos.forEach((v: videoRecord) => {
        // collection uuid
        const uuid = v.vid.split('.')[0];
        // check user permissions
        // if user does not have scores enabled
        // set to -1
        // else:
        // eslint-disable-next-line no-nested-ternary
        const score = scoreVisibility
          ? v.score == null || Number.isNaN(Number(v.score))
            ? undefined
            : clamp(v.score, 0.0, 3.0)
          : -1;

        const dateScored = moment(v.date).valueOf();
        // check if eid exists for uuid
        const assignedEid = uuidToEid[uuid];
        // check if eid exists for uuid
        const gateTime = uuidToGateTime[uuid];
        // does this cow exist?
        let cowId = cowIndexes[assignedEid];

        if (cowId === undefined) {
          // create cow record
          const cowIdentity =
            assignedEid !== undefined ? identities.find((c: cowRecord) => c.eid === assignedEid) : undefined;

          cowId = state.cows.length;
          const cowEid = cowIdentity?.eid;
          const cowTag = cowIdentity?.tag;
          const cow = {
            id: cowId,
            eid: cowEid,
            tag: cowIdentity?.tag,
            birthID: cowIdentity?.bid,
            breed: cowIdentity?.breed,
            dob: cowIdentity?.dob ? moment(cowIdentity.dob).toISOString() : undefined,
            score_min: undefined,
            score_max: undefined,
            totalCollections: 0,
            last_scored: dateScored,
            last_score: score,
            collection_ids: [],
          } as cowState;

          state.cows.push(cow);

          if (cowEid !== undefined) {
            cowIndexes[cowEid] = cowId;
          }

          // Check if a cow with exact same cow tag exists in the `tagToCowMap`
          // if so, check if it's the most recent cow and then overwrite the previously stored cow in the map
          if (
            cowTag !== undefined &&
            (!(cowTag in state.tagToCowMap) || cow.last_scored > state.tagToCowMap[cowTag].last_scored)
          ) {
            state.tagToCowMap[cowTag] = cow;
          }
        }

        const newCollection = {
          id: state.collectionCount,
          uuid,
          score,
          date: dateScored,
          gateTime,
          cowId,
        } as collectionState;

        state.collections.push(newCollection);
        state.collectionsMap[uuid] = newCollection;
        sortedCollections.push(newCollection);
        state.cows[cowId].collection_ids.push(state.collectionCount);
        state.cows[cowId].totalCollections++;

        if (
          score !== undefined &&
          (state.cows[cowId].last_score === undefined || dateScored > state.cows[cowId].last_scored)
        ) {
          state.cows[cowId].last_score = score;
          state.cows[cowId].last_scored = dateScored;
        }

        state.collectionCount++;
      });
      sortedCollections.sort((a, b) => a.date - b.date);
      [...state.cows]
        .sort((a, b) => {
          if (a.last_scored === undefined) {
            if (b.last_scored === undefined) {
              return 0;
            }

            return -1;
          }

          if (b.last_scored === undefined) {
            return 1;
          }

          return b.last_scored - a.last_scored;
        })
        .forEach((v, i) => {
          state.cows[v.id].lastScoredIndex = i;
        });

      /* add the day records as milkings */
      if (milkings.length > 0) {
        milkings.forEach((day: dayRecord) => {
          // TODO check if milking already exists
          const milkingsTemp: milkingState[] = [];

          day.milkings.forEach((m: milkingRecord) => {
            const start = moment(m.start).toDate();
            const end = moment(m.end).toDate();
            const collectionsForMilking = subarrayBetween(
              sortedCollections,
              start,
              end,
              (item) => moment(item.date).toDate(),
              (a, b) => a.getTime() - b.getTime()
            );

            milkingsTemp.push({
              start: start.toISOString(),
              end: end.toISOString(),
              eid_count: m.eids,
              track_count: m.tracks,
              score_count: m.scores,
              score_mean: m.score_mean,
              score_bins: m.score_bins,
              collection_ids: collectionsForMilking.map((coll: collectionState) => coll.id),
              // memoized collections within time period
            } as milkingState);
          });
          state.milkingDays.push({
            date: moment(day.date).valueOf(),
            eid_count: day.eids,
            track_count: day.tracks,
            score_count: day.scores,
            score_mean: day.score_mean,
            score_bins: day.score_bins,
            milkings: milkingsTemp,
          } as dayState);
        });
      } else {
        /* milking data does not exist, so bin the data before and after midday */
        sortedCollections.forEach((c: collectionState) => {
          const dayDate = moment(c.date).startOf('day');
          let day = state.milkingDays.find((d) => moment(d.date).startOf('day').isSame(dayDate));

          if (day === undefined) {
            const collections = state.collections.filter((coll) => moment(coll.date).startOf('day').isSame(dayDate));
            const scoredCollections = collections.filter(
              // eslint-disable-next-line @typescript-eslint/no-shadow
              (c) => c.score !== undefined
            );

            day = {
              date: dayDate.valueOf(),
              eid_count: 0,
              track_count: collections.length,
              score_count: scoredCollections.length,
              score_mean:
                scoredCollections.reduce(
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  (sum: number, c: collectionState) =>
                    sum +
                    // eslint-disable-next-line no-nested-ternary
                    (c === undefined ? 0 : c.score === undefined ? 0 : c.score),
                  0
                ) / scoredCollections.length,
              score_bins: scoredCollections.reduce(
                // eslint-disable-next-line @typescript-eslint/no-shadow
                (bins: number[], c: collectionState) => {
                  bins[c.score === undefined ? 0 : Math.floor(c.score * 2)] += c.score === undefined ? 0 : 1;

                  return bins;
                },
                [0, 0, 0, 0, 0, 0]
              ),
              milkings: [],
            } as dayState;
            state.milkingDays.push(day);
          }

          if (day !== undefined) {
            const start =
              moment(c.date).hour() < 12
                ? moment(c.date).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                : moment(c.date).set({
                    hour: 11,
                    minute: 59,
                    second: 59,
                    millisecond: 999,
                  });

            const end =
              moment(c.date).hour() < 12
                ? moment(c.date).set({
                    hour: 12,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                : moment(c.date).set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999,
                  });

            let milking = day.milkings.find(
              (m: milkingState) =>
                (moment(c.date).hour() < 12 && moment(m.start).hour() < 12) ||
                (moment(c.date).hour() >= 12 && moment(m.end).hour() >= 12)
            );

            if (milking === undefined) {
              const collections = sortedCollections.filter((coll: collectionState) =>
                moment(coll.date).isBetween(start, end)
              );

              const scoredCollections = collections.filter(
                // eslint-disable-next-line @typescript-eslint/no-shadow
                (c) => c.score !== undefined
              );

              milking = {
                start: moment(
                  collections.reduce(
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    (d: number, c: collectionState) => Math.min(d, c.date),
                    Infinity
                  )
                ).toISOString(),
                end: moment(
                  collections.reduce(
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    (d: number, c: collectionState) => Math.max(d, c.date),
                    0
                  )
                ).toISOString(),
                eid_count: 0,
                track_count: collections.length,
                score_count: scoredCollections.length,
                score_mean:
                  scoredCollections.reduce(
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    (sum: number, c: collectionState) =>
                      sum +
                      // eslint-disable-next-line no-nested-ternary
                      (c === undefined ? 0 : c.score === undefined ? 0 : c.score),
                    0
                  ) / scoredCollections.length,
                score_bins: scoredCollections.reduce(
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  (bins: number[], c: collectionState) => {
                    bins[c.score === undefined ? 0 : Math.floor(c.score * 2)] += c.score === undefined ? 0 : 1;

                    return bins;
                  },
                  [0, 0, 0, 0, 0, 0]
                ),
                collection_ids: collections.map((coll: collectionState) => coll.id),
              } as milkingState;
              day.milkings.push(milking);
            }
          }
        });
      }

      /* finished set loading false */
      state.loading = false;
    },

    // updating data
    cacheVideo: (state, action: PayloadAction<any>) => {
      state.videoCache.push({
        collection_id: action.payload.collection_id,
        url: action.payload.url,
      } as urlState);
    },
    cacheImage: (state, action: PayloadAction<any>) => {
      state.imageCache.push({
        collection_id: action.payload.collection_id,
        url: action.payload.url,
      } as urlState);
    },
    deleteSnooze: (state, action: PayloadAction<any>) => {
      const { eid } = action.payload;

      delete state.snoozeCache[eid];
    },
    cacheSnooze: (state, action: PayloadAction<Snooze>) => {
      state.snoozeCache[action.payload.eid] = action.payload;
    },
    cacheCowIdentityConfirmation: (state, action: PayloadAction<any>) => {
      const newData = {
        uuid: action.payload.uuid,
        eid: action.payload.eid,
        wrong_eid: action.payload.wrong_eid,
      } as APIConfirmedIdentity;

      state.confirmedIdentityCache[action.payload.uuid] = newData;
    },
  },
});

export const {
  setFarmId,
  setLegacyS3Dir,
  setFarmIdentifier,
  setDisplayFarm,
  setRefreshScheduled,
  setLoading,
  addAllData,
  cacheImage,
  cacheVideo,
  cacheSnooze,
  deleteSnooze,
  cacheCowIdentityConfirmation,
} = farmDataReduxSlice.actions;
export const getFarm = (state: RootState) => state.farm;
export const getFarmId = (state: RootState) => state.farm.farmId;
export const getDisplayFarm = (state: RootState) => state.farm.displayName;
export const getLegacyS3Dir = (state: RootState) => state.farm.legacyS3Dir;
export const getFarmIdentifier = (state: RootState) => state.farm.farmIdentifier;
export const getLoading = (state: RootState) => state.farm.loading;
export const getRefreshScheduled = (state: RootState) => state.farm.refreshScheduled;

/* These Selectors have derived values
 * largely for data-grid
 */
export const getAllCows = (state: RootState) => state.farm.cows;
export const getAllCollections = (state: RootState) => state.farm.collections;
export const getAllMilkingDays = (state: RootState) => state.farm.milkingDays;
export const getAllMilkings = (state: RootState) =>
  state.farm.milkingDays
    .reduce((r: milkingState[], day: dayState) => r.concat(day.milkings), [])
    .filter((d) => d.track_count > 0 && d.score_count >= 0);
export const getPrevalenceGraph = (state: RootState) => state.farm.prevalence;
export const getSeverePrevalenceGraph = (state: RootState) => state.farm.severe_prevalence;
export const getEIDAccuracy = (state: RootState) => state.farm.eid_accuracy;
export const getAllSnoozes = (state: RootState) => state.farm.snoozeCache;

/* META and AGGREGATE DATA */
export const getOverviewStatistics = (state: RootState) => {
  if (state.farm.cows.length === 0 && state.farm.collectionCount === 0) {
    return {
      videos: 0,
      cows: 0,
      lame_cows: 0,
      average_score: 0,
      prevalence: 0,
      severe_prevalence: 0,
      eid_accuracy: 0,
    } as statistics;
  }

  const knownCows = state.farm.cows.filter((c: cowState) => c.eid !== undefined);
  const prevalence = state.farm.prevalence.length > 0 ? state.farm.prevalence[0].value : 0;
  const totalCorrect = state.farm.eid_accuracy.reduce((sum: number, e: eidAccuracyState) => sum + e.correct, 0);
  const totalWrong = state.farm.eid_accuracy.reduce((sum: number, e: eidAccuracyState) => sum + e.wrong, 0);

  return {
    videos: state.farm.collectionCount,
    cows: knownCows.length,
    lame_cows: Math.floor(prevalence * knownCows.length),
    average_score:
      knownCows.reduce(
        (sum: number, c: cowState) =>
          sum + (c === undefined ? 0 : state.farm.collections[c.collection_ids[0]].score ?? 0),
        0
      ) / knownCows.length,
    prevalence,
    severe_prevalence: state.farm.severe_prevalence.length > 0 ? state.farm.severe_prevalence[0].value : 0,
    eid_accuracy: totalCorrect / (totalCorrect + totalWrong),
  } as statistics;
};

/* META DATA */
export const getCollectionCount = (state: RootState) => state.farm.collectionCount;

export const getCollectionMap = (state: RootState) => state.farm.collectionsMap;

export const getCollectionByCollectionIdGetter = (state: RootState) => (collectionId: string) =>
  state.farm.collectionsMap[collectionId];

export const getCowCount = (state: RootState) => state.farm.cows.length;
export const getAllDataDates = (state: RootState) =>
  state.farm.milkingDays.length < 1 ? [] : state.farm.milkingDays.map((d: dayState) => d.date);

/* RENDER DATA convert ids -> collections */
export const getCowCollectionsIds = (id: number | undefined) => (state: RootState) => {
  if (id === undefined) return undefined;
  const cow = state.farm.cows.find((c: cowState) => id === c.id);

  if (cow === undefined) return [];

  return cow.collection_ids;
};

/* RENDER DATA convert ids -> collections */
export const getCowFromTag = (state: RootState) => (tag: string | undefined) => {
  if (tag === undefined) return undefined;
  const cowId = state.farm.tagToCowMap[tag];

  if (cowId === undefined) return undefined;

  return cowId;
};

export const getCowsFromIds = (cow_ids: number[] | undefined) => (state: RootState) => {
  if (cow_ids === undefined) return [];

  return cow_ids.map((cow_id: number) => state.farm.cows[cow_id]);
};

export const getCollectionsFromIds = (collection_ids: number[] | undefined) => (state: RootState) => {
  if (collection_ids === undefined) return [];

  return collection_ids.map((collection_id: number) => state.farm.collections[collection_id]);
};

export const getCollectionsWithTagsFromIds = (collection_ids: number[] | undefined) => (state: RootState) => {
  if (collection_ids === undefined) return [];

  return collection_ids.map((collection_id: number) => ({
    ...state.farm.collections[collection_id],
    tag: state.farm.cows[state.farm.collections[collection_id].cowId ?? -1].tag,
  }));
};

export const getCollectionsWithConfirmedIdentityFromIds =
  (collection_ids: number[] | undefined) => (state: RootState) => {
    if (collection_ids === undefined) return [];

    return collection_ids.map((collection_id: number) => ({
      ...state.farm.collections[collection_id],
      tag: state.farm.cows[state.farm.collections[collection_id].cowId ?? -1].tag,
      confirmedIdentity: state.farm.confirmedIdentityCache[state.farm.collections[collection_id].uuid],
    }));
  };

/* FILTER DATA */
export const getLameCowIds = (threshold: number) => (state: RootState) =>
  state.farm.cows
    .filter((c: cowState) => c.last_score !== undefined && c.last_score >= threshold)
    .map((c: cowState) => c.id);

/* filters out un-identified cows */
export const getLameCows =
  (threshold: number, snooze_mode: SnoozeVisiblity, lastScored: number) => (state: RootState) => {
    const targetDate = new Date();

    targetDate.setDate(targetDate.getDate() - lastScored);

    return state.farm.cows
      .map((cow: cowState) => ({
        ...cow,
        snoozed: state.farm.snoozeCache[cow.eid],
      }))
      .filter(
        (c) =>
          c.eid !== undefined &&
          c.last_score !== undefined &&
          c.last_score >= threshold &&
          c.last_scored >= lastScored &&
          (snooze_mode === SnoozeVisiblity.all ||
            (snooze_mode === SnoozeVisiblity.only_unsnoozed && !currentlySnoozed(c.snoozed)) ||
            (snooze_mode === SnoozeVisiblity.only_snoozed && currentlySnoozed(c.snoozed)) ||
            (snooze_mode === SnoozeVisiblity.unseen &&
              c.last_scored >= targetDate.valueOf() &&
              !currentlySnoozed(c.snoozed)))
      );
  };

/* cows with eid */
export const getAllIdentifiedCows = (state: RootState) => {
  const cows = state.farm.cows
    .filter((c) => c.eid !== undefined)
    .map((cow) => ({
      ...cow,
      collection_uuids: new Set(cow.collection_ids.map((coll_id) => state.farm.collections[coll_id].uuid)),
    }));

  // As an optimisation, pre-calculate all the collections associated with each EID
  const eidIdentityMap = {} as { [key: string]: string[] };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(state.farm.confirmedIdentityCache).forEach(([_k, i]) => {
    if (i.eid !== undefined) {
      if (!(i.eid in eidIdentityMap)) {
        eidIdentityMap[i.eid] = [];
      }

      eidIdentityMap[i.eid].push(i.uuid);
    }

    i.wrong_eid.forEach((wrong_eid) => {
      if (!(wrong_eid in eidIdentityMap)) {
        eidIdentityMap[wrong_eid] = [];
      }

      eidIdentityMap[wrong_eid].push(i.uuid);
    });
  });

  // Count unconfirmed collections by removing all the above collection UUIDs from the cow's
  // videos. Note that we avoid simple calculating cow.collection_uuids.size - identities.length,
  // since collections can be present in identities without being in cow.collection_uuids. This
  // can happen, for example, if another cow's collection is confirmed as being this cow.
  cows.forEach((cow) => {
    const identities = eidIdentityMap[cow.eid] || [];

    identities.forEach((uuid) => {
      cow.collection_uuids.delete(uuid);
    });
    cow.unconfirmed_collections = cow.collection_uuids.size;
  });

  return cows;
};

export const getUnseenCows = () => (state: RootState) =>
  state.farm.cows.filter(
    (c: cowState) => c.last_score === undefined || c.last_score > moment().valueOf() - 300 * 24 * 60 * 60 * 1000
  );

export const getCollectionsWindow = (start: Moment, end: Moment) => (state: RootState) =>
  state.farm.collections
    .filter((c: collectionState) => moment(c.date).isBetween(start, end))
    .map((c: collectionState) => c.id);

/* SINGLE ITEMS */
export const getCow = (id: number | undefined) => (state: RootState) => {
  if (id === undefined) return undefined;

  return state.farm.cows[id]; // .find((c: cowState) => id == c.id);
};

export const getCollection = (id: number | undefined) => (state: RootState) => {
  if (id === undefined) return undefined;

  return state.farm.collections[id]; // .find((c: collectionState) => id == c.id);
};

export const getS3Image = (id: number) => (state: RootState) =>
  state.farm.imageCache.find((s: urlState) => id === s.collection_id)?.url;

export const getS3Video = (id: number) => (state: RootState) =>
  state.farm.videoCache.find((s: urlState) => id === s.collection_id)?.url;

export const getSnoozeState = (eid: string | undefined) => (state: RootState) => {
  if (eid === undefined) return undefined;

  return state.farm.snoozeCache[eid];
};

export const getConfirmedIdentityState = (uuid: string | undefined) => (state: RootState) => {
  if (uuid === undefined) return undefined;

  return state.farm.confirmedIdentityCache[uuid];
};

export default farmDataReduxSlice.reducer;
