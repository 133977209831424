import React from 'react';

import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import ChartCowLameHistoryLinePreview from '../Charts/ChartCowLameHistoryLinePreview';
import TableCowDetailsPreview from '../Charts/TableCowDetailsPreview';
import TableCowLameStatsPreview from '../Charts/TableCowLameStatsPreview';
import { getCurrentCow } from '../Data/CowDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import LoadingOverlay from '../common/LoadingOverlay';

function CardCowProfilePreview(props: any) {
  const cow = useAppSelector(getCurrentCow).currentCow;
  const { isLoading } = props;

  return (
    <Stack sx={{ flexGrow: 1, padding: 1, gap: 2, position: 'relative' }}>
      {cow && !isLoading ? (
        <>
          <TableCowDetailsPreview cow={cow} />

          <Stack sx={{ flexGrow: 1, position: 'relative' }}>
            <Typography component="h3" variant="h6">
              Lameness
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <ChartCowLameHistoryLinePreview cowId={cow.cowId} latestObservationDate={cow.latestObservationDate} />
            </Box>
          </Stack>

          <TableCowLameStatsPreview cow={cow} />
        </>
      ) : (
        <LoadingOverlay blurBackground />
      )}
    </Stack>
  );
}

export default CardCowProfilePreview;
