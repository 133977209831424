import React from 'react';
import { Link as RouterLink, createSearchParams } from 'react-router-dom';

import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';

import TableCowDetails from '../Charts/TableCowDetails';
import TableCowLameStats from '../Charts/TableCowLameStats';
import TableVideoDetails from '../Charts/TableVideoDetails';
import VideoPlayer from '../Charts/VideoPlayer';
import { getCollection, getCollectionsFromIds, getCow, getCowCollectionsIds } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import { isScoreVisible } from '../Data/userReduxSlice';
import CardDataTable from './CardDataTable';
import WatchCowButton from './CardWatchCowButton';
import { collectionActionReportIssue, collectionColumns } from './DataTableProps';
import FormReportCollection from './FormReportCollection';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyTable: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {},
  videoHistory: {
    height: '100%',
  },
  videoPlayer: {},
  details: {},
  cowActions: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0px',
  },
  cowBrowserLink: {
    marginLeft: 'auto',
    display: 'inline-block',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingRight: '1rem',
  },
}));

// eslint-disable-next-line react/require-default-props
function CowBrowserLink({ cowId }: { cowId?: number }) {
  const classes = useStyles();
  const cow = useAppSelector(getCow(cowId));

  return cow?.tag !== undefined ? (
    <Link
      className={classes.cowBrowserLink}
      component={RouterLink}
      to={{
        pathname: '/cows',
        search: createSearchParams({ cow: cow.tag.toString() }).toString(),
      }}
      align="right"
    >
      View in Cow Browser &rarr;
    </Link>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
}

function VideoDetails(props: { collection: any }) {
  const classes = useStyles();
  const { collection } = props;

  return (
    <div className={classes.details}>
      <TableVideoDetails collection_id={collection.id} />
    </div>
  );
}

// eslint-disable-next-line react/require-default-props
function HistoryTable(props: { collection?: any; setCollection: any }) {
  const classes = useStyles();
  const { collection, setCollection } = props;
  const scoresVisible = useAppSelector(isScoreVisible);
  const cowCollections = useAppSelector(getCowCollectionsIds(collection?.cowId));
  const videoList = useAppSelector(getCollectionsFromIds(cowCollections));
  const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'date', sort: 'desc' }]);
  const [page, setPage] = React.useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_pageSize, setPageSize] = React.useState<number>(0);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([collection.id]);
  const [reportCollection, setReportCollection] = React.useState<number | undefined>(undefined);

  return (
    <div className={classes.videoHistory}>
      <CardDataTable
        data={videoList}
        columns={collectionColumns(['status', 'score', 'dateTime'], scoresVisible ? [] : ['score']).concat([
          collectionActionReportIssue((collection_id: number) => setReportCollection(collection_id)),
        ])}
        page={page}
        onPageChange={setPage}
        setPageSize={setPageSize}
        sortModel={sortModel}
        setSortModel={setSortModel}
        header={false}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        emptyMessage="No Videos"
        selectRowCallback={(rowId: GridRowId | undefined) => {
          if (rowId === undefined) setCollection(undefined);
          else setCollection(rowId as number);
        }}
      />

      <FormReportCollection
        collection_id={reportCollection}
        visible={reportCollection !== undefined}
        close={() => {
          setReportCollection(undefined);
        }}
      />
    </div>
  );
}

function CardCowVideo(props: any) {
  const classes = useStyles();
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    collection_id,
    showProfile,
    noLink,
    showLameness,
    showDetails,
    showHistory,
    changeCollection,
  } = props;

  const collection = useAppSelector(getCollection(collection_id));
  const novideo = (
    <div className={classes.emptyTable}>
      <Typography color="primary" align="right" component="h4" variant="h6">
        No Video Selected
      </Typography>
    </div>
  );

  const cowProfileTable = showProfile ? (
    <div className={classes.table}>
      <TableCowDetails cowId={collection?.cowId} />

      <div className={classes.cowActions}>
        <WatchCowButton cowId={collection?.cowId} />
        {!noLink && <CowBrowserLink cowId={collection?.cowId} />}
      </div>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );

  const cowLamenessTable = showLameness ? (
    <div className={classes.table}>
      <TableCowLameStats cow_id={collection?.cowId} />
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );

  return collection_id !== undefined ? (
    <div className={classes.root}>
      {cowProfileTable}
      {cowLamenessTable}

      <div className={classes.videoPlayer}>
        <VideoPlayer collectionId={collection_id} />
      </div>

      {showDetails && <VideoDetails collection={collection} />}

      {showHistory ? (
        <HistoryTable setCollection={changeCollection} collection={collection} />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )}
    </div>
  ) : (
    novideo
  );
}

export default CardCowVideo;
