import React from 'react';

import ReportIcon from '@mui/icons-material/Report';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { Snooze } from '../../api/cow-snooze';
import type { Cow, Observation } from '../../api/farmAPI/types';
import { ageString } from '../Data/dataFormatting';
import { collectionState } from '../Data/dataInterfaces';
import LameStatus from '../common/LameStatus';

const formatScore = (params: GridValueFormatterParams) => {
  if (!params.value) {
    return '-';
  }

  return parseFloat(params.value).toFixed(2);
};

const dynamicYear = (year: number) => {
  let yearString = '';

  if (dayjs().year() !== year) {
    yearString = ' YYYY';
  }

  return yearString;
};

const formatDateTime = (params: GridValueFormatterParams) => {
  if (params.value === null || params.value === undefined) {
    return '-';
  }

  const dateTime = dayjs(params.value);

  return dateTime.format(`MMM DD,${dynamicYear(dateTime.year())} HH:mm:ss`);
};

const formatTime = (params: GridValueFormatterParams) => {
  if (params.value === null || params.value === undefined) {
    return '-';
  }

  return dayjs(params.value).format('HH:mm:ss');
};

const whitelistedFilterFields = ['eid', 'breed', 'age'];

const getColumns = (allColumns: GridColDef[]) => {
  return (visibleFields: string[], hiddenFields?: string[]): GridColDef[] => {
    return allColumns.map((col: GridColDef) => ({
      ...col,
      hide: !visibleFields.includes(col.field) || hiddenFields?.includes(col.field),
      filterable:
        (visibleFields.includes(col.field) || whitelistedFilterFields.includes(col.field)) &&
        !hiddenFields?.includes(col.field),
    }));
  };
};

export const cowColumns = getColumns([
  {
    field: 'currentStatus',
    type: 'number',
    headerName: 'Status',
    width: 100,
    valueGetter: (params: GridValueGetterParams) => (params.row as Cow).latestObservationScore,
    renderCell: (params: GridCellParams) => <LameStatus score={(params.row as Cow).latestObservationScore} />,
  },
  {
    field: 'electronicId',
    type: 'string',
    headerName: 'Electronic ID',
    flex: 1,
  },
  {
    field: 'tag',
    type: 'number',
    headerName: 'Tag',
    flex: 1,
  },
  { field: 'birthId', type: 'string', headerName: 'Birth ID', flex: 1 },
  {
    field: 'latestObservationScore',
    type: 'number',
    headerName: 'Score',
    flex: 1,
    valueFormatter: formatScore,
  },
  {
    field: 'latestObservationDate',
    type: 'dateTime',
    headerName: 'Last Seen',
    flex: 2,
    valueFormatter: formatDateTime,
  },
  {
    field: 'observationCount',
    type: 'number',
    headerName: 'Total Videos',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => (params.row as Cow).observationCount,
  },
  {
    field: 'unconfirmed_collections',
    type: 'number',
    headerName: 'Unconfirmed Videos',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return Number((params.row as Cow).observationCount) - Number((params.row as Cow).confirmedObservationCount);
    },
  },
  {
    field: 'snoozed',
    type: 'dateTime',
    headerName: 'Time Observed',
    flex: 2,
    valueGetter: (params: GridValueGetterParams) => (params.value as Snooze)?.snoozeTime,
    valueFormatter: formatDateTime,
  },
  {
    field: 'snoozeExpiry',
    type: 'dateTime',
    headerName: 'Observed Until',
    flex: 2,
    valueGetter: (params: GridValueGetterParams) => (params.row.snoozed as Snooze)?.snoozeExpiry,
    valueFormatter: formatDateTime,
  },
  {
    field: 'breed',
    type: 'string',
    headerName: 'Breed',
    flex: 1,
  },
  {
    field: 'dateOfBirth',
    type: 'dateTime',
    headerName: 'Date of Birth',
    flex: 1,
    valueGetter: ({ value }: GridValueGetterParams) => new Date(value),
    valueFormatter: (params: GridValueFormatterParams) => {
      formatDateTime(params.value);
    },
  },
  {
    field: 'age',
    type: 'string',
    headerName: 'Age',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => ageString((params.row as Cow).dateOfBirth),
  },
]);

export const observationColumns = getColumns([
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    valueGetter: (params: GridValueGetterParams) => {
      return (params.row as collectionState).score;
    },
    renderCell: (params: GridCellParams) => <LameStatus score={params.value} />,
  },
  {
    field: 'score',
    type: 'number',
    headerName: 'Score',
    valueFormatter: formatScore,
    flex: 1,
  },
  {
    field: 'videoURL',
    type: 'string',
    headerName: 'Video',
    valueFormatter: (params: GridValueFormatterParams) => (params.value as string).substring(0, 8),
    flex: 1,
  },
  {
    field: 'trackDate',
    type: 'dateTime',
    headerName: 'Date',
    flex: 1,
    valueFormatter: formatDateTime,
  },
  {
    field: 'time',
    type: 'dateTime',
    headerName: 'Time',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return (params.row as Observation).trackDate;
    },
    valueFormatter: formatTime,
  },
]);

export const observationActionReportIssue = (showReport: (observationId: number) => void) => {
  return {
    field: 'actions',
    type: 'actions',
    width: 1,
    headerName: 'x',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={`action-id-${(params.row as collectionState).uuid}`}
        icon={<ReportIcon sx={{ bgcolor: 'white', borderRadius: 3 }} color="primary" />}
        onClick={() => {
          showReport(params.row.observationId);
        }}
        label="Report Issue"
      />,
    ],
  };
};
