import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {},
  })
);

function Selector(props: any) {
  const classes = useStyles();
  // eslint-disable-next-line react/destructuring-assignment
  const [value, setValue] = React.useState<string>(props.value);
  const { name, disabled, onChange, options } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    onChange(event.target.value as string);
  };

  return (
    <FormControl className={classes.formControl} size="small">
      {name !== undefined ? (
        <InputLabel id="demo-simple-select-label">{name}</InputLabel>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )}

      <Select
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        variant="standard"
        onChange={handleChange}
      >
        {Object.entries(options).map(([opt, selectValue]: [any, any]) => (
          <MenuItem key={`option-${opt}`} value={selectValue}>
            {opt}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Selector;
