import React from 'react';

import { Alert, FormControl, FormGroup, FormLabel, InputAdornment, Snackbar, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Auth } from 'aws-amplify';

import { Farm } from '../../api/farmAPI/types';
import { getLamenessThresholds, isPresetThreshold } from '../Data/dataFormatting';
import {
  getFarm,
  setDisplayFarm,
  setFarmId,
  setFarmIdentifier,
  setLegacyS3Dir,
  setRefreshScheduled,
} from '../Data/farmDataReduxSlice';
// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../Data/pageReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import {
  getAvailableFarms,
  getCurrentLoggedUser,
  getEmail,
  getLameThreshold,
  getSnoozeDuration,
  setLameThreshold as storeLameThreshold,
  setSnoozeDuration as storeSnoozeDuration,
} from '../Data/userReduxSlice';
import { DefaultOrNumericRadioGroup } from '../DefaultOrNumericRadioGroup';
import { TitleStack } from './TitleStack';

const lameThresholdOptions = [
  ...getLamenessThresholds().map(({ name, value }) => ({
    name: `${name}${value > 0 ? ` (> ${value})` : ''}`,
    value,
  })),
  {
    name: 'Custom',
    value: 'custom',
  },
];

function SettingsLayout(props: any) {
  /* update page */
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentLoggedUser);
  const email = useAppSelector(getEmail);
  const availableFarms = useAppSelector(getAvailableFarms);
  const farm = useAppSelector(getFarm);
  const threshold = useAppSelector(getLameThreshold);
  const duration = useAppSelector(getSnoozeDuration);
  const [isSaved, setIsSaved] = React.useState<boolean>(false);
  const { pageIndex } = props;
  // BEGIN Controlled state for the form BEGIN
  // Selected Farm
  const [selectedFarm, setSelectedFarm] = React.useState<string>(farm.displayName);
  // Lameness selector
  const [isCustomLameness, setIsCustomLameness] = React.useState<boolean>(!isPresetThreshold(threshold));
  const [selectedLameness, setSelectedLameness] = React.useState<string>(
    isPresetThreshold(threshold) ? threshold.toString() : 'custom'
  );

  const [lamenessThreshold, setLamenessThreshold] = React.useState<string>(threshold.toString());

  React.useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);

  // Observation selector
  const [isCustomObservationPeriod, setIsCustomObservationPeriod] = React.useState<boolean>(duration > 0);
  const [customObservationPeriod, setCustomObservationPeriod] = React.useState<string>(
    duration > 0 ? duration.toString() : '14'
  );

  // Error States
  const [isThresholdError, setIsThresholdError] = React.useState<boolean>(false);
  const [isPeriodError, setIsPeriodError] = React.useState<boolean>(false);
  // END Controlled State for the form END

  // BEGIN handlers for complex fields

  const changeLameThreshold = (event: SelectChangeEvent) => {
    setSelectedLameness(() => {
      setIsThresholdError(false);
      setIsSaved(false);
      setIsCustomLameness(() => {
        if (event.target.value === 'custom') {
          setLamenessThreshold(threshold.toString());

          return true;
        }

        setLamenessThreshold(event.target.value);

        return false;
      });

      return event.target.value;
    });
  };
  // END handlers for complex fields

  // BEGIN submit handler for all preferences
  const submitPrefs = () => {
    let error = false;
    // Start by validating custom thresholds and periods

    if (Number.isNaN(parseFloat(lamenessThreshold)) || parseFloat(lamenessThreshold) < 0) {
      setIsThresholdError(true);
      error = true;
    }

    if (
      isCustomObservationPeriod &&
      (Number.isNaN(parseInt(customObservationPeriod, 10)) ||
        parseInt(customObservationPeriod, 10) !== parseFloat(customObservationPeriod) ||
        parseInt(customObservationPeriod, 10) < 1)
    ) {
      setIsPeriodError(true);
      error = true;
    }

    if (error) {
      return;
    }

    // Then save to the redux store
    dispatch(storeSnoozeDuration(isCustomObservationPeriod ? parseInt(customObservationPeriod, 10) : 0));
    dispatch(storeLameThreshold(parseFloat(lamenessThreshold)));

    const selectedFarmObject = availableFarms.find((availableFarm) => {
      return availableFarm.displayName === selectedFarm;
    });

    if (selectedFarmObject) {
      dispatch(setFarmIdentifier(selectedFarmObject.farmIdentifier));
      dispatch(setDisplayFarm(selectedFarmObject.displayName));
      dispatch(setLegacyS3Dir(selectedFarmObject.legacyS3Dir));
      dispatch(setFarmId(selectedFarmObject.farmId));
      dispatch(setRefreshScheduled(true));

      localStorage.setItem('current-farm', JSON.stringify(selectedFarmObject));
    }

    // Then dispatch required values to Cognito (because who needs a single source of truth)
    Auth.updateUserAttributes(currentUser, {
      'custom:lameThreshold': lamenessThreshold,
      'custom:snoozeTime': isCustomObservationPeriod ? customObservationPeriod : '0',
    });

    setIsSaved(true);
  };
  // END submit handler for all preferences

  return (
    <Stack
      sx={{
        height: '100%',
        padding: 2,
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 2,
        justifyContent: 'space-around',
      }}
    >
      <TitleStack
        sx={{
          minWidth: '300px',
          maxWidth: '600px',
          height: 'max-content',
          minHeight: 'auto',
        }}
      >
        <Stack sx={{ width: '100%', alignItems: 'center', padding: 3, gap: 3 }}>
          <FormControl fullWidth>
            <FormLabel>User Email Address</FormLabel>
            <TextField id="email" defaultValue={email} InputProps={{ readOnly: true }} />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel id="farm-label">Farm</FormLabel>

            <Select
              id="farm-select"
              defaultValue={selectedFarm}
              value={selectedFarm}
              onChange={(event: SelectChangeEvent) => {
                setIsSaved(false);
                setSelectedFarm(event.target.value as string);
              }}
            >
              {availableFarms.map((availableFarm: Farm) => (
                <MenuItem key={`selectfarm-${availableFarm.farmId}`} value={availableFarm.displayName}>
                  {availableFarm.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <FormLabel id="lameness-label">Default Lameness Threshold</FormLabel>

            <FormGroup>
              <Select id="lameness-select" value={selectedLameness} onChange={changeLameThreshold}>
                {lameThresholdOptions.map((opt, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={`option-${opt}-${i}`} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>

              {isCustomLameness && (
                <TextField
                  sx={{ marginTop: 2 }}
                  InputProps={{ inputProps: { min: 0.0, max: 4.0, step: 0.1 } }}
                  required
                  id="outlined-number"
                  label="Custom Threshold"
                  type="number"
                  error={isThresholdError}
                  value={lamenessThreshold}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    setIsSaved(false);
                    setIsThresholdError(false);
                    setLamenessThreshold(event.target.value as string);
                  }}
                />
              )}
            </FormGroup>
          </FormControl>

          <DefaultOrNumericRadioGroup
            groupLabel="Default Observation Period"
            customLabel="Set duration"
            value={customObservationPeriod}
            setValue={setCustomObservationPeriod}
            isCustom={isCustomObservationPeriod}
            setIsCustom={setIsCustomObservationPeriod}
            isError={isPeriodError}
            onChangeRadio={() => {
              setIsSaved(false);
            }}
            setIsError={setIsPeriodError}
            groupName="observation-period"
            defaultLabel="Until manually removed"
            customInputProps={{
              inputProps: { min: 1.0, max: 28.0, step: 1 },
              endAdornment: <InputAdornment position="end">days</InputAdornment>,
            }}
          />

          <Button fullWidth variant="contained" disabled={isThresholdError || isPeriodError} onClick={submitPrefs}>
            Save
          </Button>
        </Stack>
      </TitleStack>

      <Snackbar
        open={isSaved}
        autoHideDuration={3000}
        onClose={() => setIsSaved(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setIsSaved(false)} severity="success">
          Preferences Saved
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default SettingsLayout;
