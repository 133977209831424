import React from 'react';

export const coloursRange = [
  {
    rangeMin: 1000,
    rangeMax: -2,
    colorName: 'grey',
    label: 'Unscorable',
    hex: '#777777',
  },
  {
    rangeMin: 0.0,
    rangeMax: 0.5,
    colorName: 'green',
    label: 'Healthy',
    hex: '#63B179',
  },
  {
    rangeMin: 0.5,
    rangeMax: 1.0,
    colorName: 'lime',
    label: 'Healthy',
    hex: '#AED987',
  },
  {
    rangeMin: 1.0,
    rangeMax: 1.5,
    colorName: 'yellow',
    label: 'Uneven',
    hex: '#FEE17E',
  },
  {
    rangeMin: 1.5,
    rangeMax: 2.0,
    colorName: 'amber',
    label: 'Mildly lame',
    hex: '#F9B25F',
  },
  {
    rangeMin: 2.0,
    rangeMax: 2.5,
    colorName: 'orange',
    label: 'Lame',
    hex: '#E9714F',
  },
  {
    rangeMin: 2.5,
    rangeMax: 999.0,
    colorName: 'red',
    label: 'Very lame',
    hex: '#D43D51',
  },
  {
    rangeMin: -2,
    rangeMax: -1,
    colorName: 'purple',
    label: 'Unavailable',
    hex: '#330066',
  },
];

const unknownColour = coloursRange[0];

export function fixScore(score: number | undefined, precision = 2) {
  if (score === undefined) return undefined;

  const scaleFactor = 10 ** precision;
  const roundedScore = Math.round(score * scaleFactor) / scaleFactor;

  return roundedScore;
}

export function getHexByScore(score: number | undefined) {
  if (score === undefined) return unknownColour.hex;

  const col = coloursRange.find((c) => c.rangeMin <= score && c.rangeMax > score) ?? unknownColour;

  return col.hex;
}

export function getLabelByScore(score: number | undefined) {
  if (score === undefined) return unknownColour.label;

  const col = coloursRange.find((c) => c.rangeMin <= score && c.rangeMax > score) ?? unknownColour;

  return col.label;
}

export function getHexByName(name: string) {
  const col = coloursRange.find((c) => c.colorName === name) ?? unknownColour;

  return col.hex;
}

export const gradientSVG = (minScore: number, maxScore: number) => {
  const min = minScore / 3;
  const max = maxScore / 3;
  const range = max === min ? 1 : max - min;
  const scale = 100 / range;
  const upper = 100 + min * scale;
  const lower = 0 - (1 - max) * scale;

  return (
    <defs>
      <linearGradient id="gradient" x1="0" y1={`${lower}%`} x2="0" y2={`${upper}%`}>
        <stop offset="8.33%" stopColor={getHexByName('red')} />
        <stop offset="25%" stopColor={getHexByName('orange')} />
        <stop offset="41.66%" stopColor={getHexByName('amber')} />
        <stop offset="58.33%" stopColor={getHexByName('yellow')} />
        <stop offset="75%" stopColor={getHexByName('lime')} />
        <stop offset="91.66%" stopColor={getHexByName('green')} />
      </linearGradient>
    </defs>
  );
};
