/* eslint-disable @typescript-eslint/naming-convention */

/* UI data structures */
import { Snooze } from '../../api/cow-snooze';

/* missing some scattered across the code base */
export interface histogramEntry {
  name: string;
  quantity: number;
  binMedian: number;
}
export interface TableEntryT {
  name: string;
  value: string | number;
}
export interface statistics {
  videos: number;
  cows: number;
  lame_cows: number;
  average_score: number;
  prevalence: number;
  severe_prevalence: number;
  eid_accuracy: number;
}

export interface cowState {
  id: number; // unique identifier
  eid: string; // guaranteed
  tag?: number;
  birthID?: string;
  breed?: string;
  dob?: string;
  // score_min?: number; // best
  // score_max?: number; // worst
  lastScoredIndex?: number;
  last_scored: number; // oldest
  last_score?: number; // recent
  collection_ids: number[]; // memoized
  snoozed?: Snooze;
  unconfirmed_collections?: number;
  totalCollections: number;
}

export interface collectionState {
  id: number; // unique identifier
  uuid: string; // database identifier
  score: number | undefined;
  date: number;
  cowId?: number;
  tag?: number;
  eid?: string;
  gateTime?: number;
  confirmedIdentity?: APIConfirmedIdentity;
}

export interface dayState {
  id?: number;
  date: number;
  eid_count: number;
  track_count: number;
  score_count: number;
  score_mean: number;
  score_bins: number[];
  milkings: milkingState[];
}
export interface milkingState {
  start: string;
  end: string;
  eid_count: number;
  track_count: number;
  score_count: number; // memoized
  score_mean: number;
  score_bins: number[];
  collection_ids: number[]; // memoized
}

export interface urlState {
  collection_id: number;
  url: string;
}

export interface APIConfirmedIdentity {
  uuid: string;
  eid?: string | undefined;
  wrong_eid: string[];
}

export interface graphState {
  date: number;
  value: number;
}

export interface eidAccuracyState {
  date: number;
  videos: number;
  eids: number;
  correct: number;
  wrong: number;
}

/* UI mode */
export enum SnoozeVisiblity {
  only_snoozed, // not yet fetched. default.
  only_unsnoozed, // attempted to fetch, but does does not yet exist
  all, // exists and fetched
  unseen,
}
