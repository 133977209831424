import { APIService } from './data';
import { FarmAPIResponseData, FarmsAPIResponseData } from './types';

async function getFarmDetails(farmId: number, headers: Record<string, string> = {}): Promise<FarmAPIResponseData> {
  const url = `${process.env.REACT_APP_FARM_API_URL}/v1/farms/${farmId}`;

  return APIService.get(url, headers);
}

async function getFarms(headers: Record<string, string> = {}): Promise<FarmsAPIResponseData> {
  const url = `${process.env.REACT_APP_FARM_API_URL}/v1/farms`;

  return APIService.get(url, {
    ...headers,
    mode: 'cors',
  });
}

// eslint-disable-next-line import/prefer-default-export
export const farmData = {
  getFarmDetails,
  getFarms,
};
