import React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';

import CardCowVideo from '../Cards/CardCowVideo';
import CardVideoGrid from '../Cards/CardVideoGrid';
import { getAllCollections } from '../Data/farmDataReduxSlice';
// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../Data/pageReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import ModalWindow from '../common/ModalWindow';

const fullScreenStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  fullWidth: {
    flexBasis: '100%',
  },
  popUp: {},
  containerPadding: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    height: '100%',
  },
  cardsWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    flexBasis: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cardSingle: {
    flexBasis: 'calc(100% - 48px)',
  },
  grow: {
    flexGrow: 1,
  },
}));

const mobileScreenStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  fullWidth: {},
  popUp: {},
  containerPadding: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cardsWrapper: {},
  cardTitle: {
    flexBasis: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cardSingle: {},
  grow: {
    flexGrow: 1,
  },
}));

function VideoLibraryLayout(props: any) {
  const theme = useTheme();
  const { pageIndex } = props;
  const classes = useMediaQuery(theme.breakpoints.up('md')) ? fullScreenStyles() : mobileScreenStyles();
  const [collectionId, selectCollection] = React.useState<number | undefined>(undefined);
  const collectionData = useAppSelector(getAllCollections);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);

  const videoPopup = (
    <ModalWindow
      open={collectionId !== undefined}
      toggle={() => {
        selectCollection(undefined);
      }}
      title="Selected Video"
      fullHeight
    >
      <CardCowVideo collection_id={collectionId} showProfile showHistory changeCollection={selectCollection} />
    </ModalWindow>
  );

  return (
    <div className={classes.root}>
      <div className={classes.fullWidth}>
        <div className={classes.containerPadding}>
          <div className={classes.cardsWrapper}>
            <Typography className={classes.cardTitle} component="h2" variant="h5">
              All Videos
            </Typography>

            <Paper className={classes.cardSingle}>
              <CardVideoGrid
                collections={collectionData}
                collection_id={collectionId}
                changeCollection={selectCollection}
              />
            </Paper>
          </div>
        </div>
      </div>

      {videoPopup}
    </div>
  );
}

export default VideoLibraryLayout;
