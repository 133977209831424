/* eslint-disable @typescript-eslint/no-use-before-define */

/* eslint-disable no-param-reassign */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { userReports } from '../../api/user-reports';
import { UserReportT } from '../../api/user-reports/types';
import type { AppDispatch, RootState } from './store';

export enum ReportStatus {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
}

interface UserReportStore {
  allReports: Record<string, UserReportT>;
  status: keyof typeof ReportStatus;
  timer: ReturnType<typeof setTimeout> | undefined;
}

export const UserReportReduxSlice = createSlice({
  name: 'userReports',
  initialState: {
    allReports: {},
    status: ReportStatus.idle,
  } as UserReportStore,
  reducers: {
    addManyReports: (state, action: PayloadAction<UserReportT[]>) => {
      const reports = Object.fromEntries(action.payload.map((a: UserReportT) => [a.reportId, a]));

      state.allReports = { ...state.allReports, ...reports };
    },
    addReport: (state, action: PayloadAction<UserReportT>) => {
      state.allReports[action.payload.reportId] = action.payload;
    },
    destroyReport: (state, action: PayloadAction<UserReportT>) => {
      delete state.allReports[action.payload.reportId];
    },
    updateReport: (state, action: PayloadAction<UserReportT>) => {
      state.allReports[action.payload.reportId] = action.payload;
    },
    setReportStatus: (state, action: PayloadAction<ReportStatus>) => {
      state.status = action.payload;
    },
    setTimer: (state, action: PayloadAction<ReturnType<typeof setTimeout>>) => {
      state.timer = action.payload;
    },
    clearTimer: (state) => {
      if (state.timer !== undefined) clearTimeout(state.timer);
      state.timer = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserReports.pending, (state) => {
      state.status = 'loading';
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUserReports.fulfilled, (state, action) => {
      const reports = Object.fromEntries(action.payload.map((a: UserReportT) => [a.reportId, a]));

      state.allReports = { ...reports };
      state.status = 'success';
    });
  },
});

const reportComparator = (a: UserReportT, b: UserReportT) => {
  if (a.resolved === b.resolved) {
    return b.createdDateTime < a.createdDateTime ? 1 : -1;
  }

  // In reality this is returning +a.resolved - +b.resolved
  if (a.resolved) {
    return 1;
  }

  if (b.resolved) {
    return -1;
  }

  throw new Error('Unreachable state');
};

// Action creators are generated for each case reducer function
export const { addManyReports, addReport, destroyReport, updateReport, setReportStatus, setTimer, clearTimer } =
  UserReportReduxSlice.actions;

export const getAllUserReports = (state: RootState) =>
  Object.values(state.userReports.allReports).sort(reportComparator);

export const fetchUserReports = createAsyncThunk<UserReportT[], string, { dispatch: AppDispatch }>(
  'users/fetchUserReports',
  async (farm: string, { dispatch }): Promise<UserReportT[]> => {
    const t = setTimeout(() => {
      dispatch(setReportStatus(ReportStatus.idle));
    }, 1000 * 60 * 30);

    dispatch(setTimer(t));

    return userReports.getAll({ farmId: farm });
  }
);

export default UserReportReduxSlice.reducer;
