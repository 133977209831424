const awsConfig = {
  // AWS
  aws_project_region: process.env.REACT_APP_AWS_REGION,

  // Cognito
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_ID_POOL,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_CLIENT,

  // S3
  aws_user_files_s3_bucket: process.env.REACT_APP_AWS_S3,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_AWS_REGION,
};

export default awsConfig;
