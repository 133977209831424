import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import ChartMilkingHistogram from '../Charts/ChartMilkingHistogram';
import TableMilkingSession from '../Charts/TableMilkingSession';
import { getHistogramBuckets } from '../Data/dataFormatting';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {},
  histogram: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

function CardMilkingSession(props: any) {
  const classes = useStyles();
  const { milking } = props;

  return (
    <div className={classes.root}>
      <TableMilkingSession milking={milking} />

      <div className={classes.histogram}>
        <ChartMilkingHistogram data={getHistogramBuckets(milking)} />
      </div>
    </div>
  );
}

export default CardMilkingSession;
