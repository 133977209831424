import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';

import App from './App';
import { store } from './components/Data/store';
import './index.css';

Sentry.init({
  dsn: 'https://d8116c3b3b7891e7be07a699e52ace70@o4507014765084672.ingest.us.sentry.io/4507014887636992',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/dashboard\/.omnieye\/.co\/.nz\/api/],
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while
  //  in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling
  // sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
