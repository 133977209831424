import React from 'react';

import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Selector from '../common/Selector';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  table: {
    flexBasis: '165px',
  },
  histogram: {
    flexBasis: '100px',
    flexGrow: 1,
  },
  chartTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 10,
    },
  },
  grow: {
    flexGrow: 1,
  },
  chart: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

function CardAnalytic(props: any) {
  const classes = useStyles();
  const { options, title, displayMode } = props;
  const [display, setDisplay] = React.useState<string>('none');
  const [dataOption, setDataOption] = React.useState<string>(
    options && Object.values(options).length > 0 ? (Object.values(options)[0] as string) : 'none'
  );

  return (
    <div className={classes.root}>
      <div className={classes.chartTitle}>
        <Typography component="h3" variant="subtitle1">
          {title}
        </Typography>

        <div className={classes.grow} />
        {options && <Selector value={dataOption} options={options} onChange={setDataOption} />}

        {displayMode && (
          <Selector
            value={display}
            options={{
              Absolute: 'none',
              Proportion: 'expand',
            }}
            onChange={setDisplay}
          />
        )}
      </div>

      <div className={classes.chart}>
        <props.chart dataOption={dataOption} display={display} />
      </div>
    </div>
  );
}

export default CardAnalytic;
