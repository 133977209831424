import React from 'react';
import { useDispatch } from 'react-redux';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames/bind';

import CardAnalytic from '../Cards/CardAnalytic';
import CardSingleNumber from '../Cards/CardSingleNumber';
import ChartIdentityAccuracy from '../Charts/ChartIdentityAccuracy';
import ChartLameCount from '../Charts/ChartLameCount';
import ChartLameLine from '../Charts/ChartLameLine';
import ChartScoreCount from '../Charts/ChartScoreCount';
import { formatStatistics } from '../Data/dataFormatting';
import { getOverviewStatistics } from '../Data/farmDataReduxSlice';
// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../Data/pageReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';

const fullScreenStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  top: {
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bottom: {
    flexBasis: '80%',
    display: 'flex',
    flexDirection: 'row',
  },
  statistics: {
    flexBasis: '20%',
  },
  left: {
    flexBasis: '50%',
  },
  right: {
    flexBasis: '50%',
  },
  graphs: {
    flexBasis: '100%',
  },
  containerPadding: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cardsWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    flexBasis: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cardSingle: {
    flexBasis: 'calc(100% - 48px)',
  },
  cardRowFour: {
    flexBasis: 'calc(100% - 48px)',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
  cardRowQuarter: {
    /* 4 boxes, 3 spaces, 25% each */
    flexBasis: `calc(100%/5 - 4/5 * ${theme.spacing(2)})`,
  },
  cardColumnThree: {
    flexBasis: 'calc(100% - 48px)',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  cardColumnThird: {
    /* 3 boxes, 2 spaces */
    flexBasis: `calc(100%/2 - 2/3 * ${theme.spacing(2)})`,
  },
  grow: {
    flexGrow: 1,
  },
}));

const mobileScreenStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  left: {},
  right: {},
  top: {},
  statistics: {},
  bottom: {},
  graphs: {},
  containerPadding: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cardsWrapper: {},
  cardTitle: {
    flexBasis: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cardSingle: {},
  cardRowFour: {},
  cardRowQuarter: {
    marginTop: theme.spacing(1),
  },
  cardColumnThree: {},
  cardColumnThird: {
    marginTop: theme.spacing(1),
    // height: "30vh"
  },
  grow: {
    flexGrow: 1,
  },
}));

function StatisticsLayout(props: any) {
  const theme = useTheme();
  const classes = useMediaQuery(theme.breakpoints.up('md')) ? fullScreenStyles() : mobileScreenStyles();
  const rawStats = useAppSelector(getOverviewStatistics);
  const { pageIndex } = props;
  const [statistics, setStatistics] = React.useState<any[]>([]);
  /* update page */
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);

  /* set stat data */
  React.useEffect(() => {
    setStatistics(formatStatistics(rawStats));
  }, []);

  const statisticCards = (
    <div className={classes.cardsWrapper}>
      <Typography className={classes.cardTitle} component="h2" variant="h5">
        Herd Statistics
      </Typography>

      <div className={classes.cardRowFour}>
        {statistics.map((s, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Paper key={`stat-${i}`} className={classes.cardRowQuarter}>
            <CardSingleNumber label={s.label} value={s.value} info={s.info} subtitle={s.subtitle} />
          </Paper>
        ))}
      </div>
    </div>
  );

  const lamenessGraphs = (
    <div className={classes.cardsWrapper}>
      <div className={classes.cardTitle}>
        <Typography component="h2" variant="h5">
          Score Breakdown
        </Typography>

        <div className={classes.grow} />
      </div>

      <div className={classes.cardColumnThree}>
        <Paper className={classes.cardColumnThird}>
          <CardAnalytic
            title="Lameness Trends"
            options={{
              Average: 'average',
              Prevalence: 'prevalence',
              'Severe Prevalence': 'severe-prevalence',
              // {name: 'Silhouette', value: 'silhouette'},
            }}
            chart={ChartLameLine}
          />
        </Paper>

        <Paper className={classes.cardColumnThird}>
          <CardAnalytic title="Lame Distribution" displayMode chart={ChartLameCount} />
        </Paper>
      </div>
    </div>
  );

  const systemGraphs = (
    <div className={classes.cardsWrapper}>
      <div className={classes.cardTitle}>
        <Typography component="h2" variant="h5">
          Tracking and ID Breakdown
        </Typography>

        <div className={classes.grow} />
      </div>

      <div className={classes.cardColumnThree}>
        <Paper className={classes.cardColumnThird}>
          <CardAnalytic title="Tracking (and Scoring) Conversion" displayMode chart={ChartScoreCount} />
        </Paper>

        <Paper className={classes.cardColumnThird}>
          <CardAnalytic
            title="Automatic ID Conversion"
            options={{
              'ID Accuracy': 'accuracy',
              'ID Confirmed': 'all',
            }}
            displayMode
            chart={ChartIdentityAccuracy}
          />
        </Paper>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classNames(classes.containerPadding, classes.statistics)}>{statisticCards}</div>

        <div className={classes.bottom}>
          <div className={classNames(classes.containerPadding, classes.left)}>{lamenessGraphs}</div>
          <div className={classNames(classes.containerPadding, classes.right)}>{systemGraphs}</div>
        </div>
      </div>
    </div>
  );
}

export default StatisticsLayout;
