import React from 'react';

import type { Cow } from '../../api/farmAPI/types';
import { getCowPreviewProfileTable } from '../Data/dataFormatting';
import { TableEntryT } from '../Data/dataInterfaces';
import DetailsTable from '../DetailsTable';

// eslint-disable-next-line react/require-default-props
function TableCowDetailsPreview(props: { cow: Cow; onlyTags?: boolean | undefined }) {
  const { cow, onlyTags } = props;
  let entries: TableEntryT[] = getCowPreviewProfileTable(cow);

  if (onlyTags) {
    entries = [entries[0], entries[1]];
  }

  entries = entries.filter(({ value }) => value !== '?');

  return <DetailsTable details={entries} rowLength={2} tableName="cow details" />;
}

export default TableCowDetailsPreview;
