import React from 'react';

import UnsnoozeIcon from '@mui/icons-material/HighlightOff';
import SnoozeIcon from '@mui/icons-material/MedicalServices';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import { getCow, getSnoozeState } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import SnoozePopup from './SnoozeCard';

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover': { '& span': { textDecorationColor: 'inherit' } },
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: '5px',
  },
  buttonLabel: {
    verticalAlign: 'middle',
    color: '#1976D2',
    textDecoration: 'underline',
    fontSize: '14px',
    textDecorationColor: 'rgba(25, 118, 210, 0.4)',
  },
}));

function WatchCowButton(props: { cowId: number | undefined }) {
  const classes = useStyles();
  const [snoozeCowId, setSnoozeCowId] = React.useState<number | undefined>();
  const { cowId } = props;
  const cow = useAppSelector(getCow(cowId));
  const snooze = useAppSelector(getSnoozeState(cow?.eid));
  const snoozeTitle = snooze ? 'Stop observing cow' : 'Place cow under observation';

  return cow?.tag !== undefined ? (
    <>
      <button
        className={classes.button}
        type="button"
        onClick={() => {
          if (cowId !== undefined) {
            setSnoozeCowId(cowId);
          }
        }}
      >
        {snooze ? (
          <UnsnoozeIcon className={classes.icon} sx={{ bgcolor: 'white', borderRadius: 3, color: red[500] }} />
        ) : (
          <SnoozeIcon className={classes.icon} sx={{ bgcolor: 'white', borderRadius: 3 }} color="primary" />
        )}

        <span className={classes.buttonLabel}>{snoozeTitle}</span>
      </button>

      <SnoozePopup snoozeCowId={snoozeCowId} setSnoozeCowId={setSnoozeCowId} />
    </>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
}

export default WatchCowButton;
