import React from 'react';
import ReactPlayer from 'react-player';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Storage } from 'aws-amplify';

import { cacheImage, cacheVideo, getCollection, getS3Image, getS3Video } from '../Data/farmDataReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#222',
    width: '100%',
    paddingTop: '38.461%', // aspect ration = 700 / 1820
    position: 'relative',
  },
  videoPlayer: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
}));

// eslint-disable-next-line react/require-default-props
function VideoPlayer(props: { collectionId?: number }) {
  const { collectionId } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const thumbUrl = useAppSelector(getS3Image(collectionId as number));
  const videoUrl = useAppSelector(getS3Video(collectionId as number));
  const collection = useAppSelector(getCollection(collectionId));
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (collection !== undefined) {
      setLoading(true);

      if (videoUrl === undefined) {
        const videoKey = `video/${collection.uuid}.mp4`;

        Storage.get(videoKey).then((url) => {
          dispatch(cacheVideo({ collection_id: collectionId, url }));
          setLoading(false);
        });
      } else {
        setLoading(false);
      }

      if (thumbUrl === undefined) {
        const imgKey = `thumb/${collection.uuid}.jpg`;

        Storage.get(imgKey).then((url) => {
          dispatch(cacheImage({ collection_id: collectionId, url }));
        });
      }
    }
  }, [collectionId]);

  return (
    <div className={classes.root}>
      {loading ? (
        <Box sx={{ width: '100%', height: '100%' }} />
      ) : (
        <ReactPlayer
          className={classes.videoPlayer}
          url={videoUrl as string}
          light={thumbUrl as string}
          width="100%"
          height="100%"
          controls
          playing
          loop
          progressInterval={1}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          onContextMenu={(e: any) => e.preventDefault()}
        />
      )}
    </div>
  );
}

export default VideoPlayer;
