import React from 'react';

import { Paper, Stack, Typography } from '@mui/material';
import { GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';

import { getLamenessThresholds, isPresetThreshold } from '../Data/dataFormatting';
import { SnoozeVisiblity } from '../Data/dataInterfaces';
import { getAllCows, getLameCows } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import { getLameThreshold, isScoreVisible } from '../Data/userReduxSlice';
import Selector from '../common/Selector';
import CardDataTable from './CardDataTable';
import { cowActions, cowColumns } from './DataTableProps';
import SnoozePopup from './SnoozeCard';

function DraftingCard({
  setCollectionId,
}: {
  setCollectionId: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  const [snoozeCowId, setSnoozeCowId] = React.useState<number | undefined>();
  const cows = useAppSelector(getAllCows);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'last_score', sort: 'desc' }]);
  const threshold = useAppSelector(getLameThreshold);
  const lameThresholdOptions: any = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const option of getLamenessThresholds()) {
    lameThresholdOptions[`${option.name}${option.value > 0.0 ? ` (> ${option.value})` : ''}`] = option.value;
  }

  if (!isPresetThreshold(threshold)) {
    lameThresholdOptions[`Custom${threshold > 0.0 ? ` (> ${threshold})` : ''}`] = threshold;
  }

  const [lameThreshold, setLameThreshold] = React.useState<number>(threshold);
  const scoreVisibility = useAppSelector(isScoreVisible);
  const snoozeVisibleOptions = {
    Recent: SnoozeVisiblity.unseen,
    All: SnoozeVisiblity.only_unsnoozed,
  };

  const [showSnoozed, setShowSnoozed] = React.useState<SnoozeVisiblity>(Object.values(snoozeVisibleOptions)[0]);
  const lameWatchList = useAppSelector(getLameCows(lameThreshold, showSnoozed, 3));
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const [page, setPage] = React.useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_pageSize, setPageSize] = React.useState<number>(0);

  return (
    <Stack
      direction="column"
      sx={{
        flexGrow: 'true',
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%',
        minHeight: '600px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography component="h2" variant="h5" sx={{ minWidth: 'max-content' }}>
          Needs Attention
        </Typography>

        <Stack direction="row">
          <Selector value={showSnoozed} options={snoozeVisibleOptions} onChange={setShowSnoozed} />
          <Selector value={lameThreshold} options={lameThresholdOptions} onChange={setLameThreshold} />
        </Stack>
      </Stack>

      <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <CardDataTable
          data={lameWatchList}
          columns={cowColumns(
            ['current_status', 'tag', 'last_score', 'last_scored'],
            scoreVisibility ? [] : ['last_score', 'last_scored']
          ).concat([cowActions(setSnoozeCowId)])}
          page={page}
          onPageChange={setPage}
          setPageSize={setPageSize}
          sortModel={sortModel}
          setSortModel={setSortModel}
          header
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          emptyMessage="No Cow Data"
          selectRowCallback={(rowId: GridRowId | undefined) => {
            if (rowId === undefined) setCollectionId(undefined);
            else {
              setCollectionId(cows[rowId as number].collection_ids[0]);
            }
          }}
        />
      </Paper>

      <SnoozePopup snoozeCowId={snoozeCowId} setSnoozeCowId={setSnoozeCowId} />
    </Stack>
  );
}

export default DraftingCard;
