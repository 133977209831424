import { Auth } from 'aws-amplify';

async function get(url: string, headers: Record<string, string> = {}) {
  const response = await fetch(url, {
    headers: {
      ...headers,
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  });

  const data = await response.json();
  const headersObj: Record<string, string> = Object.fromEntries(response.headers);

  return {
    data,
    headers: headersObj,
    status: response.status,
  };
}

// eslint-disable-next-line import/prefer-default-export
export const APIService = {
  get,
};
