/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { GridFilterModel, GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';

import { cowData } from '../../api/farmAPI/cowData';
import { observationData } from '../../api/farmAPI/observationData';
import { buildQueryParameters } from '../../api/farmAPI/utils';
import TableVideoDetails from '../Charts/TableVideoDetails';
import VideoPlayerPreview from '../Charts/VideoPlayerPreview';
import {
  getCurrentObservation,
  getObservations,
  setCurrentObservation,
  setObservations,
} from '../Data/CowDataReduxSlice';
import { getFarmId } from '../Data/farmDataReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import { isScoreVisible } from '../Data/userReduxSlice';
import CardDataTablePreview from './CardDataTablePreview';
import { observationActionReportIssue, observationColumns } from './DataTablePropsPreview';
import FormReportCollectionPreview from './FormReportCollectionPreview';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyTable: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {},
  videoHistory: {
    height: '100%',
  },
  videoPlayer: {},
  details: {},
}));

function VideoDetails(props: { observation: any }) {
  const classes = useStyles();
  const { observation } = props;

  return (
    <div className={classes.details}>
      <TableVideoDetails observation={observation} />
    </div>
  );
}

function HistoryTable(props: {
  // eslint-disable-next-line react/no-unused-prop-types
  currentObservation: any;
  cowId: number;
  farmId: number | null;
  setObservationId: any;
  observationList: any;
}) {
  const { cowId, farmId, observationList, setObservationId } = props;
  const classes = useStyles();
  const scoresVisible = useAppSelector(isScoreVisible);
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRows, setTotalRows] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchObservationsData = (farmId: number | null, cowId: number, queryParams: Record<string, string>) => {
    if (farmId) {
      setIsLoading(true);

      cowData.getObservationsForCow(farmId, cowId, {}, queryParams).then((result) => {
        const { headers } = result;
        const totalRowCount = parseInt(headers['total-count'], 10);
        const { data } = result;

        setIsLoading(false);
        setTotalRows(totalRowCount);
        setPageSize(pageSize);

        dispatch(setObservations(data));
      });
    }
  };

  const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'trackDate', sort: 'desc' }]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });

  const handleSortModelChange = (newModel: any) => {
    setSortModel(newModel);
  };

  const handleFilterModelChange = (newModel: any) => {
    setFilterModel(newModel);
  };

  useEffect(() => {
    let orderBy = sortModel[0]?.field ?? 'observationId';

    if (orderBy === 'status') {
      orderBy = 'score';
    }

    const queryParameters = buildQueryParameters(
      sortModel,
      filterModel,
      orderBy,
      ['latestObservationDate'],
      page + 1,
      pageSize
    );

    fetchObservationsData(farmId, cowId, queryParameters);
  }, [sortModel, filterModel, page, pageSize, cowId]);

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

  useEffect(() => {
    if (observationList.length > 0) {
      setObservationId(observationList[0].observationId);
      setSelectionModel([observationList[0].observationId]);
    }
  }, [observationList]);

  useEffect(() => {
    setPage(0);
  }, [cowId]);

  const [reportObservationId, setReportObservationId] = React.useState<number | undefined>(undefined);

  return (
    <div className={classes.videoHistory}>
      <CardDataTablePreview
        compactMode
        loading={isLoading}
        data={observationList}
        columns={observationColumns(['status', 'score', 'trackDate'], scoresVisible ? [] : ['score']).concat([
          observationActionReportIssue((observationId: number) => setReportObservationId(observationId)),
        ])}
        page={page}
        rowId="observationId"
        rowCount={totalRows}
        autoPageSize={false}
        pageSize={pageSize}
        sortModel={sortModel}
        filterModel={filterModel}
        isServerPaginated
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onPageChange={(page) => {
          setPage(page);
        }}
        setPageSize={setPageSize}
        setSortModel={setSortModel}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
        header={false}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        emptyMessage="No Videos"
        selectRowCallback={(rowId: GridRowId | undefined) => {
          if (rowId === undefined) return;
          setObservationId(rowId as number);
        }}
      />

      <FormReportCollectionPreview
        observationId={reportObservationId}
        visible={reportObservationId !== undefined}
        close={() => {
          setReportObservationId(undefined);
        }}
      />
    </div>
  );
}

function CardCowVideoPreview(props: any) {
  const { showDetails, showHistory, cowId } = props;
  const classes = useStyles();
  const [observationId, setObservationId] = React.useState<number | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { currentObservation } = useAppSelector(getCurrentObservation);
  const observationList = useAppSelector(getObservations).observations;
  const farmId = useAppSelector(getFarmId);

  useEffect(() => {
    const fetchData = () => {
      if (farmId && observationId) {
        setIsLoading(true);

        observationData.getObservationDetails(farmId, observationId).then((result) => {
          const { data } = result;

          dispatch(setCurrentObservation(data));
          setIsLoading(false);
        });
      }
    };

    fetchData();
  }, [observationId]);

  const novideo = (
    <div className={classes.emptyTable}>
      <Typography color="primary" align="right" component="h4" variant="h6">
        No Video Selected
      </Typography>
    </div>
  );

  return cowId ? (
    <div className={classes.root}>
      {currentObservation && (
        <div className={classes.videoPlayer}>
          <VideoPlayerPreview observation={currentObservation} isLoading={isLoading} />
        </div>
      )}

      {showDetails && <VideoDetails observation={currentObservation} />}

      {showHistory && cowId && (
        <HistoryTable
          currentObservation={currentObservation}
          observationList={observationList}
          setObservationId={setObservationId}
          farmId={farmId}
          cowId={cowId}
        />
      )}
    </div>
  ) : (
    novideo
  );
}

export default CardCowVideoPreview;
