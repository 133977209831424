/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import { SxProps, Theme } from '@mui/material';

function Toggle({
  item,
  inactiveItem,
  activeItem,
  onActivate,
  onDeactivate,
  initialActive,
  sx,
}: {
  item?: React.ReactElement;
  inactiveItem?: React.ReactElement;
  activeItem?: React.ReactElement;
  onActivate: () => void;
  onDeactivate: () => void;
  initialActive: boolean;
  sx?: SxProps<Theme>;
}) {
  const [isActive, setActive] = useState<boolean>(initialActive);

  if (activeItem !== undefined && inactiveItem !== undefined) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {isActive
          ? React.cloneElement(activeItem, {
              onClick: () => {
                setActive(() => false);
                onDeactivate();
              },
              sx: { ...activeItem.props.sx, ...sx },
            })
          : React.cloneElement(inactiveItem, {
              onClick: () => {
                setActive(() => true);
                onActivate();
              },
              sx: { ...inactiveItem.props.sx, ...sx },
            })}
      </>
    );
  }

  if (item !== undefined) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {isActive
          ? React.cloneElement(item, {
              onClick: () => {
                setActive(() => false);
                onDeactivate();
              },
              sx: { ...item.props.sx, ...sx },
            })
          : React.cloneElement(item, {
              onClick: () => {
                setActive(() => true);
                onActivate();
              },
              sx: { ...item.props.sx, ...sx },
            })}
      </>
    );
  }

  throw new Error('Must specify item, or active and inactive item');
}

export default Toggle;
