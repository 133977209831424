import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import VideoIcon from '@mui/icons-material/Assignment';
import BeenHere from '@mui/icons-material/Beenhere';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Link, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';

import { userReports } from '../../api/user-reports';
import { UserReportT } from '../../api/user-reports/types';
import { destroyReport, fetchUserReports, getAllUserReports, updateReport } from '../Data/UserReportReduxSlice';
import { getFarmIdentifier } from '../Data/farmDataReduxSlice';
// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../Data/pageReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import LoadingCircle from '../common/LoadingCircle';

const fullScreenStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  fullWidth: {
    flexBasis: '100%',
  },
  popUp: {},
  containerPadding: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    height: '100%',
  },
  cardsWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    flexBasis: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cardReport: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  left: {
    flexBasis: '50%',
    maxWidth: 'auto',
  },
  right: {
    flexBasis: '50%',
    maxWidth: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
  rowValue: {
    overflowWrap: 'anywhere',
  },
}));

const mobileScreenStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  fullWidth: {},
  popUp: {},
  containerPadding: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cardsWrapper: {},
  cardTitle: {
    flexBasis: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cardReport: {
    marginBottom: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  left: {},
  right: {},
  rowValue: {},
}));

function UserReportsLayout(props: any) {
  const { pageIndex } = props;
  const theme = useTheme();
  const classes = useMediaQuery(theme.breakpoints.up('md')) ? fullScreenStyles() : mobileScreenStyles();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);

  const reportData = useAppSelector(getAllUserReports);
  const reportStatus = useAppSelector((state) => state.userReports.status);
  const farmId = useAppSelector(getFarmIdentifier);

  React.useEffect(() => {
    if (reportStatus === 'idle') {
      dispatch(fetchUserReports(farmId));
    }
  }, [reportStatus, dispatch]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function Options({ userReport }: { userReport: UserReportT }) {
    const { tag } = userReport.collectionData.cowIdentity;

    return (
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{ paddingTop: '.5rem', paddingBottom: '.5rem' }}
      >
        {tag !== undefined ? (
          <Link
            component={RouterLink}
            to={{
              pathname: '/cows',
              search: `?cow=${tag}`,
            }}
          >
            <Button variant="contained">
              <Typography>View Cow</Typography>
              <VideoIcon sx={{ marginLeft: '0.5rem' }} />
            </Button>
          </Link>
        ) : (
          <Button
            variant="contained"
            sx={{
              filter: 'brightness(200%) grayscale(100%) contrast(20%)',
              cursor: 'not-allowed',
            }}
          >
            <Typography>View Cow</Typography>
            <VideoIcon sx={{ marginLeft: '0.5rem' }} />
          </Button>
        )}

        <Button
          variant={userReport.resolved ? 'contained' : 'outlined'}
          onClick={async () => {
            const report = await userReports.resolve(userReport);

            dispatch(updateReport(report));
          }}
          sx={{ width: '12rem' }}
        >
          <Typography>{userReport.resolved ? 'Done' : 'Resolve Report'}</Typography>
          <BeenHere sx={{ marginLeft: '0.5rem' }} />
        </Button>

        <Button
          variant="contained"
          onClick={async () => {
            userReports.destroy(userReport);
            dispatch(destroyReport(userReport));
          }}
        >
          <Typography>{userReport.resolved ? 'Hide' : 'Resolve and Hide'}</Typography>
          <DeleteIcon sx={{ marginLeft: '0.5rem' }} />
        </Button>
      </Stack>
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface tableRow {
    name: string;
    value: string;
  }

  const generateTable = (rows: tableRow[], tableName: string) => (
    <TableContainer>
      <Table aria-label="report-table" size="small">
        <TableBody>
          {rows.map((row: tableRow, j: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`report-table-${tableName}-${j}`}>
              <TableCell component="th">
                <Typography variant="subtitle2" noWrap>
                  {row.name}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.rowValue}>{row.value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const generateReportCard = (u: UserReportT) => (
    <Paper
      key={`report-card-${u.reportId}`}
      className={classes.cardReport}
      sx={{
        filter: u.resolved ? 'grayscale(50%) brightness(75%)' : '',
      }}
    >
      <Stack className={classes.fullWidth}>
        <Stack direction="row">
          <Box className={classes.left}>
            {generateTable(
              [
                {
                  name: 'Report Date',
                  value: u.createdDateTime.toLocaleString(),
                },
                { name: 'User', value: u.userEmail },
                {
                  name: 'Problem',
                  value: Object.entries({
                    Identity: u.reportData.cowIdentity,
                    Score: u.reportData.score,
                    Other: u.reportData.other,
                  })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([_a, b]) => b)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .map(([a, _b]) => a)
                    .join('/'),
                },
                {
                  name: 'Message',
                  value: u.reportData.message,
                },
              ],
              `report-${u.reportId}`
            )}
          </Box>

          <Box className={classes.right}>
            {generateTable(
              [
                {
                  name: 'Collection Id',
                  value: u.collectionData.collectionId,
                },
                {
                  name: 'Score',
                  value: u.collectionData.score?.toFixed(2) ?? '',
                },
                {
                  name: 'Date',
                  value: u.collectionData.date.toLocaleString(),
                },
                {
                  name: 'Cow tag',
                  value: u.collectionData.cowIdentity.tag,
                },
                {
                  name: 'Cow eID',
                  value: u.collectionData.cowIdentity.eid,
                },
              ],
              `collection-${u.collectionId}`
            )}
          </Box>
        </Stack>

        <Box className={classes.fullWidth}>
          <Options userReport={u} />
        </Box>
      </Stack>
    </Paper>
  );

  return (
    <div className={classes.root}>
      <div className={classes.fullWidth}>
        <div className={classes.containerPadding}>
          <div className={classes.cardsWrapper}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {reportStatus === 'success' ? (
              reportData.length > 0 ? (
                <>{reportData.map((u: UserReportT) => generateReportCard(u))}</>
              ) : (
                <Stack direction="row" justifyContent="space-around" sx={{ marginY: 'auto' }}>
                  <Typography variant="h2" sx={{ color: '#777' }}>
                    No Reports Found
                  </Typography>
                </Stack>
              )
            ) : (
              <LoadingCircle />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserReportsLayout;
