/* eslint-disable no-param-reassign */

/* eslint-disable import/no-cycle */
import React from 'react';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import CowBrowserLayout from '../Layouts/CowBrowserLayout';
import CowBrowserPreviewLayout from '../Layouts/CowBrowserPreviewLayout';
import IdentityCorrectionLayout from '../Layouts/IdentityCorrectionLayout';
import MilkingDiaryLayout from '../Layouts/MilkingDiaryLayout';
import OverviewLayout from '../Layouts/OverviewLayout';
import SettingsLayout from '../Layouts/SettingsLayout';
import StatisticsLayout from '../Layouts/StatisticsLayout';
import UserReportsLayout from '../Layouts/UserReportsLayout';
import VideoLibraryLayout from '../Layouts/VideoLibraryLayout';
import type { RootState } from './store';

interface PageState {
  activePageIndex: number;
  title: string;
  pageNotifications: PageNotifications[];
}

export interface PageNotifications {
  url: string;
  count: number;
}

// TODO move the icons to here
export interface Page {
  url: string;
  navTitle: string;
  pageTitle: string;
  developer: boolean;
  content: (props: any) => React.ReactElement;
}
export const dashboardPages: Page[] = [
  {
    url: '/',
    navTitle: 'Farm Overview',
    pageTitle: 'Farm Overview',
    developer: false,
    content: OverviewLayout,
  },
  {
    url: '/milking',
    navTitle: 'Milking Diary',
    pageTitle: 'Milking Diary',
    developer: false,
    content: MilkingDiaryLayout,
  },
  {
    url: '/cows',
    navTitle: 'Cow Browser',
    pageTitle: 'Cow Browser',
    developer: false,
    content: CowBrowserLayout,
  },
  {
    url: '/videos',
    navTitle: 'Video Library',
    pageTitle: 'Video Library',
    developer: false,
    content: VideoLibraryLayout,
  },
  {
    url: '/settings',
    navTitle: 'Settings',
    pageTitle: 'Settings',
    developer: false,
    content: SettingsLayout,
  },
  {
    url: '/statistics',
    navTitle: 'Statistics',
    pageTitle: 'Overview Statistics',
    developer: true,
    content: StatisticsLayout,
  },
  {
    url: '/cowidentity',
    navTitle: 'ID Correction',
    pageTitle: 'ID Correction',
    developer: true,
    content: IdentityCorrectionLayout,
  },
  {
    url: '/userreports',
    navTitle: 'User Reports',
    pageTitle: 'User Reports',
    developer: true,
    content: UserReportsLayout,
  },
  {
    url: '/cows_preview',
    navTitle: 'Cow Browser (2)',
    pageTitle: 'Cow Browser (2)',
    developer: true,
    content: CowBrowserPreviewLayout,
  },
];

export const pageReduxSlice = createSlice({
  name: 'page',
  initialState: {
    activePageIndex: 0,
    title: 'Loading...',
    pageNotifications: dashboardPages.map((p: Page) => ({ url: p.url, count: 0 } as PageNotifications)),
  } as PageState,
  reducers: {
    setPageIndex: (state, action: PayloadAction<number>) => {
      state.activePageIndex = action.payload;
      state.title = dashboardPages[action.payload].pageTitle;
    },
    setPageNotifications: (state, action: PayloadAction<any>) => {
      const { url } = action.payload;
      const { count } = action.payload;
      const p = state.pageNotifications.find(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (p: PageNotifications) => p.url === url
      );

      if (p !== undefined) {
        p.count = count;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPageIndex, setPageNotifications } = pageReduxSlice.actions;

export const getPageIndex = (state: RootState) => state.page.activePageIndex;
export const getPageTitle = (state: RootState) => state.page.title;
export const getPageNotifications = (state: RootState) => state.page.pageNotifications;

export default pageReduxSlice.reducer;
