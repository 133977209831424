import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { getCowLamenessTable } from '../Data/dataFormatting';
import { getCollectionsFromIds, getCowCollectionsIds } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import { isScoreVisible } from '../Data/userReduxSlice';
import LameStatus from '../common/LameStatus';

const useStyles = makeStyles((theme) => ({
  table: {},
  StatusScore: {
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
}));

function TableCowLameStats(props: any) {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { cow_id } = props;
  const collectionIds = useAppSelector(getCowCollectionsIds(cow_id));
  const collections = useAppSelector(getCollectionsFromIds(collectionIds));
  const tableRows = getCowLamenessTable(collections);
  const scoresVisible = useAppSelector(isScoreVisible);

  function calculateScores(row: any) {
    if (scoresVisible) {
      if (scoresVisible && typeof row.value === 'number') {
        return row.value.toFixed(2);
      }

      return row.value;
    }

    return '';
  }

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableBody>
          {tableRows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th">
                <Typography variant="subtitle2" noWrap>
                  {row.name}
                </Typography>
              </TableCell>

              <TableCell align="right">
                {row.name === 'Current lameness' ? (
                  <div>
                    <LameStatus score={row.value} />
                    <Typography className={classes.StatusScore}>{calculateScores(row)}</Typography>
                  </div>
                ) : (
                  <Typography noWrap>{row.value}</Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableCowLameStats;
