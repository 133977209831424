import React from 'react';
import { Link } from 'react-router-dom';

import { Refresh } from '@mui/icons-material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BadgeIcon from '@mui/icons-material/Badge';
import OverviewIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import ReportIcon from '@mui/icons-material/Report';
import SettingsIcon from '@mui/icons-material/Settings';
import BrowserIcon from '@mui/icons-material/TableChart';
import MilkingIcon from '@mui/icons-material/Today';
import VideoIcon from '@mui/icons-material/VideoLibrary';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { getLoading, setRefreshScheduled } from '../Data/farmDataReduxSlice';
import { Page, PageNotifications, dashboardPages, getPageIndex, getPageNotifications } from '../Data/pageReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import { getDeveloper, getLastRefreshed, isScoreVisible } from '../Data/userReduxSlice';
import BuildIdentifier from '../common/BuildIdentifier';
import IrisLogo from '../common/SideBarIrisLogo';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('xl')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButtonContainer: {
    height: '100%',
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
    backgroundColor: theme.palette.primary.contrastText,
    // color: theme.palette.primary.main
  },
  menuButton: {
    margin: 0,
    height: '56px',
    color: theme.palette.primary.main,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.contrastText,
  },
  drawerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  navAdmin: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  navIcon: {
    color: 'inherit',
  },
  navLabel: {
    color: 'inherit',
  },
  bottom: {
    width: '100%',
    paddingTop: '1rem',
    marginTop: 'auto',
  },
  build: {
    padding: '0 0.5rem 0 0.5rem',
    textAlign: 'center',
    width: '100%',
    bottom: 25,
    color: '#999',
  },
  lastRefreshed: {
    padding: '0 0.5rem 0 0.5rem',
    textAlign: 'center',
    width: '100%',
    bottom: 10,
    color: '#999',
    display: 'block',
  },
}));

function SideBar() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const pageIndex = useAppSelector(getPageIndex);
  const developer = useAppSelector(getDeveloper);
  const lastRefreshed = useAppSelector(getLastRefreshed);
  const isFarmDataLoading = useAppSelector(getLoading);
  const pageNotifications = useAppSelector(getPageNotifications);
  const scoreVisibility = useAppSelector(isScoreVisible);
  const pageIcons = [
    <OverviewIcon key="overview-icon" />,
    <MilkingIcon key="milking-icon" />,
    <BrowserIcon key="browser-icon" />,
    <VideoIcon key="video-icon" />,
    <SettingsIcon key="setting-icon" />,
    <AnalyticsIcon key="statistics-icon" />,
    <BadgeIcon key="identity-icon" />,
    <ReportIcon key="user-report-icon" />,
    <BrowserIcon key="browser-icon" />,
  ];

  const links = (
    <List>
      {(dashboardPages as Page[])
        .filter((page: Page) => developer || !page.developer)
        .filter((p: Page) => p.navTitle !== 'Statistics' || scoreVisibility)
        .map((link: any, index: number) => (
          <ListItem
            className={link.developer ? classes.navAdmin : classes.nav}
            component={Link}
            to={link.url}
            selected={index === pageIndex}
            key={link.url}
          >
            <ListItemIcon className={classes.navIcon}>{pageIcons[index]}</ListItemIcon>
            <ListItemText className={classes.navLabel} primary={link.navTitle} />

            <Badge
              badgeContent={pageNotifications.find((p: PageNotifications) => p.url === link.url)?.count ?? 0}
              color="error"
            />
          </ListItem>
        ))}
    </List>
  );

  const drawer = (
    <div className={classes.drawerContent}>
      <IrisLogo />
      {isFarmDataLoading === false && links}

      <div className={classes.bottom}>
        {lastRefreshed && (
          <div className={classes.lastRefreshed}>
            <Button
              component="button"
              variant="outlined"
              disabled={isFarmDataLoading}
              onClick={() => {
                dispatch(setRefreshScheduled(true));
              }}
              endIcon={<Refresh />}
            >
              Last refreshed: {lastRefreshed}
            </Button>
          </div>
        )}

        <div className={classes.build}>
          <BuildIdentifier />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className={classes.menuButtonContainer}>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large"
        >
          <Badge
            badgeContent={(pageNotifications as PageNotifications[]).reduce((acc: number, n: any) => acc + n.count, 0)}
            color="error"
          >
            <MenuIcon />
          </Badge>
        </IconButton>
      </div>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden xlUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onClick={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>

        <Hidden xlDown implementation="css">
          <Drawer
            PaperProps={{ elevation: 3 }}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default SideBar;
