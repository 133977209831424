import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import { chunked } from './Data/dataFormatting';
import { TableEntryT } from './Data/dataInterfaces';

function Entry({ entry }: { entry: TableEntryT }) {
  return (
    <>
      <TableCell>
        <Typography variant="subtitle2">{`${entry.name}`}</Typography>
      </TableCell>

      <TableCell align="right">
        <Typography variant="body1">{`${entry.value}`}</Typography>
      </TableCell>
    </>
  );
}

const TableEntry = React.memo(Entry);

function DetailsTable({
  details,
  rowLength,
  tableName,
}: {
  details: TableEntryT[];
  rowLength: number;
  tableName: string;
}) {
  const rows = chunked({ arr: details, chunkSize: rowLength });

  return (
    <TableContainer sx={{ overflow: 'visible' }}>
      <Table size="small" aria-label={tableName}>
        <TableBody>
          {rows.map((row: TableEntryT[], i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`row-${i}`}>
              {row.map((entry: TableEntryT) => (
                <TableEntry entry={entry} key={`${entry.name}`} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DetailsTable;
