import React from 'react';

import { Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// eslint-disable-next-line import/no-cycle
import { usePageIndex } from '../../helpers/hooks';
import CardCowListIdentity from '../Cards/CardCowListIdentity';
import CardIdentityReassignment from '../Cards/CardIdentityReassignment';
import CardVideoListIdentity from '../Cards/CardVideoListIdentity';
import { getCollection, getConfirmedIdentityState, getCow } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';

const styles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2),
  },
  containerPadding: {
    flexGrow: 'true',
    flex: '1',
    height: '100%',
    marginRight: theme.spacing(2),
    '&:last-child': { marginRight: theme.spacing(0) },
  },
}));

const useReassignment = (collectionId: number | undefined, cowId: number | undefined): [number | undefined] => {
  const [reassignmentId, setReassignmentId] = React.useState<number | undefined>(collectionId);
  const collection = useAppSelector(getCollection(collectionId));
  const cow = useAppSelector(getCow(cowId));
  const collectionConfirmedState = useAppSelector(getConfirmedIdentityState(collection?.uuid));

  React.useEffect(() => {
    console.log(collectionConfirmedState);
    console.log(cow);

    if (collectionConfirmedState === undefined || cow === undefined) {
      setReassignmentId(undefined);
    } else if (
      (collectionConfirmedState.eid !== undefined && collectionConfirmedState.eid !== cow.eid) ||
      collectionConfirmedState.wrong_eid.includes(cow.eid)
    ) {
      setReassignmentId(collectionId);
    } else {
      setReassignmentId(undefined);
    }
  }, [collectionId, cow, collectionConfirmedState]);

  return [reassignmentId];
};

export function IdentityCorrection(props: { pageIndex: number }) {
  const classes = styles();
  const { pageIndex } = props;
  const [currentCow, setCurrentCow] = React.useState<number | undefined>();
  const [currentCollection, setCurrentCollection] = React.useState<number | undefined>();
  const [reassignmentId] = useReassignment(currentCollection, currentCow);

  usePageIndex(pageIndex);

  return (
    <Stack className={classes.root} direction="row">
      <CardCowListIdentity currentCow={currentCow} setCurrentCow={setCurrentCow} />

      {currentCow !== undefined && (
        <CardVideoListIdentity
          currentCow={currentCow}
          currentCollection={currentCollection}
          setCurrentCollection={setCurrentCollection}
        />
      )}

      {reassignmentId !== undefined && currentCow !== undefined && (
        <CardIdentityReassignment collectionId={reassignmentId} cowId={currentCow} />
      )}
    </Stack>
  );
}

export default IdentityCorrection;
