import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { AuthState, CognitoUserInterface, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  AmplifyAuthContainer,
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Amplify, Auth, Storage } from 'aws-amplify';

import './App.css';
import { cowIdentity } from './api/cow-identity';
import { cowSnooze } from './api/cow-snooze';
import { farmData } from './api/farmAPI/farmData';
import aws_exports from './awsConfig';
import { getS3file } from './components/Data/OmnieyeAWS';
import { ReportStatus, clearTimer, setReportStatus } from './components/Data/UserReportReduxSlice';
import {
  addAllData,
  getFarmIdentifier,
  getLegacyS3Dir,
  getLoading,
  getRefreshScheduled,
  setDisplayFarm,
  setFarmId,
  setFarmIdentifier,
  setLegacyS3Dir,
  setLoading,
  setRefreshScheduled,
} from './components/Data/farmDataReduxSlice';
import { Page, dashboardPages } from './components/Data/pageReduxSlice';
import { useAppDispatch, useAppSelector } from './components/Data/reduxHooks';
import {
  enableDemoMode,
  enableDeveloperOptions,
  getCurrentLoggedUser,
  getDeveloper,
  isEidVisible,
  isScoreVisible,
  setAvailableFarms,
  setCurrentLoggedUser,
  setEidInvisible,
  setEmail,
  setEmailList,
  setEmailNotification,
  setEulaConfirmed,
  setEulaConfirmedDate,
  setLameThreshold,
  setLastRefreshed,
  setPNList,
  setPNNotification,
  setScoreInvisible,
  setSnoozeDuration,
} from './components/Data/userReduxSlice';
import Dashboard from './components/Navigation/Dashboard';
import CognitoFrame from './components/common/CognitoFrame';
import LoadingCircle from './components/common/LoadingCircle';
import { dateTimeToRFC3339String } from './helpers/time';
import theme from './theme';

Amplify.configure(aws_exports);

function DashboardRouter() {
  const developer = useAppSelector(getDeveloper);
  const scoreVisibility = useAppSelector(isScoreVisible);

  return (
    <Dashboard>
      <Routes>
        {dashboardPages
          .filter((p: Page) => !p.developer || developer)
          .filter((p: Page) => p.navTitle !== 'Statistics' || scoreVisibility)
          .map((p, i) => (
            <Route
              // eslint-disable-next-line react/no-array-index-key
              key={`page-route-${i}`}
              path={p.url}
              element={<p.content pageIndex={i} />}
            />
          ))}
      </Routes>
    </Dashboard>
  );
}

function App() {
  const [cognitoUser, setCognitoUser] = React.useState<CognitoUserInterface | undefined>(undefined);
  const [authState, setAuthState] = React.useState<AuthState | undefined>(undefined);
  const dispatch = useAppDispatch();
  const eidVisibility = useAppSelector(isEidVisible);
  const scoreVisibility = useAppSelector(isScoreVisible);
  const farmIdentifier = useAppSelector(getFarmIdentifier);
  const legacyS3Dir = useAppSelector(getLegacyS3Dir);
  const currentUser = useAppSelector(getCurrentLoggedUser);
  const [previousIdentifier, setPreviousIdentifier] = React.useState<string | undefined>();
  const isFarmDataLoading = useAppSelector(getLoading);

  React.useEffect(
    () =>
      onAuthUIStateChange((nextAuthState: AuthState, authData) => {
        setAuthState(nextAuthState);
        setCognitoUser(authData as CognitoUserInterface);
      }),
    []
  );

  /* populated Context properties when user logs in */
  React.useEffect(() => {
    if (cognitoUser !== undefined) {
      dispatch(setCurrentLoggedUser(cognitoUser));

      if (Object.prototype.hasOwnProperty.call(cognitoUser, 'attributes')) {
        /* set email as user identifier */
        if (Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'email')) {
          dispatch(setEmail(cognitoUser.attributes.email));
          Sentry.setUser({ email: cognitoUser.attributes.email });
        }

        /* if developer (set developer flag) AND give access to all S3 farm directories */
        /* if not developer give access to their restricted S3 farm directories */
        if (
          Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:developer') &&
          cognitoUser.attributes['custom:developer'] === '1'
        ) {
          dispatch(enableDeveloperOptions());
        }

        /* if user has eidVisible set on cognito, then allow showing of eid's */
        if (
          Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:eidVisible') &&
          cognitoUser.attributes['custom:eidVisible'] === '0'
        ) {
          dispatch(setEidInvisible());
        }

        /* if user has demomode set on cognito, then show demo features */
        if (
          Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:demomode') &&
          cognitoUser.attributes['custom:demomode'] === '1'
        ) {
          dispatch(enableDemoMode());
        }

        /* if user has scoresVisible set on cognito, then allow showing of scores */
        if (
          Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:scoresVisible') &&
          cognitoUser.attributes['custom:scoresVisible'] === '0'
        ) {
          dispatch(setScoreInvisible());
        }

        /* if user has notifyEmail set on cognito, then notify email */
        if (
          Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:notifyEmail') &&
          cognitoUser.attributes['custom:notifyEmail'] === '1'
        ) {
          dispatch(setEmailNotification(true));
        }

        if (Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:emailList')) {
          dispatch(setEmailList(cognitoUser.attributes['custom:emailList'].split(',')));
        }

        /* if user has PUSH notify enabled set on cognito, then enable PUSH notification */
        if (
          Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:notifyPush') &&
          cognitoUser.attributes['custom:notifyPush'] === '1'
        ) {
          dispatch(setPNNotification(true));
        }

        if (Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:phoneList')) {
          dispatch(setPNList(cognitoUser.attributes['custom:phoneList'].split(',')));
        }

        if (Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:lameThreshold')) {
          dispatch(setLameThreshold(parseFloat(cognitoUser.attributes['custom:lameThreshold'] ?? 2.0)));
        }

        if (Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:snoozeTime')) {
          dispatch(setSnoozeDuration(parseInt(cognitoUser.attributes['custom:snoozeTime'] ?? 14, 10)));
        }

        if (
          Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:EULAConfirmed') &&
          cognitoUser.attributes['custom:EULAConfirmed'] === '1'
        ) {
          dispatch(setEulaConfirmed(true));
        }

        if (Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:EULAConfirmedDate')) {
          dispatch(setEulaConfirmedDate(cognitoUser.attributes['custom:EULAConfirmedDate']));
        }

        if (Object.prototype.hasOwnProperty.call(cognitoUser.attributes, 'custom:lastRefreshed')) {
          dispatch(setLastRefreshed(cognitoUser.attributes['custom:lastRefreshed']));
        }
      }
    }
  }, [cognitoUser]);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchFarms = async () => {
    setIsLoading(true);

    const result = await farmData.getFarms({});
    const { data } = result;

    setIsLoading(false);

    if (!isLoading && data.length > 0) {
      const localStoredFarm = JSON.parse(localStorage.getItem('current-farm') ?? '{}');

      if (localStoredFarm && data.some((farmObject) => farmObject.farmId === localStoredFarm.farmId)) {
        dispatch(setFarmIdentifier(localStoredFarm.farmIdentifier));
        dispatch(setDisplayFarm(localStoredFarm.displayName));
        dispatch(setLegacyS3Dir(localStoredFarm.legacyS3Dir));
        dispatch(setFarmId(localStoredFarm.farmId));
      } else {
        dispatch(setFarmIdentifier(data[0].farmIdentifier));
        dispatch(setDisplayFarm(data[0].displayName));
        dispatch(setLegacyS3Dir(data[0].legacyS3Dir));
        dispatch(setFarmId(data[0].farmId));
      }

      const sortedData = data
        // eslint-disable-next-line eqeqeq
        .filter((farm) => farm.legacyS3Dir != undefined)
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      dispatch(setAvailableFarms(sortedData));
    }
  };

  React.useEffect(() => {
    if (cognitoUser !== undefined) {
      fetchFarms();
    }
  }, [cognitoUser]);

  React.useEffect(() => {
    if (legacyS3Dir && cognitoUser) {
      Storage.configure({
        level: 'public',
        customPrefix: {
          public: `${legacyS3Dir}/`,
        },
      });

      setPreviousIdentifier(() => '');

      if (previousIdentifier !== farmIdentifier) {
        setPreviousIdentifier(farmIdentifier);
        dispatch(setRefreshScheduled(true));
      }
    }
  }, [legacyS3Dir]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const hours = new Date().getHours();
      const min = new Date().getMinutes();

      if (hours === 5 && min >= 30 && min < 45) {
        dispatch(setRefreshScheduled(true));
      }
    }, 10 * 60 * 1000); // every 10 mins..

    return () => clearInterval(interval);
  }, []);

  const refreshScheduled = useAppSelector(getRefreshScheduled);

  const magicFunction = async () => {
    dispatch(setLoading(true));

    const [
      videoRecords,
      cowData,
      idRecords,
      milkingRecords,
      graphRecords,
      snoozeRecords,
      confirmedIdentities,
      accuracyGraphRecords,
    ] = await Promise.all([
      getS3file('video-data.json'),
      getS3file('cow-data.json'),
      // eslint-disable-next-line max-len
      eidVisibility ? getS3file('id-data.json') : Promise.resolve([]), // hide this to hide id's - return promise.resolve([]) empty array
      getS3file('milking-data-2.json'),
      getS3file('prevalence-lameness.json'),
      cowSnooze.readAll({}),
      farmIdentifier !== undefined ? cowIdentity.readAll({ farmIdentifier }) : Promise.resolve([]),
      getS3file('eid-accuracy.json'),
    ]);

    dispatch(
      addAllData([
        videoRecords ?? [],
        cowData ?? [],
        milkingRecords ?? [],
        idRecords ?? [],
        graphRecords ?? [],
        snoozeRecords ?? [],
        confirmedIdentities ?? [],
        accuracyGraphRecords ?? [],
        scoreVisibility,
      ])
    );
    const newDate = new Date();
    const dateTimeString = dateTimeToRFC3339String(newDate);

    dispatch(setLastRefreshed(dateTimeString));
    dispatch(setRefreshScheduled(false));

    return dateTimeString;
  };

  React.useEffect(() => {
    dispatch(clearTimer());
    dispatch(setReportStatus(ReportStatus.idle));

    if (refreshScheduled) {
      magicFunction().then((updateTimeString) =>
        Auth.updateUserAttributes(currentUser, {
          'custom:lastRefreshed': updateTimeString,
        })
      );
    }
  }, [refreshScheduled]);

  const signin = (
    <>
      <CognitoFrame />

      <AmplifyAuthContainer>
        <AmplifyAuthenticator>
          <AmplifySignIn slot="sign-in" hideSignUp usernameAlias="email" />
          <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />
        </AmplifyAuthenticator>
      </AmplifyAuthContainer>
    </>
  );

  Sentry.init({
    dsn: 'https://d8116c3b3b7891e7be07a699e52ace70@o4507014765084672.ingest.us.sentry.io/4507014887636992',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
  });

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          {/* eslint-disable-next-line no-nested-ternary */}
          {cognitoUser === undefined || authState !== AuthState.SignedIn ? (
            signin
          ) : isFarmDataLoading === true ? (
            <LoadingCircle />
          ) : (
            <DashboardRouter />
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

export default App;
