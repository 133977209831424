/* eslint-disable consistent-return */
import { z } from 'zod';

const ReportData = z.object({
  cowIdentity: z.boolean(),
  score: z.boolean(),
  other: z.boolean(),
  message: z.string(),
});

const CollectionData = z.object({
  collectionId: z.string(),
  score: z.optional(z.number().nonnegative()),
  date: z.preprocess((arg) => {
    if (typeof arg === 'string' || arg instanceof Date || typeof arg === 'number') return new Date(arg);
  }, z.date()),
  cowIdentity: z.record(z.string()),
});

export const UserReport = z.object({
  reportId: z.string(),
  farmId: z.string(),
  collectionId: z.string(),
  createdDateTime: z.preprocess((arg) => {
    if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
  }, z.date()),
  modifiedDateTime: z.preprocess((arg) => {
    if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
  }, z.date()),
  collectionData: CollectionData,
  reportData: ReportData,
  resolved: z.boolean(),
  userEmail: z.string().email(),
});

export type UserReportT = z.infer<typeof UserReport>;

export const CreateUserReport = UserReport.pick({
  farmId: true,
  collectionData: true,
  reportData: true,
});

export type CreateUserReportT = z.infer<typeof CreateUserReport>;
