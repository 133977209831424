import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import LoadingCircle from './LoadingCircle';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    justifyContent: 'center',
  },
  loader: {
    width: '200px',
  },
  blurBackground: {
    backdropFilter: 'blur(1px)',
  },
}));

interface LoadingOverlayProps {
  blurBackground?: boolean;
}

function LoadingOverlay(props: LoadingOverlayProps) {
  const { blurBackground } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${blurBackground ? classes.blurBackground : ''}`}>
      <div className={classes.loader}>
        <LoadingCircle size={100} />
      </div>
    </div>
  );
}

LoadingOverlay.defaultProps = {
  blurBackground: false,
};

export default LoadingOverlay;
