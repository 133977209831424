import { Storage } from 'aws-amplify';

import { APIConfirmedIdentity } from './dataInterfaces';

export const checkIdentityAccuracy = (eid: string | undefined, state: APIConfirmedIdentity | undefined) => {
  if (state !== undefined && eid !== undefined) {
    if (state.eid !== undefined) return state.eid === eid;
    if (state.wrong_eid.length > 0 && state.wrong_eid.includes(eid)) return false;
  }

  return null;
};

/* retrieve data from S3 Bucket */
export const getS3file = async (fn: string) => {
  try {
    const url = await Storage.get(fn, { download: true });

    return await new Response(url.Body as Blob).json();
  } catch (e: unknown) {
    if (e instanceof Error) {
      console.error(e.message);
    }

    return [];
  }
};
