import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Moment from 'moment';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getHexByScore } from '../Data/ColourScale';
import { eidAccuracyState } from '../Data/dataInterfaces';
import { getEIDAccuracy } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '800px',
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function ChartIdentityAccuracy(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const eidAccuracy = useAppSelector(getEIDAccuracy);
  const { display, dataOption } = props;
  /* compute the differences for the graph */
  const data = eidAccuracy.map((d: eidAccuracyState) => ({
    date: d.date,
    correct: d.correct,
    wrong: d.wrong,
    unchecked: d.eids - (d.wrong + d.correct),
    noid: d.videos - d.eids,
  }));

  const barchart =
    data !== undefined ? (
      <BarChart
        data={data}
        margin={{
          top: 8,
          right: 8,
          left: 8,
          bottom: 0,
        }}
        maxBarSize={50}
        stackOffset={display}
      >
        <XAxis
          dataKey="date"
          axisLine={false}
          ticks={data.map((x: any) => x.date)}
          tickLine={false}
          scale="utc"
          type="number"
          padding={{ left: 25, right: 25 }}
          domain={['dataMin', 'dataMax']}
          tickFormatter={(d: Date) => Moment(d).format('DD MMM')}
          interval="preserveStartEnd" // 'preserveStartEnd'
          angle={30}
          tickCount={data.length}
          tickMargin={10}
          height={40}
          tick={{ fontSize: 12 }}
        />

        <YAxis axisLine={false} tickLine={false} width={30} />

        <Tooltip
          cursor={false}
          labelFormatter={(d: Date) => Moment(d).format('DD MMM')}
          formatter={(l: number) => l.toFixed(0)}
        />

        <Bar
          isAnimationActive={false}
          name="ID Correct"
          type="monotone"
          dataKey="correct"
          fill={getHexByScore(0.0)}
          strokeWidth={2}
          stackId="a"
        />

        <Bar
          isAnimationActive={false}
          name="ID Wrong"
          type="monotone"
          dataKey="wrong"
          fill={getHexByScore(3.0)}
          strokeWidth={2}
          stackId="a"
        />

        {dataOption === 'all' && (
          <>
            <Bar
              isAnimationActive={false}
              name="ID unconfirmed"
              type="monotone"
              dataKey="unchecked"
              fill="#1976D2"
              strokeWidth={2}
              stackId="a"
            />

            <Bar
              isAnimationActive={false}
              name="no ID"
              type="monotone"
              dataKey="noid"
              fill="purple"
              strokeWidth={2}
              stackId="a"
            />
          </>
        )}
      </BarChart>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
    );

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="90%" height={useMediaQuery(theme.breakpoints.up('md')) ? '90%' : 165}>
        {barchart}
      </ResponsiveContainer>
    </div>
  );
}

export default ChartIdentityAccuracy;
