import { stageWrap } from '..';
import { CreateUserReportT, UserReport, UserReportT } from './types';

const getAll =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async ({ farmId }: { farmId: string }): Promise<UserReportT[]> => {
    const query = new URLSearchParams({ farmId });
    const resp = await fetch(`${url}?${query}`, {
      mode: 'cors',
      headers: new Headers({
        ...headers,
        accept: 'application/json',
      }),
    });

    return Promise.all((await resp.json()).items.map(UserReport.parseAsync));
  };

const create =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async (userReport: CreateUserReportT): Promise<UserReportT> => {
    const resp = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        ...headers,
        accept: 'application/json',
      }),
      body: JSON.stringify(userReport),
    });

    return UserReport.parse(await resp.json());
  };

const destroy =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async (userReport: UserReportT): Promise<void> => {
    fetch(`${url}/${userReport.reportId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers: new Headers({
        ...headers,
        accept: 'application/json',
      }),
    });
  };

const resolve =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async (userReport: UserReportT): Promise<UserReportT> => {
    const resp = await fetch(`${url}/${userReport.reportId}/resolve`, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        ...headers,
        accept: 'application/json',
      }),
    });

    return UserReport.parse(await resp.json());
  };

const userReportRoute = stageWrap({ route: '/user-report' });

// eslint-disable-next-line import/prefer-default-export
export const userReports = {
  getAll: userReportRoute(getAll),
  create: userReportRoute(create),
  destroy: userReportRoute(destroy),
  resolve: userReportRoute(resolve),
};
