import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { Card, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import moment from 'moment';

import Calendar from '../Cards/CardCalendar';
import CardCowVideo from '../Cards/CardCowVideo';
import CardDataTable from '../Cards/CardDataTable';
import CardMilkingSession from '../Cards/CardMilkingSession';
import { collectionActionReportIssue, collectionColumns } from '../Cards/DataTableProps';
import FormReportCollection from '../Cards/FormReportCollection';
import TableMilkingSession from '../Charts/TableMilkingSession';
import { dayState, milkingState } from '../Data/dataInterfaces';
import { getAllMilkingDays, getCollectionsWithTagsFromIds } from '../Data/farmDataReduxSlice';
// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../Data/pageReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import { isScoreVisible } from '../Data/userReduxSlice';
import ModalWindow from '../common/ModalWindow';

const styles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'stretch',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  calendar: {
    alignSelf: 'center',
    [theme.breakpoints.up('lg')]: {
      alignSelf: 'flex-start',
    },
  },
}));

function VideoPopup({
  collection_id,
  selectCollection,
}: {
  collection_id: number | undefined;
  selectCollection: Dispatch<SetStateAction<number | undefined>>;
}) {
  return (
    <ModalWindow
      open={collection_id !== undefined}
      toggle={() => {
        selectCollection(undefined);
      }}
      title="Selected Video"
      fullHeight
    >
      <CardCowVideo
        collection_id={collection_id}
        showDetails
        showProfile
        showHistory
        changeCollection={selectCollection}
      />
    </ModalWindow>
  );
}

function Milking(props: any) {
  const { milking, title, selectCollection } = props;
  const collections = useAppSelector(getCollectionsWithTagsFromIds(milking.collection_ids));
  const [reportCollection, setReportCollection] = React.useState<number | undefined>(undefined);
  const scoreVisibility = useAppSelector(isScoreVisible);
  const blankMilking: any = {
    eid_count: 0,
    track_count: 0,
    score_count: 0,
    score_mean: 0,
    score_bins: [],
    collection_ids: [],
  };

  const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'score', sort: 'desc' }]);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const [page, setPage] = React.useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_pageSize, setPageSize] = React.useState<number>(0);

  return (
    <Stack sx={{ minWidth: '350px', flexGrow: 1, alignSelf: 'stretch' }}>
      <Typography component="h2" variant="h5">
        {title}
      </Typography>

      <Card sx={{ minHeight: '800px', flexGrow: 1 }}>
        <Stack sx={{ height: '100%' }}>
          <CardMilkingSession milking={scoreVisibility ? milking : { ...milking, ...blankMilking }} />

          <CardDataTable
            data={collections}
            columns={collectionColumns(
              ['status', 'score', 'tag', 'time'],
              scoreVisibility ? undefined : ['score']
            ).concat([
              collectionActionReportIssue((collection_id: number) => {
                setReportCollection(collection_id);
              }),
            ])}
            page={page}
            onPageChange={setPage}
            setPageSize={setPageSize}
            sortModel={sortModel}
            setSortModel={setSortModel}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            header={false}
            emptyMessage="No Collections"
            selectRowCallback={(rowId: GridRowId | undefined) => {
              if (rowId === undefined) selectCollection(undefined);
              else selectCollection(rowId as number);
            }}
          />

          <FormReportCollection
            collection_id={reportCollection}
            visible={reportCollection !== undefined}
            close={() => {
              setReportCollection(undefined);
            }}
          />
        </Stack>
      </Card>
    </Stack>
  );
}

function MilkingCalendarStack({
  selectDateCallback,
  selectedDate,
}: {
  selectDateCallback: React.Dispatch<Date>;
  selectedDate: Date;
}) {
  const classes = styles();

  return (
    <Stack sx={{ width: '350px' }} className={classes.calendar}>
      <Typography component="h2" variant="h5">
        Date Selection
      </Typography>

      <Card>
        <Stack alignItems="center">
          <TableMilkingSession />
          <Calendar selectDateCallback={selectDateCallback} selectedDate={selectedDate} />
        </Stack>
      </Card>
    </Stack>
  );
}

function MilkingDetailsStacks({ day }: { day: dayState | undefined }) {
  const [collectionId, selectCollection] = React.useState<number | undefined>(undefined);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (day === undefined || day.milkings.length === 0) return <></>;

  return (
    <>
      {day.milkings
        .slice(0)
        .reverse()
        .map((m: milkingState) => (
          <Milking
            key={`milking-session-${m.start}`}
            milking={m}
            title={`${moment(m.start).format('HH:mm A')}`}
            selectCollection={selectCollection}
          />
        ))}

      <VideoPopup collection_id={collectionId} selectCollection={selectCollection} />
    </>
  );
}

function MilkingDiaryLayout(props: any) {
  const { pageIndex } = props;
  const classes = styles();
  const allDays = useAppSelector(getAllMilkingDays);
  const [day, setDay] = React.useState<dayState>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);

  React.useEffect(() => {
    if (day === undefined) {
      setDay(allDays[0]);
    }
  }, [allDays]);

  const setMilkingDayBydate = (date: Date) => {
    const d1 = moment(date);

    for (let i = 0; i < allDays.length; ++i) {
      const d2 = moment(allDays[i].date);
      const compare = d1.startOf('day').isSame(d2.startOf('day'));

      if (compare) {
        setDay(allDays[i]);
      }
    }
  };

  return (
    <Stack className={classes.root}>
      <MilkingCalendarStack selectDateCallback={setMilkingDayBydate} selectedDate={moment(day?.date).toDate()} />
      <MilkingDetailsStacks day={day} />
    </Stack>
  );
}

export default MilkingDiaryLayout;
