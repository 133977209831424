import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Moment from 'moment';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getHexByScore } from '../Data/ColourScale';
import { getAllMilkingDays, getPrevalenceGraph, getSeverePrevalenceGraph } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '800px',
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function ChartLameLine(props: any) {
  const { dataOption } = props;
  const classes = useStyles();
  const theme = useTheme();
  const avg = useAppSelector(getAllMilkingDays);
  const prevalence = useAppSelector(getPrevalenceGraph);
  const severePrevalence = useAppSelector(getSeverePrevalenceGraph);
  const lineChart =
    avg !== undefined && prevalence !== undefined && severePrevalence !== undefined ? (
      <LineChart
        data={avg}
        margin={{
          top: 8,
          right: 8,
          left: 8,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          scale="utc"
          type="number"
          padding={{ left: 25, right: 25 }}
          domain={['dataMin', 'dataMax']}
          tickFormatter={(d: Date) => Moment(d).format('DD MMM')}
          interval="preserveStartEnd" // interval={'preserveStartEnd'}
          angle={30}
          tickCount={avg.length}
          tickMargin={10}
          height={40}
          tick={{ fontSize: 12 }}
        />

        {dataOption === 'average' ? (
          <>
            <YAxis
              axisLine={false}
              tickLine={false}
              width={32}
              allowDataOverflow
              tickFormatter={(l: number) => l.toFixed(1)}
              yAxisId="1"
            />

            <Tooltip
              cursor={false}
              labelFormatter={(d: Date) => Moment(d).format('DD MMM')}
              formatter={(l: number) => l.toFixed(2)}
            />

            <Line
              data={avg.filter((d) => d.score_mean > 0)}
              yAxisId="1"
              isAnimationActive={false}
              name="Average"
              type="basis"
              dataKey="score_mean"
              stroke="#1976D2"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}

        {dataOption === 'prevalence' ? (
          <>
            <YAxis
              axisLine={false}
              tickLine={false}
              width={40}
              allowDataOverflow
              tickFormatter={(l: number) => `${(l * 100).toFixed(1)}%`}
              yAxisId="2"
            />

            <Tooltip
              cursor={false}
              labelFormatter={(d: Date) => Moment(d).format('DD MMM')}
              formatter={(l: number) => `${(l * 100).toFixed(1)}%`}
            />

            <Line
              data={prevalence}
              yAxisId="2"
              isAnimationActive={false}
              name="Prevalence"
              type="linear"
              dataKey="value"
              stroke={getHexByScore(2.0)}
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}

        {dataOption === 'severe-prevalence' ? (
          <>
            <YAxis
              axisLine={false}
              tickLine={false}
              width={40}
              allowDataOverflow
              tickFormatter={(l: number) => `${(l * 100).toFixed(1)}%`}
              yAxisId="2"
            />

            <Tooltip
              cursor={false}
              labelFormatter={(d: Date) => Moment(d).format('DD MMM')}
              formatter={(l: number) => `${(l * 100).toFixed(1)}%`}
            />

            <Line
              data={severePrevalence}
              yAxisId="2"
              isAnimationActive={false}
              name="Severe Prevalence"
              type="linear"
              dataKey="value"
              stroke={getHexByScore(3.0)}
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}
      </LineChart>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
    );

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="90%" height={useMediaQuery(theme.breakpoints.up('md')) ? '90%' : 165}>
        {lineChart}
      </ResponsiveContainer>
    </div>
  );
}

export default ChartLameLine;
