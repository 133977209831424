/* eslint-disable react/require-default-props */
import React from 'react';

import { Card, Stack, SxProps, Theme, Typography } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export function TitleStack({
  title,
  children,
  sx = [],
}: React.PropsWithChildren<{
  title?: string;
  sx?: SxProps<Theme>;
}>) {
  return (
    <Stack
      sx={[
        {
          flexGrow: 'true',
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          height: '100%',
          minHeight: '600px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {title !== undefined && (
        <Typography component="h2" variant="h5">
          {title}
        </Typography>
      )}

      <Card sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>{children}</Card>
    </Stack>
  );
}
