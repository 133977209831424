import React from 'react';
import { useDispatch } from 'react-redux';

import { GridSelectionModel, GridSortModel } from '@mui/x-data-grid';

// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../components/Data/pageReduxSlice';

export const usePageIndex = (pageIndex: number) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);
};

export const useDataTable = ({
  initialSortModel,
  initialSelectionModel,
  initialPage,
  initialPageSize,
}: {
  initialSortModel: GridSortModel;
  initialSelectionModel: GridSelectionModel;
  initialPage: number;
  initialPageSize: number;
}) => {
  const [sortModel, setSortModel] = React.useState<GridSortModel>(initialSortModel);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(initialSelectionModel);
  const [page, setPage] = React.useState<number>(initialPage);
  const [pageSize, setPageSize] = React.useState<number>(initialPageSize);

  return {
    sortModel,
    setSortModel,
    selectionModel,
    setSelectionModel,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};
