import React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';
import { GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';

import CardCowProfile from '../Cards/CardCowProfile';
import CardCowVideo from '../Cards/CardCowVideo';
import CardDataTable from '../Cards/CardDataTable';
import { cowColumns } from '../Cards/DataTableProps';
import { getAllCows, getCow, getCowFromTag } from '../Data/farmDataReduxSlice';
// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../Data/pageReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import { isScoreVisible } from '../Data/userReduxSlice';
import { TitleStack } from './TitleStack';

function CowListStack({ cowId, setCowId }: { cowId: number | undefined; setCowId: any }) {
  const cowList = useAppSelector(getAllCows);
  const scoreVisibility = useAppSelector(isScoreVisible);
  const [searchParams, setSearchParams] = useSearchParams();
  const getCowByTag = useAppSelector(getCowFromTag);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'last_scored', sort: 'desc' }]);
  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(0);

  React.useEffect(() => {
    const tag = searchParams.get('cow');

    if (tag?.startsWith('unknown')) {
      setCowId(+tag.split('-')[1]);
      setSelectionModel([+tag.split('-')[1]]);
    } else if (tag !== null) {
      const cow = getCowByTag(tag);
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const cowId = cow?.id;

      setSortModel([{ field: 'last_scored', sort: 'desc' }]);
      setCowId(() => (cowId !== undefined ? +cowId : undefined));
      setSelectionModel(() => (cowId !== undefined ? [+cowId] : []));
      setPage(() => {
        if (pageSize > 0 && cow?.lastScoredIndex) {
          return Math.floor(cow.lastScoredIndex / pageSize);
        }

        return 0;
      });
    }
  }, [searchParams, pageSize]);

  return (
    <TitleStack title="Cow List">
      <CardDataTable
        data={cowList} // cow _ids
        columns={cowColumns(
          ['current_status', 'tag', 'last_score', 'totalVideos', 'last_scored'],
          scoreVisibility ? [] : ['last_score', 'last_scored']
        )}
        page={page}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onPageChange={(page) => {
          setSearchParams({});
          setPage(page);
        }}
        setPageSize={setPageSize}
        header
        sortModel={sortModel}
        setSortModel={setSortModel}
        setSelectionModel={setSelectionModel}
        selectionModel={selectionModel}
        emptyMessage="No Cow Data"
        selectRowCallback={(rowId: GridRowId | undefined) => {
          if (rowId === undefined) setCowId(undefined);
          else {
            if (rowId === cowId) {
              setCowId(undefined);
              setSearchParams({});

              return;
            }

            setCowId(rowId as number);
            setSearchParams({});
          }
        }}
      />
    </TitleStack>
  );
}

function CowProfileStack({
  cowId,
  collectionId,
  setCollectionId,
}: {
  cowId: number | undefined;
  collectionId: number | undefined;
  setCollectionId: React.Dispatch<number | undefined>;
}) {
  return (
    <TitleStack title="Cow Details">
      <CardCowProfile cow_id={cowId} collection_id={collectionId} changeCollection={setCollectionId} />
    </TitleStack>
  );
}

function CowVideosStack({
  collectionId,
  setCollectionId,
}: {
  collectionId: number | undefined;
  setCollectionId: React.Dispatch<number | undefined>;
}) {
  return (
    <TitleStack title="Cow Videos">
      <CardCowVideo collection_id={collectionId} showDetails showHistory changeCollection={setCollectionId} />
    </TitleStack>
  );
}

function CowBrowserLayout(props: any) {
  const [cowId, setCowId] = React.useState<number | undefined>();
  const cow = useAppSelector(getCow(cowId));
  const { pageIndex } = props;
  const [collectionId, setCollectionId] = React.useState<number | undefined>();

  React.useEffect(() => {
    if (cow !== undefined) {
      setCollectionId(cow.collection_ids[0]);
    }
  }, [cow]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);

  return (
    <Stack
      sx={{
        padding: 2,
        height: '100%',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      <CowListStack setCowId={setCowId} cowId={cowId} />

      {cow !== undefined && (
        <>
          <CowProfileStack cowId={cowId} collectionId={collectionId} setCollectionId={setCollectionId} />
          <CowVideosStack collectionId={collectionId} setCollectionId={setCollectionId} />
        </>
      )}
    </Stack>
  );
}

export default CowBrowserLayout;
