// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import { adaptV4Theme, createTheme } from '@mui/material/styles';

import { getHexByName } from './components/Data/ColourScale';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createShadow = (...px: any[]) => {
  const { r, g, b } = { r: 0, g: 0, b: 0 };
  /* Material Design shadows are cast from a "key light" and an "ambient light" */
  const keyUmbraOpacity = 0.2; /* inner shadow: default = 0.2 */
  const keyPenumbraOpacity = 0.14; /* outer shadow, default = 0.14 */
  const ambientOpacity = 0.12; // global illumination approximation, default = 0.2

  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(${r},${g},${b},${keyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(${r},${g},${b},${keyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(${r},${g},${b},${ambientOpacity})`,
  ].join(',');
};

/* Matu Brand Colours */
const blue = '#1976D2';
const lightBlue = '#63a3df'; // "#4391DD"; // "#D1E4F6";
const darkBlue = '#22609D';
// https://material-ui.com/customization/default-theme/
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: lightBlue,
      main: blue,
      dark: darkBlue,
      contrastText: '#FFFFFF',
    },
    // secondary: {
    // 	main: "#FF0000",
    // 	contrastText: "#FFFFFF"
    // },
    // success: {
    // 	main: "#FF0000",
    // 	contrastText: "#FFFFFF"
    // },
    error: {
      main: getHexByName('red'),
      contrastText: '#FFFFFF',
    },
    // warning: {
    // 	main: "#FF0000",
    // 	contrastText: "#FFFFFF"
    // },
    // info: {
    // 	main: "#FF0000",
    // 	contrastText: "#FFFFFF"
    // },
    background: {
      paper: '#FFFFFF',
      default: '#E5E5E5',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  /* Material UI's default shadow values */
  // shadows: [
  // 	"none",
  // 	createShadow(0, 1, 3, 0, 0, 1, 1, 0, 0, 2, 1, -1),
  // 	createShadow(0, 1, 5, 0, 0, 2, 2, 0, 0, 3, 1, -2),
  // 	createShadow(0, 1, 8, 0, 0, 3, 4, 0, 0, 3, 3, -2),
  // 	createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  // 	createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  // 	createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  // 	createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  // 	createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  // 	createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  // 	createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  // 	createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  // 	createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  // 	createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  // 	createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  // 	createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  // 	createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  // 	createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  // 	createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  // 	createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  // 	createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  // 	createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  // 	createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  // 	createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  // 	createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8)
  // ],
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1: {
      fontSize: 14, // "1.0rem"
    },
    // 	button: {
    // 		fontSize: "0.9rem"
    // 	},
    // 	h1: {
    // 		// Navigation Ribbon
    // 		fontSize: "1.25rem"
    // 	},
    // 	h2: {
    // 		// Pharmac "Supported by"
    // 		fontSize: "24px",
    // 		color: "#FFFFFF",
    // 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    // 	},
    // 	h3: {
    // 		// Card Headers
    // 		fontSize: "1.5rem",
    // 		color: "#FF0000"
    // 	},
    // 	h4: {
    // 		// Card section headers
    // 		fontSize: "1.5rem",
    // 		color: "#FF0000",
    // 		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    // 	},
    // 	h5: {
    // 		// info box header
    // 		fontSize: "1.5rem",
    // 		fontFamily: "Times New Roman, Times, serif"
    // 	},
    caption: {
      // footnotes
      fontSize: '0.7rem',
    },
    subtitle2: {
      fontWeight: 600,
      // fontSize: 50
    },
  },
});

export default theme;
