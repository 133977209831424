import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  circle: {
    color: theme.palette.primary.main,
  },
}));

function LoadingCircle(props: any) {
  const { size } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress variant="indeterminate" thickness={2} className={classes.circle} size={size || 200} />
    </Box>
  );
}

export default LoadingCircle;
