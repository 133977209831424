/* eslint-disable react/require-default-props */
import React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';

export function DefaultOrNumericRadioGroup({
  isError,
  setIsError,
  isCustom,
  setIsCustom,
  value,
  setValue,
  onChangeRadio,
  groupName,
  groupLabel,
  defaultLabel,
  customLabel,
  customInputProps,
  onChangeNumber,
}: {
  isError: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  isCustom: boolean;
  setIsCustom: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onChangeRadio?: (value: string) => void;
  onChangeNumber?: () => void;
  groupName: string;
  groupLabel: string;
  defaultLabel: string;
  customLabel: string;
  customInputProps?: any;
}) {
  const changeGroupHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCustom(() => {
      if (onChangeRadio !== undefined) onChangeRadio(event.target.value);
      setIsError(false);

      if (event.target.value === 'custom') {
        return true;
      }

      return false;
    });
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel id={`${groupName}-radio-label`}>{groupLabel}</FormLabel>

      <FormGroup
        row
        sx={{
          justifyContent: 'space-around',
          width: '100%',
          alignItems: 'flex-end',
        }}
      >
        <RadioGroup
          value={isCustom ? 'custom' : 'default'}
          aria-labelledby={`${groupName}-radio-label`}
          name={`${groupName}-radio-group`}
          onChange={changeGroupHandler}
        >
          <FormControlLabel value="default" control={<Radio />} label={defaultLabel} />

          <FormControlLabel
            value="custom"
            control={<Radio />}
            label={
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Box sx={{ paddingRight: 1, flexGrow: 2 }}>{`${customLabel}:`}</Box>

                <TextField
                  sx={{ flexGrow: 1 }}
                  required
                  value={value}
                  type="number"
                  error={isError}
                  disabled={!isCustom}
                  InputProps={customInputProps}
                  onChange={(event) => {
                    if (onChangeNumber !== undefined) onChangeNumber();
                    setIsError(false);
                    setValue(event.target.value);
                  }}
                />
              </Stack>
            }
          />
        </RadioGroup>
      </FormGroup>
    </FormControl>
  );
}

export default DefaultOrNumericRadioGroup;
