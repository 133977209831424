import { APIService } from './data';
import { ObservationAPIResponseData } from './types';

async function getObservationDetails(
  farmId: number,
  observationId: number,
  headers: Record<string, string> = {}
): Promise<ObservationAPIResponseData> {
  const url = `${process.env.REACT_APP_FARM_API_URL}/v1/farms/${farmId}/observations/${observationId}`;

  return APIService.get(url, headers);
}

// eslint-disable-next-line import/prefer-default-export
export const observationData = {
  getObservationDetails,
};
