import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { fixScore } from '../Data/ColourScale';
import { getVideoDetails } from '../Data/dataFormatting';
import { getCollection } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import LameStatus from '../common/LameStatus';

const useStyles = makeStyles((theme) => ({
  table: {},
  StatusScore: {
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
  TitleAndButton: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  aDownload: {
    textDecoration: 'none',
  },
}));

function TableVideoDetails(props: any) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { collection_id } = props;
  const classes = useStyles();
  const [tableRows, setTableRows] = React.useState<any[]>([]);
  const collection = useAppSelector(getCollection(collection_id));

  React.useEffect(() => {
    if (collection !== undefined) {
      setTableRows(getVideoDetails(collection));
    }
  }, [collection]);

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableBody>
          {tableRows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th">
                <Typography variant="subtitle2" noWrap>
                  {row.name}
                </Typography>
              </TableCell>

              <TableCell align="right">
                {row.name === 'Lameness Score' ? (
                  <div>
                    <LameStatus score={row.value} />
                    <Typography className={classes.StatusScore}>{fixScore(row.value)?.toFixed(2) ?? '?'}</Typography>
                  </div>
                ) : (
                  <Typography noWrap>{row.value}</Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableVideoDetails;
