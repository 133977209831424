import React from 'react';
import { useDispatch } from 'react-redux';

import { Stack } from '@mui/material';

import CardCowVideo from '../Cards/CardCowVideo';
import DraftingCard from '../Cards/DraftList';
import ObservationList from '../Cards/ObservationList';
// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../Data/pageReduxSlice';
import ModalWindow from '../common/ModalWindow';

function VideoPopup({ collectionId, setCollectionId }: any) {
  return (
    <ModalWindow
      open={collectionId !== undefined}
      toggle={() => {
        setCollectionId(undefined);
      }}
      title="Selected Video"
      fullHeight
    >
      <CardCowVideo
        collection_id={collectionId}
        showDetails
        showProfile
        showHistory
        showLameGraph
        changeCollection={setCollectionId}
      />
    </ModalWindow>
  );
}

function OverviewLayout(props: any) {
  /* update page */
  const dispatch = useDispatch();
  const { pageIndex } = props;

  React.useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);

  const [collectionId, setCollectionId] = React.useState<number | undefined>(undefined);

  return (
    <Stack
      sx={{
        padding: 2,
        height: '100%',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <DraftingCard setCollectionId={setCollectionId} />
      <ObservationList setCollectionId={setCollectionId} />
      <VideoPopup collectionId={collectionId} setCollectionId={setCollectionId} />
    </Stack>
  );
}

export default OverviewLayout;
