import { configureStore } from '@reduxjs/toolkit';

import cowsReducer, {
  CurrentCowReduxSlice,
  CurrentObservationReduxSlice,
  ObservationDataReduxSlice,
} from './CowDataReduxSlice';
import userReportsReducer from './UserReportReduxSlice';
import farmDataReducer from './farmDataReduxSlice';
import pageReducer from './pageReduxSlice';
import userReducer from './userReduxSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    page: pageReducer,
    farm: farmDataReducer,
    userReports: userReportsReducer,
    cows: cowsReducer,
    currentCow: CurrentCowReduxSlice.reducer,
    currentObservation: CurrentObservationReduxSlice.reducer,
    observations: ObservationDataReduxSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
