import React from 'react';

import { getCowProfileTable } from '../Data/dataFormatting';
import { TableEntryT } from '../Data/dataInterfaces';
import { getCow } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import DetailsTable from '../DetailsTable';

function TableCowDetails(props: {
  cowId: number | undefined;
  // eslint-disable-next-line react/require-default-props
  onlyTags?: boolean | undefined;
}) {
  const { cowId, onlyTags } = props;
  const cow = useAppSelector(getCow(cowId));
  let entries: TableEntryT[] = getCowProfileTable(cow);

  if (onlyTags) {
    entries = [entries[0], entries[1]];
  }

  entries = entries.filter(({ value }) => value !== '?');

  return <DetailsTable details={entries} rowLength={2} tableName="cow details" />;
}

export default TableCowDetails;
