import { stageWrap } from '..';
import { APIConfirmedIdentity } from '../../components/Data/dataInterfaces';
import { convertNewIdentifierToOldIdentifier } from '../../helpers/identifiers';

const submit =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async ({
    uuid,
    eid,
    correction,
    userEmail,
    farmIdentifier,
  }: {
    uuid: string; // uuid of the video collection
    eid?: string; // eid of assigned or replacement cow
    state?: APIConfirmedIdentity; // previously loaded state
    correction: string;
    userEmail: string;
    farmIdentifier: string;
  }) => {
    let data: {
      UpdateType?: string;
      CollectionUuid: string;
      User: string;
      Eid: string | undefined;
    }[] = [];

    const baseData = {
      CollectionUuid: uuid,
      User: userEmail,
      Eid: eid,
    };

    if (correction === 'setEid') {
      data = [
        {
          UpdateType: 'setEid',
          ...baseData,
        },
      ];
    } else if (correction === 'addWrongEid') {
      data = [
        {
          UpdateType: 'addWrongEid',
          ...baseData,
        },
      ];
    } else if (correction === 'removeWrongEid') {
      data = [
        {
          UpdateType: 'removeWrongEid',
          ...baseData,
        },
      ];
    } else {
      data = [
        {
          UpdateType: 'removeEid',
          ...baseData,
        },
      ];
    }

    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        ...headers,
        accept: 'application/json',
      }),
      body: JSON.stringify({
        ...convertNewIdentifierToOldIdentifier(farmIdentifier),
        data,
      }),
    });
  };

const readAll =
  ({ url, headers }: { url: string; headers: Record<string, string> }) =>
  async ({ farmIdentifier }: { farmIdentifier: string }) => {
    const oldIdentifierAndScheme = convertNewIdentifierToOldIdentifier(farmIdentifier);
    const query = new URLSearchParams(oldIdentifierAndScheme);
    const resp = await fetch(`${url}?${query}`, {
      mode: 'cors',
      headers: new Headers({
        ...headers,
        accept: 'application/json',
      }),
    });

    const response = await resp.json();

    if (!response) {
      throw new Error('Farm setup incorrectly. Check database.');
    }

    return response?.Items ?? [];
  };

const cowIdentityRoute = stageWrap({ route: '/cow-identity' });

// eslint-disable-next-line import/prefer-default-export
export const cowIdentity = {
  submit: cowIdentityRoute(submit),
  readAll: cowIdentityRoute(readAll),
};
