/* Helper functions which do not refer to Redux or any API */
import moment from 'moment';

import { Snooze } from '../../api/cow-snooze';
import type { Cow } from '../../api/farmAPI/types';
import { collectionState, cowState, dayState, histogramEntry, milkingState, statistics } from './dataInterfaces';

export const defaultLameThreshold = 2.0;

export const clamp = (num: number, min: number, max: number) =>
  // eslint-disable-next-line no-nested-ternary
  num <= min ? min : num >= max ? max : num;

export const currentlySnoozed = (s: Snooze | undefined): any => {
  if (s === undefined) {
    return false;
  }

  return new Date().valueOf() < s.snoozeExpiry.valueOf();
};

export const getHistogramBuckets = (milking: dayState | milkingState | undefined) => {
  const names = ['Very Healthy', 'Healthy', 'Uneven', 'Mildly lame', 'Lame', 'Very Lame'];
  const medians = [0.25, 0.75, 1.25, 1.75, 2.25, 2.75];
  const bins = milking === undefined ? [0, 0, 0, 0, 0, 0] : milking.score_bins;

  return names.map(
    (name: string, i: number) =>
      ({
        name,
        quantity: bins[i],
        binMedian: medians[i],
      } as histogramEntry)
  );
};

export const getStatsLastMilking = (milking: milkingState | undefined) => {
  let date = '?';
  let firstEid = '?';
  let lastEid = '?';
  let totalTracked = 0;
  let totalScanned = 0;
  let totalScored = 0;

  if (milking !== undefined) {
    date = moment(milking.start).format('ddd DD MMM YYYY');
    firstEid = moment(milking.start).format('HH:mm');
    lastEid = moment(milking.end).format('HH:mm');

    totalTracked = milking.track_count;
    totalScanned = milking.eid_count;
    totalScored = milking.score_count;
  }

  return [
    {
      name: 'Date',
      value: `${date} ${firstEid}-${lastEid}`,
    },
    {
      name: 'eIDs read',
      value: `${totalScanned}`,
    },
    {
      name: 'Tracked',
      value: `${totalTracked}`,
    },
    {
      name: 'Scored',
      value: `${totalScored}`,
    },
  ];
};

export const ageString = (cowDob: string | undefined) => {
  if (cowDob === undefined) return '?';
  const today = moment();
  const dob = moment(cowDob);
  const years = today.diff(dob, 'years');

  dob.add(years, 'years');
  const months = today.diff(dob, 'months');

  return `${years}y ${months}m`;
};

export const getAllMilkingStats = (days: dayState[] | undefined) => {
  let firstDate = 'Never';
  let lastDate = 'Never';
  let totalDays = '0';
  let totalMilkings = '0';

  if (days !== undefined && days.length > 0) {
    lastDate = moment(days[0].date).format('ddd DD MMM YYYY');
    firstDate = moment(days[days.length - 1].date).format('ddd DD MMM YYYY');
    totalDays = days.length.toString();
    totalMilkings = days.reduce((sum: number, d: dayState) => sum + d.milkings.length, 0).toString();
  }

  return [
    {
      name: 'First Milking',
      value: firstDate,
    },
    {
      name: 'Latest Milking',
      value: lastDate,
    },
    {
      name: 'Total Days',
      value: totalDays,
    },
    {
      name: 'Total Milkings',
      value: totalMilkings,
    },
  ];
};

export const getVideoDetails = (collection: collectionState | undefined) => {
  let score: number | string = '?';
  let date = '?';

  if (collection !== undefined) {
    score = collection.score === undefined ? score : collection.score;
    date = moment(collection.date).format('HH:mm DD MMM YYYY');
  }

  return [
    {
      name: 'Lameness Score',
      value: score,
    },
    {
      name: 'Recorded',
      value: date,
    },
  ];
};

export const getCowProfileTable = (cow: cowState | undefined) => {
  let tag = '?';
  let dob = '?';
  let age = '?';
  let eid = '?';
  let birthid = '?';
  let breed = '?';

  if (cow !== undefined) {
    tag = cow.tag?.toString() ?? tag;
    dob = cow.dob === undefined ? dob : moment(cow.dob).format('DD MMM YYYY');
    age = cow.dob === undefined ? age : ageString(cow.dob);
    eid = cow.eid ?? eid;
    birthid = cow.birthID ?? birthid;
    breed = cow.breed ?? breed;
  }

  return [
    {
      name: 'Tag',
      value: tag,
    },
    {
      name: 'eID',
      value: eid,
    },
    {
      name: 'BirthID',
      value: birthid,
    },
    {
      name: 'DoB',
      value: dob,
    },
    {
      name: 'Age',
      value: age,
    },
    {
      name: 'Breed',
      value: breed,
    },
  ];
};

export const getLamenessThresholds = () => [
  { name: 'Very lame', value: 2.5 },
  { name: 'Lame', value: 2.0 },
  { name: 'Mildly lame', value: 1.5 },
  { name: 'All', value: 0.0 },
];

export const getCowPreviewProfileTable = (cow: Cow | undefined) => {
  const defaultValue = '?';
  const { tag, dateOfBirth, electronicId, birthId, breed } = cow ?? {};
  const formattedDob = dateOfBirth ? moment(dateOfBirth).format('DD MMM YYYY') : defaultValue;
  const formattedAge = dateOfBirth ? ageString(dateOfBirth) : defaultValue;

  return [
    { name: 'Tag', value: tag?.toString() ?? defaultValue },
    { name: 'eID', value: electronicId ?? defaultValue },
    { name: 'BirthID', value: birthId ?? defaultValue },
    { name: 'DoB', value: formattedDob },
    { name: 'Age', value: formattedAge },
    { name: 'Breed', value: breed ?? defaultValue },
  ];
};

export const isPresetThreshold = (threshold: number) => {
  return getLamenessThresholds().some((el) => el.value === threshold);
};

export const formatOverviewStatistics = (data: statistics, scoreVisibility: boolean) => {
  let totalCows = '?';
  // let total_lame = '?';
  let prevalence = '?';
  let severePrevalence = '?';
  let averageScore = '?';

  if (data !== undefined) {
    totalCows = data.cows.toLocaleString();
    // total_lame = data.lame_cows.toLocaleString();
    if (data.prevalence !== undefined && scoreVisibility) prevalence = `${(data.prevalence * 100).toFixed(2)}%`;
    if (data.severe_prevalence !== undefined && scoreVisibility)
      severePrevalence = `${(data.severe_prevalence * 100).toFixed(2)}%`;
    if (scoreVisibility) averageScore = data.average_score.toFixed(2);
  }

  return [
    {
      label: 'Cows Seen',
      value: totalCows,
      info: 'Total number of identified cows.',
    },
    // {
    //   label: 'Estimated Lame',
    //   value: total_lame,
    //   info: `Total number of cows identified lame.`,
    //   // subtitle: `${confirmedLame.toLocaleString()} confirmed. Up to ${possiblyLame.toLocaleString()} estimated.`,
    // },
    {
      label: 'Average Score',
      value: averageScore,
      info: "The average of every cow's most recent score",
    },
    {
      label: 'Prevalence',
      value: prevalence,
      info: `Percentage of cows which are lame.`,
    },
    {
      label: 'Severe Prevalence',
      value: severePrevalence,
      info: `Percentage of cows which are lame.`,
    },
  ];
};

export const validateEmail = (data: string) => {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (String(data).toLowerCase().match(re)) {
    return true;
  }

  return false;
};

export const validatePhoneNumber = (data: string) => {
  const re = /^(\d[-\s]?){6,11}\d$/;

  if (String(data).toLowerCase().match(re)) {
    return true;
  }

  return false;
};

export const formatStatistics = (data: statistics) => {
  let totalVideos = '?';
  let totalCows = '?';
  let prevalence = '?';
  let severePrevalence = '?';
  let eidAccuracy = '?';

  if (data !== undefined) {
    totalVideos = data.videos.toLocaleString();
    totalCows = data.cows.toLocaleString();
    if (data.prevalence !== undefined) prevalence = `${(data.prevalence * 100).toFixed(2)}%`;
    if (data.severe_prevalence !== undefined) severePrevalence = `${(data.severe_prevalence * 100).toFixed(2)}%`;

    if (data.eid_accuracy !== undefined) {
      eidAccuracy = `${(data.eid_accuracy * 100).toFixed(2)}%`;
    }
  }

  return [
    {
      label: 'Videos',
      value: totalVideos,
      info: 'Total number of videos.',
    },
    {
      label: 'Cows',
      value: totalCows,
      info: 'Total number of identified cows.',
    },
    {
      label: 'Prevalence',
      value: prevalence,
      info: `Percentage of cows which are lame.`,
    },
    {
      label: 'Severe Prevalence',
      value: severePrevalence,
      info: `Percentage of cows which are lame.`,
    },
    {
      label: 'ID Accuracy',
      value: eidAccuracy,
      info: 'Percentage of cows which were accurately identified by the automatic system.',
    },
  ];
};

export const getCowLamenessTable = (collections: collectionState[] | undefined) => {
  let score: number | string = '?';
  let totalScores = '?';
  let totalVideos = '?';
  let firstDate = '?';
  let lastDate = '?';

  if (collections !== undefined && collections.length > 0) {
    totalScores = collections
      .filter((collection: collectionState) => collection !== undefined && collection.score !== undefined)
      .length.toString();
    totalVideos = collections.length.toString();
    lastDate = moment(collections[0].date).format('HH:mm DD MMM YYYY');
    firstDate = moment(collections[collections.length - 1].date).format('HH:mm DD MMM YYYY');
    const f = collections.filter((collection: collectionState) => collection.score !== undefined);

    if (f !== undefined && f.length > 0) {
      for (let i = 0; i < f.length; i++) {
        if (f[i] !== undefined && f[i].score !== undefined) {
          score = f[i]?.score ?? '?';
          break;
        }
      }
    }
  }

  return [
    {
      name: 'Current lameness',
      value: score,
    },
    {
      name: 'Total Scores',
      value: totalScores,
    },
    {
      name: 'Videos',
      value: totalVideos,
    },
    {
      name: 'Latest Video',
      value: lastDate,
    },
    {
      name: 'First Video',
      value: firstDate,
    },
  ];
};

export const binarySearch = <ArrayType, KeyType>(
  ar: ArrayType[],
  el: KeyType,
  key_fn: (item: ArrayType) => KeyType,
  compare_fn: (a: KeyType, b: KeyType) => number
) => {
  let m = 0;
  let n = ar.length - 1;

  while (m <= n) {
    const k = (n + m) >> 1;
    const cmp = compare_fn(el, key_fn(ar[k]));

    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }

  return -m - 1;
};

export const subarrayBetween = <ArrayType, KeyType>(
  array: ArrayType[],
  a: KeyType,
  b: KeyType,
  key_fn: (item: ArrayType) => KeyType,
  compare_fn: (a: KeyType, b: KeyType) => number
) => {
  let startIndex = binarySearch(array, a, key_fn, compare_fn);

  if (startIndex < 0) {
    startIndex = ~startIndex;
  }

  let endIndex = binarySearch(array, b, key_fn, compare_fn);

  if (endIndex < 0) {
    endIndex = ~endIndex;
  }

  return array.slice(startIndex, endIndex);
};

export const chunked = <T>({ arr, chunkSize }: { arr: T[]; chunkSize: number }) =>
  arr.reduce((acc, _, i) => {
    if (i % chunkSize === 0) acc.push(arr.slice(i, i + chunkSize));

    return acc;
  }, [] as T[][]);
