/* eslint-disable no-param-reassign */
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Farm } from '../../api/farmAPI/types';
import type { RootState } from './store';

interface UserState {
  developer: boolean;
  email: string;
  demoMode: boolean;
  availableFarms: Farm[];
  // declare visibility paramaters here
  eidVisible: boolean;
  scoreVisible: boolean;
  // needed fields
  notifyEmail: boolean;
  emailList: string[];
  notifyPush: boolean;
  phoneList: string[];
  userSeen: boolean;
  lameThreshold: number;
  snoozeDuration: number;
  loggedInUser: CognitoUserInterface | any;
  lastRefreshed: string;
  EULAConfirmed: boolean;
  EulaConfirmedDate: string;
}

export const userReduxSlice = createSlice({
  name: 'user',
  initialState: {
    developer: false,
    demoMode: false,
    email: 'Error: no email.',
    availableFarms: [],
    // initialise viz params
    eidVisible: true,
    scoreVisible: true,
    notifyEmail: false,
    emailList: [],
    notifyPush: false,
    phoneList: [],
    userSeen: false,
    loggedInUser: '',
    lameThreshold: 2,
    snoozeDuration: 0,
    lastRefreshed: '',
    EULAConfirmed: false,
    EulaConfirmedDate: '',
  } as UserState,
  reducers: {
    enableDeveloperOptions: (state) => {
      state.developer = true;
    },
    enableDemoMode: (state) => {
      state.demoMode = true;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAvailableFarms: (state, action: PayloadAction<Farm[]>) => {
      state.availableFarms = action.payload;
    },
    // add permissions here
    setEidInvisible: (state) => {
      state.eidVisible = false;
    },
    setScoreInvisible: (state) => {
      state.scoreVisible = false;
    },
    setEmailNotification: (state, action: PayloadAction<boolean>) => {
      state.notifyEmail = action.payload;
    },
    setEmailList: (state, action: PayloadAction<string[]>) => {
      state.emailList = action.payload;
    },
    setPNNotification: (state, action: PayloadAction<boolean>) => {
      state.notifyPush = action.payload;
    },
    setPNList: (state, action: PayloadAction<string[]>) => {
      state.phoneList = action.payload;
    },
    setIsUserSeen: (state, action: PayloadAction<boolean>) => {
      state.userSeen = action.payload;
    },
    setCurrentLoggedUser: (state, action: PayloadAction<CognitoUserInterface>) => {
      state.loggedInUser = action.payload;
    },
    setLameThreshold: (state, action: PayloadAction<number>) => {
      state.lameThreshold = action.payload;
    },
    setSnoozeDuration: (state, action: PayloadAction<number>) => {
      state.snoozeDuration = action.payload;
    },
    setLastRefreshed: (state, action: PayloadAction<string>) => {
      state.lastRefreshed = action.payload;
    },
    setEulaConfirmed: (state, action: PayloadAction<boolean>) => {
      state.EULAConfirmed = action.payload;
    },
    setEulaConfirmedDate: (state, action: PayloadAction<string>) => {
      state.EulaConfirmedDate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  enableDeveloperOptions,
  enableDemoMode,
  setEmail,
  setAvailableFarms,
  setEidInvisible,
  setScoreInvisible,
  setEmailNotification,
  setEmailList,
  setPNNotification,
  setPNList,
  setIsUserSeen,
  setCurrentLoggedUser,
  setLameThreshold,
  setSnoozeDuration,
  setLastRefreshed,
  setEulaConfirmed,
  setEulaConfirmedDate,
} = userReduxSlice.actions;

export const getDeveloper = (state: RootState) => state.user.developer;
export const getDemoMode = (state: RootState) => state.user.demoMode;
export const getEmail = (state: RootState) => state.user.email;
export const getAvailableFarms = (state: RootState) => state.user.availableFarms;
// export const isvisparams = follow above syntax
export const isEidVisible = (state: RootState) => state.user.eidVisible;
export const isScoreVisible = (state: RootState) => state.user.scoreVisible;
export const isEmailNotify = (state: RootState) => state.user.notifyEmail;
export const getEmailList = (state: RootState) => state.user.emailList;
export const isPNNotify = (state: RootState) => state.user.notifyPush;
export const getPNList = (state: RootState) => state.user.phoneList;
export const isUserSeen = (state: RootState) => state.user.userSeen;
export const getCurrentLoggedUser = (state: RootState) => state.user.loggedInUser;
export const getLameThreshold = (state: RootState) => state.user.lameThreshold;
export const getSnoozeDuration = (state: RootState) => state.user.snoozeDuration;
export const getLastRefreshed = (state: RootState) => state.user.lastRefreshed;
export const getEulaConfirmation = (state: RootState) => state.user.EULAConfirmed;
export const getEULAConfirmedDate = (state: RootState) => state.user.EulaConfirmedDate;

export default userReduxSlice.reducer;
