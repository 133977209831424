/* eslint-disable react/no-unstable-nested-components */

/* eslint-disable react/no-unused-prop-types */
import React, { ReactNode } from 'react';

import { FormControl, MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';

import LoadingOverlay from '../common/LoadingOverlay';

const compactStyle = {
  '& li': {
    '& button': {
      display: 'none',
    },
    '& .MuiPaginationItem': {
      '&-ellipsis': {
        display: 'none',
      },
      '&-previousNext': {
        display: 'block',
      },
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 125px)',
      },
      '& .table-pagination': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .rows-per-page': {
          display: 'flex',
          alignItems: 'center',
          '&-label': {
            paddingRight: '10px',
          },
        },
        '&-actions': {
          [theme.breakpoints.down('sm')]: {
            ...compactStyle,
          },
        },
        '&--compact': {
          '& .table-pagination-actions': {
            ...compactStyle,
          },
        },
      },
      '& .MuiDataGrid': {
        '&-root': {
          border: 'none',
        },
        '&-footerContainer': {
          padding: '0 20px',
          minWidth: '350px',
          '& > :first-child:is(div)': {
            // mui puts empty div in here
            display: 'none',
          },
        },
        '&-row': {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            '& .MuiDataGrid-checkboxInput': {
              color: theme.palette.primary.contrastText,
            },
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          },
        },
      },
    },
    emptyTable: {
      marginTop: 38,
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    badge: {
      display: 'inline-flex',
      width: theme.spacing(4.5),
      right: theme.spacing(2),
      top: 0,
    },
    grow: {
      flexGrow: 1,
    },

    MuiDataGridRow: {
      backgroundColor: 'red',
      root: {
        '&$selected': {
          backgroundColor: 'yellow',
        },
      },
    },
    iconButton: {
      backgroundColor: 'white !important',
      padding: '0px',
    },
  })
);

function EmptyQueue(props: any) {
  const { message } = props;
  const classes = useStyles();

  return (
    <div className={classes.emptyTable}>
      <Typography color="primary" align="right" component="h4" variant="h6">
        {message}
      </Typography>
    </div>
  );
}

interface CardDataTableI {
  data: object[];
  columns: GridColDef[];
  sortModel: GridSortModel;
  filterModel: GridFilterModel;
  selectionModel: GridSelectionModel;
  loading: boolean;
  rowCount: number;
  page: number;
  pageSize: number;
  rowId: string;
  autoPageSize: boolean;
  compactMode: boolean;
  emptyMessage: string;
  // eslint-disable-next-line react/require-default-props
  isServerPaginated?: boolean;
  header: boolean;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
  onSortModelChange: (newModel: any) => void;
  onFilterModelChange: (newModel: any) => void;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  onPageChange: (page: number) => void;
  selectRowCallback(rowId: GridRowId | undefined): void;
}

function RowsPerPageSelect(props: {
  rowsPerPage: number;
  onRowsPerPageChange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
}) {
  const { rowsPerPage, onRowsPerPageChange } = props;
  const uuid = uuidv4();

  return (
    <div className="rows-per-page">
      <Typography className="rows-per-page-label">Rows:</Typography>

      <FormControl variant="standard">
        <Select
          id={`rows-per-page-select-${uuid}`}
          value={rowsPerPage}
          label="Rows per page"
          onChange={onRowsPerPageChange}
        >
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

function CustomPagination(props: {
  rowCount: number;
  pageSize: number;
  compactMode: boolean;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { pageSize, rowCount, setPageSize, compactMode } = props;
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rangeStart = page * pageSize + 1;
  const rangeEnd = rangeStart + pageSize - 1 > rowCount ? rowCount : rangeStart + pageSize - 1;

  const handleRowsPerPageChange = (event: SelectChangeEvent<any>) => {
    setPageSize(event.target.value);
  };

  return (
    <div className={`table-pagination ${compactMode ? 'table-pagination--compact' : ''}`}>
      {!Number.isNaN(rowCount) && (
        <>
          <Typography>
            Showing {rangeStart} - {rangeEnd} of {rowCount}
          </Typography>

          <RowsPerPageSelect rowsPerPage={pageSize} onRowsPerPageChange={handleRowsPerPageChange} />
        </>
      )}

      <Pagination
        className="table-pagination-actions"
        shape="rounded"
        color="primary"
        size="small"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </div>
  );
}

function CardDataTablePreview(props: CardDataTableI) {
  const {
    isServerPaginated,
    data,
    rowCount,
    page,
    pageSize,
    setPageSize,
    onPageChange,
    columns,
    filterModel,
    sortModel,
    onSortModelChange,
    onFilterModelChange,
    rowId,
    loading,
    autoPageSize,
    setSelectionModel,
    selectionModel,
    selectRowCallback,
    emptyMessage,
    header,
    compactMode,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DataGrid
        paginationMode={isServerPaginated ? 'server' : 'client'}
        rowCount={rowCount || data.length}
        rowsPerPageOptions={[pageSize || 0]}
        pageSize={pageSize}
        columns={columns}
        sortingMode="server"
        filterMode="server"
        filterModel={filterModel}
        rows={data}
        getRowId={(row) => row[rowId]}
        loading={loading}
        autoPageSize={autoPageSize}
        disableColumnMenu={false}
        density="compact"
        disableSelectionOnClick // disable regular selection, and handle on cell click
        hideFooterSelectedRowCount
        checkboxSelection={false}
        onCellClick={(params: GridCellParams) => {
          /* if this column is an action column, do nothing */
          if (params.colDef.type !== 'actions') {
            /* set this row "selected" */
            setSelectionModel([params.id]);

            /* if row selection callback exists */
            if (selectRowCallback !== undefined) {
              /* if there was a defaultSelected then don't do deselection */
              if (selectionModel && selectionModel[0] === params.id) {
                /* de-selection */
                selectRowCallback(undefined);
                setTimeout(() => setSelectionModel([]), 200);
              } else {
                /* callback on row */
                selectRowCallback(params.id);
              }
            }
          }
        }}
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        onPageSizeChange={setPageSize}
        page={page}
        onPageChange={onPageChange}
        sortingOrder={['desc', 'asc']}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        components={{
          Pagination: () => (
            <CustomPagination
              compactMode={compactMode}
              rowCount={rowCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          ),
          LoadingOverlay: () => <LoadingOverlay blurBackground />,
          NoRowsOverlay: () => <EmptyQueue message={emptyMessage} />,
          Toolbar: () =>
            header ? (
              <GridToolbarContainer>
                <GridToolbarFilterButton />

                <GridToolbarExport
                  printOptions={{
                    hideFooter: true,
                    hideToolbar: true,
                  }}
                />
              </GridToolbarContainer>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            ),
        }}
      />
    </div>
  );
}

export default CardDataTablePreview;
