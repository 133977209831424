import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames/bind';

const useStyles = makeStyles((theme) => ({
  hoverButton: {
    color: 'lightgrey',
  },
  float: {
    position: 'absolute',
    padding: theme.spacing(1),
    left: 0,
    top: 0,
  },
  flex2: {
    display: 'inline-flex',
    // boxSizing: "inherit",
    // position: "relative",
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function InfoToolTip(props: any) {
  const { float, info } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className={classNames(classes.hoverButton, float ? classes.float : classes.flex2)}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <InfoOutlinedIcon fontSize="small" />

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{info}</Typography>
      </Popover>
    </div>
  );
}

export default InfoToolTip;
