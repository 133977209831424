import React from 'react';

import { Paper, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridRowId } from '@mui/x-data-grid';

// eslint-disable-next-line import/no-cycle
import { useDataTable } from '../../helpers/hooks';
import { getAllIdentifiedCows } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import CardDataTable from './CardDataTable';
import { cowColumns } from './DataTableProps';

const styles = makeStyles((theme) => ({
  containerPadding: {
    flexGrow: 'true',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    height: '100%',
    marginRight: theme.spacing(2),
    '&:last-child': { marginRight: theme.spacing(0) },
  },
}));

function CardCowListIdentity(props: {
  currentCow: number | undefined;
  setCurrentCow: React.Dispatch<number | undefined>;
}) {
  const classes = styles();
  const { currentCow, setCurrentCow } = props;
  const { page, setPage, sortModel, setSortModel, selectionModel, setSelectionModel, setPageSize } = useDataTable({
    initialSortModel: [{ field: 'totalVideos', sort: 'desc' }],
    initialSelectionModel: [currentCow as number],
    initialPage: 0,
    initialPageSize: 0,
  });

  const cowsWithEid = useAppSelector(getAllIdentifiedCows);
  const columns = React.useMemo(
    () => cowColumns(['tag', 'last_score', 'last_scored', 'totalVideos', 'unconfirmed_collections']),
    []
  );

  return (
    <Stack direction="column" className={classes.containerPadding}>
      <Typography variant="h5" component="h2" sx={{ minWidth: 'max-content' }}>
        Select a cow
      </Typography>

      <Paper sx={{ flexBasis: '100%' }}>
        <CardDataTable
          data={cowsWithEid}
          columns={columns}
          page={page}
          onPageChange={setPage}
          setPageSize={setPageSize}
          sortModel={sortModel}
          setSortModel={setSortModel}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          header={false}
          emptyMessage="No Cows With EID"
          selectRowCallback={(rowId: GridRowId | undefined) => {
            if (rowId === undefined) {
              setCurrentCow(undefined);
            } else {
              setCurrentCow(rowId as number);
            }
          }}
        />
      </Paper>
    </Stack>
  );
}

export default React.memo(CardCowListIdentity);
