import dayjs from 'dayjs';

// eslint-disable-next-line import/prefer-default-export
export const buildQueryParameters = (
  sortModel: any,
  filterModel: any,
  orderBy: string,
  dateFields: any[],
  page?: string | number,
  pageSize?: string | number
) => {
  const queryParameters: Record<string, string> = {
    orderBy,
    sortOrder: sortModel[0]?.sort ?? 'asc',
    page: page?.toString() ?? '1',
    perPage: pageSize?.toString() ?? '10',
  };

  const filterArray: any[] = [];
  const availableOperators: Record<string, string> = {
    is: 'eq',
    not: 'ne',
    after: 'gt',
    before: 'lt',
    onOrAfter: 'ge',
    onOrBefore: 'le',
    '=': 'eq',
    '<>': 'ne',
    '>': 'gt',
    '<': 'lt',
    '>=': 'ge',
    '<=': 'le',
  };

  filterModel.items.forEach((item: any) => {
    const operator = availableOperators[item.operatorValue ?? 'eq'];

    if (item.value === undefined) return;

    let { value } = item;

    if (dateFields.includes(item.columnField)) {
      value = dayjs(item.value).format('YYYY-MM-DD HH:mm:ss.SSS');
    }

    filterArray.push(`${item.columnField}:${operator}:${value}`);
  });

  const filterString: string = filterArray.join(';');

  if (filterString.length > 0) {
    queryParameters.filter = filterString;
  }

  return queryParameters;
};
