import React from 'react';

import { getAllMilkingStats, getStatsLastMilking } from '../Data/dataFormatting';
import { TableEntryT } from '../Data/dataInterfaces';
import { getAllMilkingDays } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';
import DetailsTable from '../DetailsTable';

function TableMilkingSession(props: any) {
  const [tableRows, setTableRows] = React.useState<TableEntryT[]>([{ name: 'Error', value: 'no data' }]);
  const allMilkingDays = useAppSelector(getAllMilkingDays);
  const { milking } = props;

  React.useEffect(() => {
    if (milking !== undefined) {
      setTableRows(getStatsLastMilking(milking));
    } else if (allMilkingDays !== undefined) {
      setTableRows(getAllMilkingStats(allMilkingDays));
    }
  }, [milking, allMilkingDays]);

  return <DetailsTable details={tableRows} rowLength={1} tableName="milking details" />;
}

export default TableMilkingSession;
