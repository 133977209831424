import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { formatDateTime } from '../../helpers/time';
import LameStatus from '../common/LameStatus';

const useStyles = makeStyles((theme) => ({
  table: {},
  StatusScore: {
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
}));

function TableCowLameStatsPreview(props: any) {
  const { cow } = props;
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableBody>
          <TableRow key="currentLameness">
            <TableCell component="th">
              <Typography variant="subtitle2" noWrap>
                Current Lameness
              </Typography>
            </TableCell>

            <TableCell align="right">
              <LameStatus score={cow.latestObservationScore ?? '-'} />
              <Typography className={classes.StatusScore}>{cow.latestObservationScore?.toFixed(2) ?? '-'}</Typography>
            </TableCell>
          </TableRow>

          <TableRow key="totalScores">
            <TableCell component="th">
              <Typography variant="subtitle2" noWrap>
                Total Scores
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography noWrap>{cow.scoreCount ?? 0}</Typography>
            </TableCell>
          </TableRow>

          <TableRow key="videos">
            <TableCell component="th">
              <Typography variant="subtitle2" noWrap>
                Videos
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography noWrap>{cow.observationCount}</Typography>
            </TableCell>
          </TableRow>

          <TableRow key="latestVideo">
            <TableCell component="th">
              <Typography variant="subtitle2" noWrap>
                Latest Video
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography noWrap>{formatDateTime(cow.latestObservationDate)}</Typography>
            </TableCell>
          </TableRow>

          <TableRow key="firstVideo">
            <TableCell component="th">
              <Typography variant="subtitle2" noWrap>
                First Video
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography noWrap>{formatDateTime(cow.firstObservationDate)}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableCowLameStatsPreview;
