import React from 'react';
import ReactPlayer from 'react-player';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { Observation } from '../../api/farmAPI/types';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#222',
    width: '100%',
    paddingTop: '38.461%', // aspect ration = 700 / 1820
    position: 'relative',
  },
  videoPlayer: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
}));

function VideoPlayerPreview(props: { observation: Observation; isLoading: boolean }) {
  const { isLoading, observation } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Box sx={{ width: '100%', height: '100%' }} />
      ) : (
        <ReactPlayer
          className={classes.videoPlayer}
          url={observation.videoUrl}
          light={observation.thumbnail}
          width="100%"
          height="100%"
          controls
          playing
          loop
          progressInterval={1}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          onContextMenu={(e: any) => e.preventDefault()}
        />
      )}
    </div>
  );
}

export default VideoPlayerPreview;
