import { Auth } from 'aws-amplify';

export type SingleArgFunc<T, R> = (arg0: T) => R;
export type NoArgFunc<R> = () => R;
export type Func<T, R> = SingleArgFunc<T, R> | NoArgFunc<R>;

export type APIFunctionWithURLAndHeaders<T, R> = ({
  url,
  headers,
  shouldSend,
}: {
  url: string;
  headers: Record<string, string>;
  shouldSend?: boolean;
}) => Func<T, R>;

export const wrapApiFunction = ({ url }: { url?: string }) => {
  if (url === undefined) throw new Error('Base API url must be defined');

  return ({ stage, defaultHeaders }: { stage?: string; defaultHeaders?: Record<string, string> }) =>
    ({ route }: { route: string }) => {
      if (route === undefined) throw new Error('API route must be defined');

      return <T, R>(func: APIFunctionWithURLAndHeaders<T, R>): Func<T, Promise<R>> =>
        async (arg0: T) => {
          const accessToken = (await Auth.currentSession()).getAccessToken();
          const fetchUrl = url + (stage !== undefined ? `/${stage}` : '') + route;
          const headers = {
            ...defaultHeaders,
            Authorization: `Bearer ${accessToken.getJwtToken()}`,
          };

          return func({ url: fetchUrl, headers })(arg0);
        };
    };
};

const urlWrap = wrapApiFunction({
  url: process.env.REACT_APP_BASE_API_URL,
});

export const stageWrap = urlWrap({
  stage: process.env.REACT_APP_API_STAGE,
  defaultHeaders: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
});
