import React from 'react';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { gradientSVG } from '../Data/ColourScale';
import { collectionState } from '../Data/dataInterfaces';
import { getAllDataDates, getCollectionsFromIds, getCow } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '800px',
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyTable: {
    height: '100%',
    // backgroundColor: "red",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface ChartCowLameHistoryLineI {
  selectDataPoint(collection_id: number): void;
  // eslint-disable-next-line react/no-unused-prop-types
  collection_id: number | undefined;
  cow_id: number | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  numberOfDays: number;
}

function ChartCowLameHistoryLine(props: ChartCowLameHistoryLineI) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { cow_id, selectDataPoint } = props;
  const classes = useStyles();
  const theme = useTheme();
  const chartHeight = useMediaQuery(theme.breakpoints.up('md')) ? '90%' : 165;
  const cow = useAppSelector(getCow(cow_id));
  const collections = useAppSelector(getCollectionsFromIds(cow?.collection_ids));
  const allDataDates = useAppSelector(getAllDataDates);
  const [data, setData] = React.useState<collectionState[]>();
  const [scoreRange, setScoreRange] = React.useState<number[]>();

  React.useEffect(() => {
    if (collections !== undefined) {
      setData(collections.filter((c) => c.score !== undefined && c.score > 0));
    }
  }, [cow_id]);

  React.useEffect(() => {
    if (data !== undefined && data.length > 0) {
      let min = 3;
      let max = 0;

      data.forEach((d: collectionState) => {
        if (d.score !== undefined) {
          min = d.score < min ? d.score : min;
          max = d.score > max ? d.score : max;
        }
      });
      setScoreRange([min, max]);
    }
  }, [data]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomizedDot(dotProps: any) {
    const { cx, cy, payload } = dotProps;
    const selected = props.collection_id === payload.id;
    const size = selected ? 20 : 12;
    const padding = 4;

    return (
      <svg
        x={cx - size / 2 - padding}
        y={cy - size / 2 - padding}
        height={size + padding * 2}
        width={size + padding * 2}
      >
        <circle
          cx={size / 2 + padding}
          cy={size / 2 + padding}
          r={size / 2}
          stroke="#1976D2"
          strokeWidth="3"
          fill="#FFF"
        />
      </svg>
    );
  }

  const emptyChart = (
    <div className={classes.emptyTable}>
      <Typography color="primary" align="right" component="h4" variant="h6">
        Insufficient data for graph
      </Typography>
    </div>
  );

  return data === undefined || data.length < 1 || allDataDates === undefined || scoreRange === undefined ? (
    emptyChart
  ) : (
    <div className={classes.root}>
      <ResponsiveContainer width="90%" height={chartHeight}>
        <LineChart
          data={data}
          margin={{
            top: 8,
            right: 8,
            left: 8,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="date"
            type="number"
            scale="utc"
            // type="category"
            axisLine={false}
            tickLine={false}
            tickFormatter={(d: Date) => moment(d).format('DD MMM')}
            interval="preserveStartEnd" // 'preserveStartEnd'
            angle={30}
            tickCount={data.length}
            tickMargin={10}
            ticks={allDataDates}
            height={40}
            tick={{ fontSize: 12 }}
            domain={[allDataDates[0], moment().valueOf()]}
          />

          <YAxis
            dataKey="score"
            // type="number"
            domain={[0, 3]}
            axisLine
            tickLine={false}
            width={25}
            tickFormatter={(l: number) => l.toFixed(1)}
          />

          {/* <Tooltip cursor={false} /> */}
          {/* <Legend /> */}
          {gradientSVG(scoreRange[0], scoreRange[1])}

          <Line
            isAnimationActive={false}
            // xAxsiId="date"
            // yAxisId="score"
            name="Lameness score"
            type="basis"
            dataKey="score"
            stroke="url(#gradient)"
            strokeWidth={5}
            dot={<CustomizedDot cursor="pointer" />}
            activeDot={{
              cursor: 'pointer',
              r: 10,
              stroke: '#1976D2',
              strokeWidth: '3',
              fill: '#FFF',
              onClick: (e: any, i: any) => {
                if (selectDataPoint) {
                  selectDataPoint(data[i.index].id);
                }
              },
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ChartCowLameHistoryLine;
