import React from 'react';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import InfoToolTip from '../common/InfoTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  container: {
    margin: '0 auto',
  },
  title: {
    lineHeight: 1,
    fontSize: '1.5rem',
  },
  value: {
    lineHeight: 1,
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    fontWeight: 'bolder',
  },
  subtitle: {
    color: 'grey',
  },
}));

function CardSingleNumber(props: any) {
  const classes = useStyles();
  const { label, subtitle, value, info } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.title} align="center" variant="h3">
          {label}
        </Typography>

        <Typography className={classes.value} align="center" variant="body1">
          {value}
        </Typography>

        {subtitle ? (
          <Typography className={classes.subtitle} align="center" variant="body1">
            {subtitle}
          </Typography>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}
      </div>

      <InfoToolTip float info={info} />
    </div>
  );
}

export default CardSingleNumber;
