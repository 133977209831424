/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';
import { GridFilterModel, GridRowId, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';

import { cowData } from '../../api/farmAPI/cowData';
import { buildQueryParameters } from '../../api/farmAPI/utils';
import CardCowProfilePreview from '../Cards/CardCowProfilePreview';
import CardCowVideoPreview from '../Cards/CardCowVideoPreview';
import CardDataTablePreview from '../Cards/CardDataTablePreview';
import { cowColumns } from '../Cards/DataTablePropsPreview';
import { getCows, setCows, setCurrentCow } from '../Data/CowDataReduxSlice';
import { getFarmId } from '../Data/farmDataReduxSlice';
// eslint-disable-next-line import/no-cycle
import { setPageIndex } from '../Data/pageReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import { isScoreVisible } from '../Data/userReduxSlice';
import { TitleStack } from './TitleStack';

function CowListStack({
  farmId,
  cowId,
  setCowId,
}: {
  farmId: number | null;
  cowId: number | undefined;
  setCowId: any;
}) {
  const scoreVisibility = useAppSelector(isScoreVisible);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'latestObservationDate', sort: 'desc' }]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });

  const handleSortModelChange = (newModel: any) => {
    setSortModel(newModel);
  };

  const handleFilterModelChange = (newModel: any) => {
    setFilterModel(newModel);
  };

  const [page, setPage] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRows, setTotalRows] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchData = async (queryParams: Record<string, string>) => {
    if (farmId) {
      setIsLoading(true);

      const result = await cowData.getCows(farmId, {}, queryParams);
      const { headers } = result;
      const totalRowCount = parseInt(headers['total-count'], 10);
      const { data } = result;

      setIsLoading(false);
      setTotalRows(totalRowCount);
      setPageSize(pageSize);

      dispatch(setCows(data));
    }
  };

  useEffect(() => {
    let orderBy = sortModel[0]?.field ?? 'cowId';

    if (orderBy === 'currentStatus') {
      orderBy = 'latestObservationScore';
    }

    const queryParameters = buildQueryParameters(
      sortModel,
      filterModel,
      orderBy,
      ['latestObservationDate'],
      page + 1,
      pageSize
    );

    fetchData(queryParameters);
  }, [sortModel, filterModel, page, pageSize]);

  const cowList = useAppSelector(getCows).cows;

  useEffect(() => {
    const tag = searchParams.get('cow');

    if (tag?.startsWith('unknown')) {
      setCowId(+tag.split('-')[1]);
      setSelectionModel([+tag.split('-')[1]]);
    } else if (tag !== null) {
      setSortModel([{ field: 'latestObservationDate', sort: 'desc' }]);
      setCowId(() => (cowId !== undefined ? +cowId : undefined));
      setSelectionModel(() => (cowId !== undefined ? [+cowId] : []));
      fetchData({ filter: `tag:eq:${tag}` });
    }
  }, [searchParams, pageSize]);

  return (
    <TitleStack title="Cow List">
      <CardDataTablePreview
        compactMode={cowId !== undefined}
        loading={isLoading}
        data={cowList}
        rowId="cowId"
        columns={cowColumns(
          ['currentStatus', 'tag', 'latestObservationScore', 'observationCount', 'latestObservationDate'],
          scoreVisibility ? [] : ['latestObservationScore', 'latestObservationDate']
        )}
        page={page}
        onPageChange={(page) => {
          setSearchParams({});
          setPage(page);
        }}
        isServerPaginated
        rowCount={totalRows}
        autoPageSize={false}
        setPageSize={setPageSize}
        pageSize={pageSize}
        header
        sortModel={sortModel}
        filterModel={filterModel}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
        setSortModel={setSortModel}
        setSelectionModel={setSelectionModel}
        selectionModel={selectionModel}
        emptyMessage="No Cow Data"
        selectRowCallback={(rowId: GridRowId | undefined) => {
          if (rowId === undefined) {
            setCowId(undefined);
          } else if (rowId === cowId) {
            setCowId(undefined);
            setSearchParams({});
          } else {
            setCowId(rowId as number);
            setSearchParams({});
          }
        }}
      />
    </TitleStack>
  );
}

function CowProfileStack(props: { isLoading: boolean }) {
  const { isLoading } = props;

  return (
    <TitleStack title="Cow Details">
      <CardCowProfilePreview isLoading={isLoading} />
    </TitleStack>
  );
}

function CowVideosStack(props: { cowId: number | undefined }) {
  const { cowId } = props;

  return (
    <TitleStack title="Cow Videos">
      <CardCowVideoPreview showDetails showHistory cowId={cowId} />
    </TitleStack>
  );
}

function CowBrowserPreviewLayout(props: any) {
  const { pageIndex } = props;
  const [cowId, setCowId] = React.useState<number | undefined>();
  const [cowIsLoading, setCowIsLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const farmId = useAppSelector(getFarmId);

  const fetchData = () => {
    if (farmId && cowId) {
      setCowIsLoading(true);

      cowData.getCowDetails(farmId, cowId).then((result) => {
        dispatch(setCurrentCow(result.data));
        setCowIsLoading(false);
      });
    } else {
      dispatch(setCurrentCow(undefined));
    }
  };

  useEffect(() => {
    fetchData();
  }, [cowId]);

  useEffect(() => {
    dispatch(setPageIndex(pageIndex));
  }, [pageIndex]);

  return (
    <Stack
      sx={{
        padding: 2,
        height: '100%',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      <CowListStack farmId={farmId} setCowId={setCowId} cowId={cowId} />

      {cowId && (
        <>
          <CowProfileStack isLoading={cowIsLoading} />
          <CowVideosStack cowId={cowId} />
        </>
      )}
    </Stack>
  );
}

export default CowBrowserPreviewLayout;
