import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Moment from 'moment';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getHexByScore } from '../Data/ColourScale';
import { getHistogramBuckets } from '../Data/dataFormatting';
import { histogramEntry } from '../Data/dataInterfaces';
import { getAllMilkingDays } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '800px',
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function ChartLameCount(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const data = useAppSelector(getAllMilkingDays);
  const { display } = props;
  /* get a single empty histogram
   * for the titles (e.g. "Very Lame")
   * and binMedians (e.g. 2.75)
   * */
  const barData = getHistogramBuckets(undefined);
  /*
  barData = [
    {
      "name":"Very Healthy",
      "quantity":0,
      "binMedian":0.25
    },
    ...
  ]
  */
  const barchart =
    data !== undefined ? (
      <BarChart
        data={data}
        margin={{
          top: 8,
          right: 8,
          left: 8,
          bottom: 0,
        }}
        maxBarSize={50}
        stackOffset={display}
        barCategoryGap={1}
        barGap={1}
      >
        <XAxis
          dataKey="date"
          axisLine={false}
          // ticks={data.map((x: any) => new Date(x.date))}
          tickLine={false}
          scale="utc"
          type="number"
          padding={{ left: 25, right: 25 }}
          domain={['dataMin', 'dataMax']}
          tickFormatter={(d: Date) => Moment(d).format('DD MMM')}
          interval="preserveStartEnd" // 'preserveStartEnd'
          angle={30}
          tickCount={data.length}
          tickMargin={10}
          height={40}
          tick={{ fontSize: 12 }}
        />

        <YAxis
          // dataKey="collections"
          axisLine={false}
          tickLine={false}
          width={30}
        />

        <Tooltip
          cursor={false}
          labelFormatter={(d: Date) => Moment(d).format('DD MMM')}
          formatter={(l: number) => l.toFixed(0)}
        />

        {/* <Legend /> */}
        {barData.map((bar: histogramEntry, i: number) => (
          <Bar
            key={`bar-${bar.name}`}
            isAnimationActive={false}
            // barSize={1}
            name={bar.name}
            type="monotone"
            dataKey={`score_bins[${i}]`}
            fill={getHexByScore(bar.binMedian)}
            strokeWidth={2}
            stackId="a"
          />
        ))}
      </BarChart>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
    );

  return (
    <div className={classes.root}>
      <ResponsiveContainer width="90%" height={useMediaQuery(theme.breakpoints.up('md')) ? '90%' : 165}>
        {barchart}
      </ResponsiveContainer>
    </div>
  );
}

export default ChartLameCount;
