import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { useAppSelector } from '../Data/reduxHooks';
import { getEulaConfirmation } from '../Data/userReduxSlice';
import EULA from '../common/EULA';
import SideBar from './SideBar';
import TopBar from './TopBar';

const drawerWidth = '240px';
const bannerHeight = '64px';
const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${bannerHeight})`,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: drawerWidth,
    },
  },
  nav: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      height: bannerHeight,
    },
    // position: '-webkit-sticky',
    position: 'sticky',
    // left: 0,
    top: 0,
    zIndex: 1,
  },
}));

function Dashboard(props: any) {
  const classes = useStyles();
  const eulaConfirm = useAppSelector(getEulaConfirmation);
  const { children } = props;

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <SideBar />
        <TopBar />
        <EULA />
      </div>

      {eulaConfirm && <div className={classes.content}>{children}</div>}
    </div>
  );
}

export default Dashboard;
