import React from 'react';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: '55vh',
      },
      '& .MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
      },
      '& .MuiDataGrid-row:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },
      '& .MuiDataGrid-row:hover  .MuiDataGrid-checkboxInput': {
        color: theme.palette.primary.contrastText,
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '& .MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    emptyTable: {
      marginTop: 38,
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    badge: {
      display: 'inline-flex',
      width: theme.spacing(4.5),
      right: theme.spacing(2),
      top: 0,
    },
    grow: {
      flexGrow: 1,
    },

    MuiDataGridRow: {
      backgroundColor: 'red',
      root: {
        '&$selected': {
          backgroundColor: 'yellow',
        },
      },
    },
    iconButton: {
      backgroundColor: 'white !important',
      padding: '0px',
    },
  })
);

function EmptyQueue(props: any) {
  const classes = useStyles();
  const { message } = props;

  return (
    <div className={classes.emptyTable}>
      <Typography color="primary" align="right" component="h4" variant="h6">
        {message}
      </Typography>
    </div>
  );
}

interface CardDataTableI {
  data: object[];
  columns: GridColDef[];
  sortModel: GridSortModel;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
  selectionModel: GridSelectionModel;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  onPageChange: (page: number) => void; // React.Dispatch<React.SetStateAction<number>>;
  emptyMessage: string;
  header: boolean;
  selectRowCallback(rowId: GridRowId | undefined): void;
}

function CardDataTable(props: CardDataTableI) {
  const classes = useStyles();
  const {
    data,
    columns,
    setSelectionModel,
    selectRowCallback,
    page,
    setPageSize,
    onPageChange,
    setSortModel,
    sortModel,
    selectionModel,
    header,
    emptyMessage,
  } = props;

  return (
    <div className={classes.root}>
      <DataGrid
        columns={columns}
        rows={data}
        // loading={true}
        autoPageSize
        disableColumnMenu={false}
        density="compact"
        disableSelectionOnClick // disable regular selection, and handle on cell click
        hideFooterSelectedRowCount
        checkboxSelection={false}
        onCellClick={(params: GridCellParams) => {
          /* if this column is an action column, do nothing */
          if (params.colDef.type !== 'actions') {
            /* set this row "selected" */
            setSelectionModel([params.row.id]);

            /* if row selection callback exists */
            if (selectRowCallback !== undefined) {
              /* if there was a defaultSelected then don't do deselection */
              if (selectionModel && selectionModel[0] === params.row.id) {
                /* de-selection */
                selectRowCallback(undefined);
                setTimeout(() => setSelectionModel([]), 200);
              } else {
                /* callback on row */
                selectRowCallback(params.row.id);
              }
            }
          }
        }}
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        onPageSizeChange={setPageSize}
        page={page}
        onPageChange={onPageChange}
        sortingOrder={['desc', 'asc']}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        components={{
          // Footer: GridFooter, // () => (<></>), //
          // eslint-disable-next-line react/no-unstable-nested-components
          NoRowsOverlay: () => <EmptyQueue message={emptyMessage} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          Toolbar: () =>
            header ? (
              <GridToolbarContainer>
                <GridToolbarFilterButton />

                <GridToolbarExport
                  printOptions={{
                    hideFooter: true,
                    hideToolbar: true,
                  }}
                />
              </GridToolbarContainer>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            ),
        }}
      />
    </div>
  );
}

export default CardDataTable;
