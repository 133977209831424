/* eslint-disable @typescript-eslint/no-redeclare */

/* eslint-disable consistent-return */

/* eslint-disable no-redeclare */
import { z } from 'zod';

// Returned value of a snooze
export const Snooze = z.object({
  snoozeId: z.string(),
  snoozeTime: z.preprocess((arg) => {
    if (typeof arg === 'string' || arg instanceof Date || typeof arg === 'number') return new Date(arg);
  }, z.date()),
  eid: z.string(),
  snoozeExpiry: z.preprocess((arg) => {
    if (typeof arg === 'string' || arg instanceof Date || typeof arg === 'number') return new Date(arg);
  }, z.date()),
  user: z.string().email(),
  farm: z.string(),
});

// The structure expected to create a snooze
export const CreateSnooze = Snooze.omit({
  snoozeId: true,
  snoozeTime: true,
  snoozeExpiry: true,
}).extend({
  snoozeLength: z.number().positive(),
  user: z.optional(z.string().email()),
});

// The types of these structures
export type CreateSnooze = z.infer<typeof CreateSnooze>;
export type Snooze = z.infer<typeof Snooze>;

export const DeleteSnoozePost = z.object({
  operation: z.literal('delete'),
  tableName: z.optional(z.literal('SnoozedCows')),
  payload: z.object({
    Key: z.object({
      eid: z.string(),
    }),
    ReturnValue: z.literal('CREATED_NEW'),
  }),
});

export type DeleteSnoozePost = z.infer<typeof DeleteSnoozePost>;
