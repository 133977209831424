// Tell webpack this JS file uses this image
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Logo from './herd-i-logo-black.png';

const useStyles = makeStyles(() => ({
  logo: {
    /* SideBare width is 240 */
    width: 210,
    marginLeft: 15,
    marginTop: 30,
    marginBottom: 15,
  },
}));

function SideBarIrisLogo() {
  const classes = useStyles();

  return <img className={classes.logo} src={Logo} alt="Logo" />;
}

export default SideBarIrisLogo;
