import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Moment from 'moment';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { dayState } from '../Data/dataInterfaces';
import { getAllMilkingDays } from '../Data/farmDataReduxSlice';
import { useAppSelector } from '../Data/reduxHooks';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '800px',
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function ChartScoreCount(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const { display } = props;
  const allDays = useAppSelector(getAllMilkingDays);
  /* compute the differences for the graph */
  const data = allDays.map((d: dayState) => ({
    date: d.date,
    scored: d.score_count,
    unscored: d.track_count - d.score_count,
    untracked: Math.max(0, d.eid_count - d.track_count),
  }));

  const barchart = (
    <BarChart
      data={data}
      margin={{
        top: 8,
        right: 8,
        left: 8,
        bottom: 0,
      }}
      maxBarSize={50}
      stackOffset={display}
    >
      <XAxis
        dataKey="date"
        axisLine={false}
        ticks={data.map((x: any) => x.date)}
        tickLine={false}
        scale="utc"
        type="number"
        padding={{ left: 25, right: 25 }}
        domain={['dataMin', 'dataMax']}
        tickFormatter={(d: Date) => Moment(d).format('DD MMM')}
        interval="preserveStartEnd" // 'preserveStartEnd'
        angle={30}
        tickCount={data.length}
        tickMargin={10}
        height={40}
        tick={{ fontSize: 12 }}
      />

      <YAxis axisLine={false} tickLine={false} width={30} />

      <Tooltip
        cursor={false}
        labelFormatter={(d: Date) => Moment(d).format('DD MMM')}
        formatter={(l: number) => l.toFixed(0)}
      />

      {/* <Legend /> */}
      <Bar
        isAnimationActive={false}
        name="Scored"
        type="monotone"
        dataKey="scored"
        fill="#42A5F5"
        strokeWidth={2}
        stackId="a"
      />

      <Bar
        isAnimationActive={false}
        name="No Score"
        type="monotone"
        dataKey="unscored"
        fill="#F44336"
        strokeWidth={2}
        stackId="a"
      />

      <Bar
        isAnimationActive={false}
        name="No Video"
        type="monotone"
        dataKey="untracked"
        fill="#9C27B0"
        strokeWidth={2}
        stackId="a"
      />
    </BarChart>
  );

  return (
    <div className={classes.root}>
      {data !== undefined && (
        <ResponsiveContainer width="90%" height={useMediaQuery(theme.breakpoints.up('md')) ? '90%' : 165}>
          {barchart}
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default ChartScoreCount;
