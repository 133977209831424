import React from 'react';
import useScrollbarSize from 'react-scrollbar-size';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid as Grid } from 'react-window';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Storage } from 'aws-amplify';
import Moment from 'moment';

import { cacheImage, getCollection, getCow, getS3Image } from '../Data/farmDataReduxSlice';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import LameStatus from '../common/LameStatus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: '90vh',
      },
    },
    card: {
      padding: '5px 5px 5px 5px',
      borderBottom: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },
    },
    cellSubtitle: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: theme.spacing(1),
    },
    cardSelected: {
      padding: '5px 5px 5px 5px',
      borderBottom: '1px solid',
      borderRight: '1px solid',
      borderColor: theme.palette.primary.main,
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    thumbnail: {
      width: '100%' /* width of container */,
      height: '85px' /* height of container */,
      overflow: 'hidden',
      '& img': {
        margin: '-60px 0px 0px -60px',
      },
    },
  })
);

const gridRef = React.createRef<any>();

const onResize = () => {
  if (gridRef.current != null) {
    gridRef.current.resetAfterColumnIndex(0);
  }
};

function CardVideoGrid(props: any) {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { collection_id, changeCollection, collections } = props;

  type CellProps = {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties | undefined;
  };

  const { height: cellHeight } = useScrollbarSize();
  const scrollBarWidth = cellHeight;
  let columnCount = 10;
  const rowHeight = 150;
  const minCellWidth = 177;

  const calcColumnCount = (width: number) => {
    columnCount = Math.floor(width / minCellWidth);

    return columnCount;
  };

  const calcCellWidth = (width: number) => {
    const remainingWidth = (width % minCellWidth) - scrollBarWidth;
    const cellCollumnCount = calcColumnCount(width);

    return minCellWidth + remainingWidth / cellCollumnCount;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function Cell({ columnIndex, rowIndex, style }: CellProps) {
    const collectionId = rowIndex * columnCount + columnIndex;
    const dispatch = useAppDispatch();
    const collection = useAppSelector(getCollection(collectionId));
    const cow = useAppSelector(getCow(collection?.cowId));
    const imageUrl = useAppSelector(getS3Image(collectionId));

    React.useEffect(() => {
      if (collection !== undefined && imageUrl === undefined) {
        const imgKey = `thumb/${collection.uuid}.jpg`;

        Storage.get(imgKey).then((url) => {
          dispatch(cacheImage({ collection_id: collectionId, url }));
        });
      }
    }, []);

    return collection === undefined ? (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
    ) : (
      <div
        className={
          // eslint-disable-next-line no-nested-ternary
          columnIndex % 2
            ? rowIndex % 2 === 0
              ? 'GridItemOdd'
              : 'GridItemEven'
            : rowIndex % 2
            ? 'GridItemOdd'
            : 'GridItemEven'
        }
        style={style}
      >
        <Box
          className={collectionId === collection_id ? classes.cardSelected : classes.card}
          onClick={() => {
            changeCollection(collectionId);
          }}
        >
          <div className={classes.thumbnail}>{imageUrl && <img src={imageUrl} alt="video thumbnail" />}</div>

          <Typography gutterBottom align="center" variant="body1">
            {Moment(collection.date).format('HH:mm ddd DD MMM YYYY')}
          </Typography>

          <div className={classes.cellSubtitle}>
            <LameStatus score={collection.score} />
            Tag: {cow?.tag ?? '?'}
          </div>
        </Box>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AutoSizer onResize={onResize}>
        {({ height, width }) => (
          <Grid
            ref={gridRef}
            className="Grid"
            columnCount={calcColumnCount(width)}
            rowCount={Math.ceil(collections.length / calcColumnCount(width))}
            columnWidth={() => calcCellWidth(width)}
            rowHeight={() => rowHeight}
            height={height} // 150 * 5 + 48}
            width={width} // 150 * columnCount + 32}
          >
            {Cell}
          </Grid>
        )}
      </AutoSizer>
    </div>
  );
}

export default CardVideoGrid;
